import { getPlainTextFromContent } from "@knowt/editor/helpers/getPlainTextFromContent";
import { Flashcard, FlashcardSide } from "@knowt/syncing/graphql/schema";
import clsx from "clsx";
import React from "react";
import { FlashcardControls } from "../FlashcardCard/FlashcardControls";
import carouselStyles from "./flashcardsCarousel.module.css";
import HtmlText from "components/HtmlText";
import styles from "@/styles/flashcard.module.css";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import br from "@/styles/breakpoints.module.css";
import AddRemoveImageButton from "@/components/AddRemoveImageButton";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";

type FlashcardMockProps = {
    onClick: () => void;
    style?: React.CSSProperties;
    className?: string;
    children: React.ReactNode;
};

export const FlashcardMock = ({ children, onClick, style, className }: FlashcardMockProps) => {
    return (
        <FlexColumn
            className={clsx(styles.flashcard, className)}
            onClick={onClick}
            style={{ height: "57rem", padding: "2.8rem 3rem", ...style }}>
            {children}
        </FlexColumn>
    );
};

type Props = {
    flashcard: Flashcard;
    starProps: {
        isStarred: boolean;
        toggleFlashcardStarById: () => void;
    };
    showEditFlashcard: (flashcard: Flashcard) => void;
    mobileButtonComponents?: {
        next: React.ReactElement;
        prev: React.ReactElement;
    };
    onClick: () => void;
    side: FlashcardSide;
    readOnly?: boolean;
};

export const FlashcardCarouselCard = ({
    flashcard,
    starProps: { isStarred, toggleFlashcardStarById },
    mobileButtonComponents,
    showEditFlashcard,
    onClick,
    side,
    readOnly,
}: Props) => {
    const { smDown } = useBreakPoints();

    if (!flashcard) return null;
    const { term, definition, secondaryImage, image } = flashcard;

    const isTerm = side === FlashcardSide.TERM;
    const htmlText = (isTerm ? term : definition) || "";
    const plainText = getPlainTextFromContent({ content: htmlText, type: "flashcard" });
    const imageSrc = isTerm ? secondaryImage : image;
    const imageAlt = (isTerm ? term : definition) || "";

    const isHtmlTextEmpty = getPlainTextFromContent({ content: htmlText as string, type: "flashcard" }) === "";

    return (
        <FlashcardMock onClick={onClick}>
            <Flex style={{ justifyContent: "flex-end", width: "100%" }}>
                <FlashcardControls
                    flashcard={flashcard}
                    isStarred={isStarred}
                    toggleStarFlashcard={toggleFlashcardStarById}
                    showEditFlashcard={showEditFlashcard}
                    audioSide={side}
                    readOnly={readOnly}
                    withHint={true}
                />
            </Flex>
            <FlexRowAlignCenter
                className={clsx(isTerm ? br.smDownColumnReverse : br.smDownColumn, "scrollbar")}
                style={{
                    flexDirection: isTerm ? "row" : "row-reverse",
                    margin: smDown ? "2rem auto 2rem" : "auto",
                    gap: "3rem",
                    height: "calc(100% - 6rem)",
                    width: "100%",
                    overflowY: smDown ? "scroll" : "auto",
                    justifyContent: smDown ? "unset" : "center",
                    paddingRight: smDown ? "0.5rem" : "0",
                }}>
                {imageSrc && (
                    <AddRemoveImageButton
                        alt={imageAlt}
                        editable={false}
                        image={imageSrc}
                        size="min(35rem, 90%)"
                        style={{ borderRadius: "0.8rem" }}
                    />
                )}
                {!isHtmlTextEmpty && (
                    <HtmlText
                        text={htmlText}
                        className="scrollbar-thin"
                        style={{
                            fontSize: "2.5rem",
                            fontFamily: "var(--knowt-font-name)",
                            fontWeight: 400,
                            // center vertically
                            display: "flex",
                            padding: "0 2rem",
                            lineHeight: "31px",
                            flexDirection: "column",
                            alignItems: plainText.length > 80 ? "flex-start" : "center",
                            justifyContent: "safe center",
                            overflowY: smDown ? "unset" : "auto",
                        }}
                    />
                )}
            </FlexRowAlignCenter>
            <FlexRowAlignCenter
                className={carouselStyles.mobilePrevNextButtons}
                style={{ justifyContent: "space-between", width: "100%" }}>
                {mobileButtonComponents?.prev}
                {mobileButtonComponents?.next}
            </FlexRowAlignCenter>
        </FlashcardMock>
    );
};
