import LazyLoaded from "@/components/LazyLoaded";
import ToggleSwitch from "@/components/ToggleSwitch";
import CloseButton from "@/components/CircularButton/styled/CloseButton";
import ClickableText from "@/components/styled/button/ClickableText";
import { STORAGE_KEYS } from "@knowt/syncing/constants";
import useConfirmDialog from "@/hooks/useConfirmDialog";
import { themeColors } from "@/utils/themeColors";
import { callClearFlashcardSetStudySession } from "@knowt/syncing/hooks/study/graphqlUtils";
import { DEFAULT_INTERVALS, getSpacedRepetitionMultiplier } from "@knowt/syncing/hooks/study/spacedRepetitionUtils";
import { QuestionType, SpacedStudySessionSetting, StudySessionType } from "@knowt/syncing/graphql/schema";
import { toggleArrayElements } from "@knowt/syncing/utils/arrayUtils";
import { getAnswerSide, getAnswerToggles } from "@knowt/syncing/utils/genericUtils";
import useCombinedState from "@knowt/syncing/utils/hooks/useCombinedState";
import dayjs from "dayjs";
import { isEqual } from "lodash";
import dynamic from "next/dynamic";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { addEmitEventListener, removeEmitEventListener } from "../../utils/eventEmitter";
import { START_STUDY_EVENT } from "./SettingsMatchingMode";
import { useStudySettingsContextSelector } from "../../StudySettings";
import AudioSettings from "../AudioSettings";
import { HeaderSection, Divider, Label, Option, OptionLabel, Section } from "../styled/SharedLayouts";
import StartButton from "../StartButton";
import StudyDatePicker from "../styled/StudyDatePicker";
import StudySettingsNumberInput from "../StudySettingsNumberInput";
import TimePeriodOptions from "../TimePeriodOptions";
import {
    validateAnswerSide,
    validateCardsPerSession,
    validateIntervals,
    validateQuestionTypes,
} from "../../utils/utils";
import { SpacedModeSettingsInput } from "../../types";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";

const SpacedRepitionDateWarning = dynamic(
    () => import("@/features/StudySettings/components/SpacedRepitionDateWarning"),
    {
        ssr: false,
    }
);

const SettingsSpacedMode = () => {
    const { smDown } = useBreakPoints();

    const itemId = useStudySettingsContextSelector(state => state.itemId);
    const startNewRound = useStudySettingsContextSelector(state => state.startNewRound);
    const studySession = useStudySettingsContextSelector(state => state.studySession);
    const flashcardSet = useStudySettingsContextSelector(state => state.flashcardSet);
    const onClose = useStudySettingsContextSelector(state => state.onClose);

    const spacedModeSettings = studySession?.settings.SPACED;
    const { openConfirmDialog } = useConfirmDialog();

    const settingsExists = spacedModeSettings && spacedModeSettings.answerSide !== undefined;

    const estimatedNewCards = Math.max(5, Math.floor((flashcardSet?.flashcards?.length ?? 0) * 0.1));

    const [settings, updateSettings] = useCombinedState<SpacedModeSettingsInput>({
        examDate: studySession?.examDate ?? undefined,
        cardsPerSession:
            spacedModeSettings?.cardsPerSession ||
            Math.min(30, Math.max(estimatedNewCards, Math.min(estimatedNewCards, 10))),
        questionTypes: spacedModeSettings?.questionTypes || [QuestionType.NONE],
        ...getAnswerToggles(spacedModeSettings?.answerSide),
        starred: false,
        shuffled: spacedModeSettings?.shuffled ?? true,
        fuzzy: spacedModeSettings?.fuzzy ?? true,
        intervals: spacedModeSettings?.intervals ?? DEFAULT_INTERVALS,
    });

    const [showDateWarningPopup, setShowDateWarningPopup] = useState(false);

    const isSelectedDateBelow30Days =
        settings?.examDate && dayjs.unix(Number(settings?.examDate)) < dayjs().add(30, "days");

    const spacedModeEnabledForBelow30Days = localStorage.getItem(STORAGE_KEYS.SPACED_MODE_ENABLED_FOR_BELOW_30_DAYS);

    useEffect(() => {
        if (settings.examDate && !spacedModeSettings?.intervals?.length) {
            updateSettings({
                intervals: DEFAULT_INTERVALS.map(interval =>
                    Math.floor(interval * getSpacedRepetitionMultiplier(settings.examDate))
                ),
            });
        }
    }, [settings.examDate]);

    const settingsAreChanged = (
        settings: SpacedModeSettingsInput,
        spacedModeSettings?: SpacedStudySessionSetting | null
    ) => {
        if (!spacedModeSettings) {
            return true;
        }

        return (
            settings.examDate !== (studySession?.examDate ?? undefined) ||
            settings.cardsPerSession !== spacedModeSettings.cardsPerSession ||
            settings.questionTypes !== spacedModeSettings.questionTypes ||
            getAnswerSide({ answerWithTerm: settings.answerWithTerm, answerWithDef: settings.answerWithDef }) !==
                spacedModeSettings.answerSide ||
            settings.shuffled !== spacedModeSettings.shuffled ||
            settings.fuzzy !== spacedModeSettings.fuzzy ||
            !isEqual(settings.intervals, spacedModeSettings.intervals)
        );
    };

    const startStudy = async ({ skipDateWarning = false }: { skipDateWarning?: boolean }) => {
        try {
            validateAnswerSide({ answerWithTerm: settings.answerWithTerm, answerWithDef: settings.answerWithDef });
            validateQuestionTypes(settings.questionTypes);
            validateCardsPerSession(settings.cardsPerSession);
            validateIntervals(settings.intervals);
        } catch (err) {
            toast.error(err.message);
            return;
        }

        if (!settingsAreChanged(settings, spacedModeSettings)) {
            return onClose();
        }

        // no date or below 30 days
        if ((!settings.examDate || isSelectedDateBelow30Days) && !spacedModeEnabledForBelow30Days && !skipDateWarning) {
            return setShowDateWarningPopup(true);
        }

        await startNewRound({
            flashcardSetId: itemId,
            examDate: settings.examDate,
            type: StudySessionType.SPACED,
            settings: {
                SPACED: {
                    answerSide: getAnswerSide({
                        answerWithTerm: settings.answerWithTerm,
                        answerWithDef: settings.answerWithDef,
                    }),
                    fuzzy: settings.fuzzy,
                    cardsPerSession: settings.cardsPerSession,
                    questionTypes: settings.questionTypes,
                    shuffled: settings.shuffled,
                    starred: false,
                    intervals: settings.intervals,
                },
            },
        })
            .then(() => {
                onClose();
            })
            .catch(err => {
                toast.error(err.message);
            });
    };

    useEffect(() => {
        addEmitEventListener(START_STUDY_EVENT, () => startStudy({ skipDateWarning: true }));

        return () => {
            removeEmitEventListener(START_STUDY_EVENT, () => startStudy({ skipDateWarning: true }));
        };
    });

    return (
        <>
            <HeaderSection>
                <div className="bold" style={{ fontSize: "3rem", color: themeColors.neutralBlack }}>
                    {smDown ? "" : "Spaced Repetition "}Options
                </div>
                {!settingsExists && (
                    <StartButton label={"start"} onClick={() => startStudy({ skipDateWarning: true })} />
                )}
                {settingsExists && <CloseButton onClick={() => startStudy({ skipDateWarning: true })} />}
            </HeaderSection>

            <React.Fragment>
                <Section>
                    <Label>Studying for an exam?</Label>
                    <Option style={{ flexWrap: "wrap" }}>
                        <OptionLabel label="When is your exam?" />
                        <StudyDatePicker
                            onChange={date => updateSettings({ examDate: date })}
                            date={settings.examDate}
                        />
                    </Option>
                    <Option>
                        <span>How many new cards do you want to see per day?</span>
                        <StudySettingsNumberInput
                            value={settings.cardsPerSession}
                            onChange={n => updateSettings({ cardsPerSession: n })}
                        />
                    </Option>

                    <OptionLabel label="What do you want the time intervals to be?" />
                    <Option>
                        <span>Again</span>
                        <TimePeriodOptions
                            interval={settings.intervals[0]}
                            onChange={n => {
                                const newIntervals = [...settings.intervals];
                                newIntervals[0] = n;
                                updateSettings({ intervals: newIntervals });
                            }}
                        />
                    </Option>
                    <Option>
                        <span>Hard</span>
                        <TimePeriodOptions
                            interval={settings.intervals[1]}
                            onChange={n => {
                                const newIntervals = [...settings.intervals];
                                newIntervals[1] = n;
                                updateSettings({ intervals: newIntervals });
                            }}
                        />
                    </Option>
                    <Option>
                        <span>Good</span>
                        <TimePeriodOptions
                            interval={settings.intervals[2]}
                            onChange={n => {
                                const newIntervals = [...settings.intervals];
                                newIntervals[2] = n;
                                updateSettings({ intervals: newIntervals });
                            }}
                        />
                    </Option>
                    <Option>
                        <span>Easy</span>
                        <TimePeriodOptions
                            interval={settings.intervals[3]}
                            onChange={n => {
                                const newIntervals = [...settings.intervals];
                                newIntervals[3] = n;
                                updateSettings({ intervals: newIntervals });
                            }}
                        />
                    </Option>
                </Section>
                <Divider />
            </React.Fragment>

            <Section>
                <Label>Question Types</Label>
                <Option>
                    <OptionLabel label="Flashcards" />
                    <ToggleSwitch
                        checked={settings.questionTypes.includes(QuestionType.NONE)}
                        onChange={() =>
                            updateSettings({
                                questionTypes: toggleArrayElements(settings.questionTypes, [QuestionType.NONE]),
                            })
                        }
                    />
                </Option>
                <Option>
                    <OptionLabel label="Multiple Choice" />
                    <ToggleSwitch
                        checked={settings.questionTypes.includes(QuestionType.MULTI)}
                        onChange={() =>
                            updateSettings({
                                questionTypes: toggleArrayElements(settings.questionTypes, [QuestionType.MULTI]),
                            })
                        }
                    />
                </Option>
                <Option>
                    <OptionLabel label="Written" />
                    <ToggleSwitch
                        checked={settings.questionTypes.includes(QuestionType.WRITING)}
                        onChange={() =>
                            updateSettings({
                                questionTypes: toggleArrayElements(settings.questionTypes, [QuestionType.WRITING]),
                            })
                        }
                    />
                </Option>
                <Option>
                    <OptionLabel label="True & False" />{" "}
                    <ToggleSwitch
                        checked={settings.questionTypes.includes(QuestionType.TF)}
                        onChange={() =>
                            updateSettings({
                                questionTypes: toggleArrayElements(settings.questionTypes, [QuestionType.TF]),
                            })
                        }
                    />
                </Option>
            </Section>
            <Divider />

            <Section>
                <Label>Question Format</Label>
                <Option>
                    <OptionLabel label="Answer with Term" />
                    <ToggleSwitch
                        checked={settings.answerWithTerm}
                        onChange={() => updateSettings({ answerWithTerm: !settings.answerWithTerm })}
                    />
                </Option>
                <Option>
                    <OptionLabel label="Answer with Definition" />
                    <ToggleSwitch
                        checked={settings.answerWithDef}
                        onChange={() => updateSettings({ answerWithDef: !settings.answerWithDef })}
                    />
                </Option>
            </Section>
            <Divider />

            <Section>
                <Label>Learning Options</Label>
                <Option>
                    <OptionLabel label="Shuffle terms" />
                    <ToggleSwitch
                        checked={settings.shuffled}
                        onChange={() => updateSettings({ shuffled: !settings.shuffled })}
                    />
                </Option>
                <Option>
                    <OptionLabel label="Smart grading" />
                    <ToggleSwitch
                        checked={settings.fuzzy}
                        onChange={() => updateSettings({ fuzzy: !settings.fuzzy })}
                    />
                </Option>
            </Section>
            <Divider />

            <AudioSettings />

            {settingsExists && <Divider />}
            {settingsExists && (
                <Section>
                    <ClickableText
                        style={{ color: themeColors.primary }}
                        onClick={() => startStudy({ skipDateWarning: true })}>
                        Save options
                    </ClickableText>
                    <ClickableText
                        style={{ color: themeColors.icon3 }}
                        onClick={async () => {
                            onClose();
                            openConfirmDialog({
                                title: `Are you sure you want to reset?`,
                                subtitle:
                                    "Resetting will not save your options, but rather start your learn mode again from scratch",
                                style: { borderRadius: "4rem" },
                                rounded: true,
                                cardSize: "60rem",
                                spacing: 2.2,
                                subtitleFontSize: "1.9rem",
                                subtitleFontWeight: "500",
                                buttonColor: "black",
                                simpleCancelBtn: true,
                                onConfirm: async () => {
                                    toast.loading("Resetting progress...", { id: "RESET_STUDY" });
                                    await callClearFlashcardSetStudySession({
                                        flashcardSetId: itemId,
                                    });
                                    toast.success("Study session cleared!", { id: "RESET_STUDY" });
                                    window.location.reload();
                                },
                            });
                        }}>
                        Reset progress & restart
                    </ClickableText>
                </Section>
            )}

            <LazyLoaded load={showDateWarningPopup}>
                <SpacedRepitionDateWarning
                    isOpen={showDateWarningPopup}
                    onClose={() => setShowDateWarningPopup(false)}
                    onCancel={async () => {
                        localStorage.setItem(STORAGE_KEYS.SPACED_MODE_ENABLED_FOR_BELOW_30_DAYS, "true");
                        await startStudy({
                            skipDateWarning: true,
                        });
                    }}
                    recommendLearnMode={!!isSelectedDateBelow30Days}
                />
            </LazyLoaded>
        </>
    );
};

export default SettingsSpacedMode;
