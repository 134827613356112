import OutlineTextButton from "@/components/CircularButton/styled/OutlineTextButton";
import clsx from "clsx";

const StudyNavbarTextBtn = ({
    children,
    sx,
    onClick,
    tooltip,
    className,
    ...restProps
}: {
    children: React.ReactNode;
    sx?: React.CSSProperties | { width?: string; height?: string };
    tooltip?: string;
    onClick?: (e: React.MouseEvent) => void;
    className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    return (
        <OutlineTextButton
            {...restProps}
            className={clsx("body1", className)}
            onClick={onClick}
            sx={{
                width: "auto",
                height: "4.4rem",
                padding: "0 2.4rem",
                fontSize: "1.4rem",
                fontWeight: "400",
                gap: "0.8rem",
                ...sx,
            }}
            tooltip={tooltip}
            text={children}
        />
    );
};

export default StudyNavbarTextBtn;
