import { createFlashcardSetViewer, updateFlashcardSetViewer } from "@/graphql/mutations";
import { getFlashcardSetViewer } from "@/graphql/queries";
import { platform } from "@/platform";
import {
    FlashcardReviewMode,
    FlashcardSetViewer,
    FlashcardSide,
    FlashcardStudyFrom,
} from "@knowt/syncing/graphql/schema";
import { client, ServerClientWithCookies } from "@/utils/SyncUtils";

export const callCreateFlashcardSetViewer = async ({
    userId,
    flashcardSetId,
    serverClient,
}: {
    userId: string;
    flashcardSetId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    const input = {
        userId,
        flashcardSetId,
        position: 0,
        starred: [],
        answerSide: FlashcardSide.TERM,
        reviewMode: FlashcardReviewMode.STANDARD,
        studyFrom: FlashcardStudyFrom.ALL,
        studiedFlashcards: [],
        shuffled: false,
        lastViewedFlashcard: null,
        order: null,
    };

    return await client
        .mutate({
            mutation: createFlashcardSetViewer,
            variables: { input },
            serverClient,
        })
        .then(({ data }) => data.createFlashcardSetViewer)
        .catch(async error => {
            const { report } = await platform.analytics.logging();
            report(error, "createFlashcardSetViewer", input);
            throw error;
        });
};

export const callUpdateFlashcardSetViewer = async (
    flashcardSetViewerData: { userId: string; flashcardSetId: string } & Partial<FlashcardSetViewer>,
    serverClient?: ServerClientWithCookies
) => {
    return await client
        .mutate({
            mutation: updateFlashcardSetViewer,
            variables: { input: { ...flashcardSetViewerData, studiedFlashcards: null } },
            serverClient,
        })
        .then(({ data }) => data.updateFlashcardSetViewer)
        .catch(async error => {
            const { report } = await platform.analytics.logging();
            report(error, "updateFlashcardSetViewer", flashcardSetViewerData);
            throw error;
        });
};

export const callGetFlashcardSetViewer = async ({
    userId,
    flashcardSetId,
    serverClient,
}: {
    userId: string | null | undefined;
    flashcardSetId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    if (!userId || !flashcardSetId) return null;
    return await client
        .query({
            query: getFlashcardSetViewer,
            variables: { input: { userId, flashcardSetId } },
            serverClient,
        })
        .then(({ data }) => data.getFlashcardSetViewer)
        // the mutation throws error for non-existing flashcardSetViewer
        .catch(() => null);
};
