// eslint-disable-next-line no-restricted-imports
import { SxProps } from "@mui/material";
import CircularRectTextButton from "./CircularRectTextButton";
import { themeColors } from "@/utils/themeColors";

export const FilledTextButton = ({
    text,
    onClick,
    tooltip,
    sx,
}: {
    borderColor?: string;
    text: React.ReactNode;
    onClick?: () => void;
    tooltip?: string;
    sx?: SxProps;
}) => {
    return (
        <CircularRectTextButton
            component={onClick ? "button" : "div"}
            sx={{
                background: themeColors.neutralWhite,
                color: themeColors.neutralBlack,
                fontSize: "1.4rem",
                width: "auto",
                padding: "0.5rem 1.5rem",
                height: "3.8rem",
                "&:hover": {
                    background: themeColors.neutralBlack,
                    color: themeColors.neutralWhite,
                },
                "&:active": {
                    transform: "scale(0.97)",
                },
                transition: "all 0.2s ease",
                ...sx,
            }}
            onClick={onClick}
            tooltip={tooltip}>
            {text}
        </CircularRectTextButton>
    );
};

export default FilledTextButton;
