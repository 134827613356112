import { getPlainTextFromContent } from "@knowt/editor/helpers/getPlainTextFromContent";
import { capitalize, ignoreAccent, normalizeQuote, normalizeSpace } from "@knowt/syncing/utils/stringUtils";
import { getOppositeFlashcardSide, isFlashcardContentEmpty } from "../flashcards/utils";
import { UserAnswer, UserAnswers } from "./useStudySession";
import { fromEntries } from "@/utils/genericUtils";
import {
    Flashcard,
    FlashcardSetStudySessionQuestion,
    FlashcardSide,
    QuestionType,
    StudySession,
    StudySessionSettings,
    StudySessionType,
} from "@knowt/syncing/graphql/schema";

export const STANDARD_FLASHCARD_OPTION_TEXT = { STILL_LEARNING: "Still learning this", GOT_IT_RIGHT: "Got it right" };

export const gradeFlashcards = async ({
    userAnswers,
    flashcardData,
    settings,
    mode,
}: {
    userAnswers: UserAnswers;
    flashcardData: Record<string, Flashcard>;
    settings: StudySessionSettings;
    mode: StudySessionType;
}): Promise<UserAnswers> => {
    const questions = Object.keys(userAnswers).map(flashcardId => ({
        flashcardId,
        ...userAnswers[flashcardId],
    }));

    if (mode === StudySessionType.MATCH)
        return fromEntries(questions.map(({ flashcardId, ...rest }) => [flashcardId, rest]));

    const isFuzzyMatchGradingType = settings[mode].fuzzy;

    for (const question of questions) {
        if (question.isCorrect !== undefined) {
            continue;
        }

        const isAnswerWithTerm = question.side === FlashcardSide.TERM;
        const currAnswerVal = question.answer;

        const flashcard = flashcardData[question.flashcardId];

        const term = (await isFlashcardContentEmpty(flashcard.term)) ? "" : flashcard.term;
        const definition = (await isFlashcardContentEmpty(flashcard.definition)) ? "" : flashcard.definition;

        let isCorrect = false;

        switch (question.questionType) {
            case QuestionType.NONE:
                isCorrect = currAnswerVal === STANDARD_FLASHCARD_OPTION_TEXT.GOT_IT_RIGHT;
                break;

            case QuestionType.MULTI: {
                isCorrect = question.selectedFlashcardId === flashcard.flashcardId;
                break;
            }

            case QuestionType.TF:
                // true, false -> "True", "False"
                isCorrect = capitalize(question.tfIsCorrect.toString()) === currAnswerVal;
                break;

            case QuestionType.WRITING: {
                if (!currAnswerVal) {
                    break;
                }

                isCorrect = await computeCorrectness(
                    isAnswerWithTerm ? term : definition,
                    currAnswerVal,
                    isFuzzyMatchGradingType
                );
                break;
            }
        }

        question.isCorrect = isCorrect;
    }

    return fromEntries(questions.map(({ flashcardId, ...rest }) => [flashcardId, rest]));
};

const constructAnswers = (rawCorrectAnswer: string, rawUserAnswer: string, isFuzzyMatchGradingType: boolean) => {
    const correctAnswer = getPlainTextFromContent({ content: rawCorrectAnswer, type: "flashcard" })
        .replace(/\s+/g, " ")
        .trim()
        .toLowerCase();

    //replace all \n with <br> and then wrap in a p tag
    rawUserAnswer = "<p>" + rawUserAnswer.replace(/\n/g, "<br>") + "</p>";

    const userAnswer = getPlainTextFromContent({ content: rawUserAnswer, type: "flashcard" })
        .replace(/\s+/g, " ")
        .trim()
        .toLowerCase();

    if (isFuzzyMatchGradingType) {
        return [ignoreAccent(correctAnswer), ignoreAccent(userAnswer)];
    } else {
        return [normalizeQuote(correctAnswer), normalizeQuote(userAnswer)];
    }
};

export const computeCorrectness = (
    rawCorrectAnswer: string,
    rawUserAnswer: string,
    isFuzzyMatchGradingType: boolean
) => {
    const [correctAnswer, userAnswer] = constructAnswers(rawCorrectAnswer, rawUserAnswer, isFuzzyMatchGradingType);
    if (isFuzzyMatchGradingType) {
        return (
            isUserAnswerCorrect(userAnswer, correctAnswer) ||
            isCorrectFuzzyFlashcardAnswer({ userAnswer, correctAnswer })
        );
    } else {
        return isUserAnswerCorrect(userAnswer, correctAnswer);
    }
};

export const isCorrectFuzzyFlashcardAnswer = async ({
    userAnswer,
    correctAnswer,
}: {
    userAnswer: string;
    correctAnswer: string;
}) => {
    const { token_sort_ratio } = await import("fuzzball");
    // https://www.npmjs.com/package/fuzzball
    // TODO: maybe switch to basic ratio function?
    const score = token_sort_ratio(userAnswer, correctAnswer);
    return score > 80 || isPartialAnswerMatch({ userAnswer, correctAnswer });
};

/**
 * This is used to match answers like "The" and "The (optional)", and "Answer One/Answer Two" as "Answer One" or "Answer Two"
 * @param param0
 * @returns
 */
const isPartialAnswerMatch = ({
    userAnswer,
    correctAnswer,
}: {
    userAnswer: string;
    correctAnswer: string;
}): boolean => {
    const userAnswerChoices = getAnswerChoices(userAnswer);
    const correctAnswerChoices = getAnswerChoices(correctAnswer);

    // If there are no optional answers
    if (userAnswerChoices.possibleAnswers.length === 0) {
        return false;
    }

    return userAnswerChoices.possibleAnswers.some(
        answer =>
            answer &&
            (correctAnswerChoices.possibleAnswers.includes(answer) || correctAnswerChoices.optionalAnswer === answer)
    );
};

//for the users answer => each answer they give has to appear in
// answerChoices. if they give an optional answer it has to match
// the optional answer in the correctAnswer
const getAnswerChoices = (answer: string): { possibleAnswers: string[]; optionalAnswer: string } => {
    const optionalAnswer: string = answer.substring(answer.indexOf("(") + 1, answer.indexOf(")"));

    const _answer = answer.replace(answer.substring(answer.indexOf("("), answer.indexOf(")") + 1), "").trim();

    const possibleAnswers = _answer.split("/").map(choice => normalizeSpace(choice.trim()));
    return { possibleAnswers, optionalAnswer };
};

const isUserAnswerCorrect = (userAnswer: string, correctAnswer: string): boolean => {
    return correctAnswer === userAnswer;
};

export const getFirstActiveStudyMode = (studySession: StudySession): StudySessionType => {
    const studyChecksOrder = [
        StudySessionType.LEARN,
        StudySessionType.SPACED,
        StudySessionType.TEST,
        StudySessionType.MATCH,
        StudySessionType.REVIEW,
    ];

    return studyChecksOrder.find(type => !!studySession.settings?.[type]);
};

export const withSide = (answerSide: FlashcardSide = FlashcardSide.DEFINITION): FlashcardSide => {
    return answerSide === FlashcardSide.BOTH
        ? Math.random() > 0.7
            ? FlashcardSide.TERM
            : FlashcardSide.DEFINITION
        : answerSide;
};

export const createExplainWhyImWrongInput = ({
    currQuestion,
    flashcard,
    userAnswer,
}: {
    currQuestion?: FlashcardSetStudySessionQuestion | null;
    flashcard?: Flashcard | null;
    userAnswer: UserAnswer;
}) => {
    if (!currQuestion || !flashcard || userAnswer?.isCorrect) return null;

    const question = getPlainTextFromContent({
        content: flashcard?.[getOppositeFlashcardSide(currQuestion.answerSide).toLowerCase()],
        type: "flashcard",
    });

    const correctAnswer = getPlainTextFromContent({
        content: flashcard?.[currQuestion.answerSide.toLowerCase()],
        type: "flashcard",
    });

    // this flashcard probably contains images instead, so we can't really do anything
    if (!question || !correctAnswer) return null;

    return `Question: ${question}\nMy Answer: ${
        getPlainTextFromContent({ content: userAnswer.answer, type: "flashcard" }) || ""
    }\nCorrect Answer: ${correctAnswer}`;
};
