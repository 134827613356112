export const tooltips = {
    "When is your exam?": "Tell us when you exam is so we can help you master all your flashcards by then.",
    "How many new cards do you want to see per day?":
        "Pick how many new flashcards you want to practice each day during your spaced repetition session.",
    "Answer with Term":
        "We'll ask questions using the definition side of your flashcards so you can practice recalling the terms.",
    "Answer with Definition":
        "We'll ask questions using the terms side of your flashcards so you can practice recalling the definitions.",
    "Study starred terms only":
        "We'll only test you on the starred flashcards so you can focus on mastering those first.",
    "Smart grading": "We'll ignore typos and minor mistakes when grading your responses.",
    "Re-type answers":
        "When you get a written flashcard wrong, we'll ask you to type it again to reinforce the correct answer.",
    "Cards sorting":
        "Sort your cards to focus on the terms you need to review more. Turn sorting off if you want to quickly review your flashcards.",
    "Writing Review Mode": "We'll make you type the answer & tell you if you're right.",
    "Grid Mode On": "Grid mode arranges all the cards in a grid so you can see them clearly.",
    "How many cards do you want to match?": "Pick how many flashcards you want to practice in this round.",
    "What do you want the time intervals to be?": `Changing the time intervals allows you to modify how soon you see a card each time you select that button. We recommend sticking to our suggested intervals if you're a beginner. If you do choose to customize the intervals, we recommend going in ascending order with "Again" having the shortest interval and "Easy" having the longest.`,
    "Length of Rounds": "Set how many questions you want in each round. We recommend keeping the default!",
};
