import { Rem } from "@/types/common";
import { X } from "lucide-react";
import CircularOutlineIcon from "./CircularOutlineIcon";
import { iconSizes } from "@/utils/iconProps";

const CloseButton = ({
    onClick,
    radius = "4rem",
    size = iconSizes.MD,
}: {
    onClick?: () => void;
    radius?: Rem;
    size?: number;
    borderColor?: string;
}) => {
    return <CircularOutlineIcon radius={radius} onClick={onClick} Icon={X} size={size} />;
};

export default CloseButton;
