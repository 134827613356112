import Select from "@/components/Select";
import { FlexRowAlignCenter } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";
import { TIME_SECONDS } from "@knowt/syncing/utils/dateTimeUtils";
import useCombinedState from "@knowt/syncing/utils/hooks/useCombinedState";
import StudySettingsNumberInput from "./StudySettingsNumberInput";
import { useEffect } from "react";
import { getMappedValuePeriod } from "@knowt/syncing/hooks/study/spacedRepetitionUtils";

const OPTIONS_OBJ = {
    [TIME_SECONDS.SECOND]: { label: "seconds", value: TIME_SECONDS.SECOND },
    [TIME_SECONDS.MINUTE]: { label: "minutes", value: TIME_SECONDS.MINUTE },
    [TIME_SECONDS.HOUR]: { label: "hours", value: TIME_SECONDS.HOUR },
    [TIME_SECONDS.DAY]: { label: "days", value: TIME_SECONDS.DAY },
};

const TimePeriodOptions = ({
    interval,
    onChange,
    disabled,
}: {
    interval: number;
    onChange: (value: number) => void;
    disabled?: boolean;
}) => {
    const [states, updateStates] = useCombinedState(() => {
        const { value, period } = getMappedValuePeriod(interval);
        return { value, period };
    });

    useEffect(() => {
        const { value, period } = getMappedValuePeriod(interval);
        updateStates({ value, period });
    }, [interval]);

    const handleChange = ({ value, period }: { value: number; period: TIME_SECONDS }) => {
        updateStates({ value, period });
        onChange(value * period);
    };

    // chop off "s" if value is 1
    const options = Object.values(OPTIONS_OBJ).map(({ label, value }) => ({
        label: `${states.value === 1 ? label.slice(0, -1) : label}`,
        value,
    }));

    return (
        <FlexRowAlignCenter style={{ gap: "1rem" }}>
            <StudySettingsNumberInput
                value={states.value}
                disabled={disabled}
                onChange={value => !disabled && handleChange({ value, period: states.period })}
            />
            <Select
                options={options}
                onChange={({ value: period }) => handleChange({ value: states.value, period })}
                menuSx={{ width: "12rem" }}
                btnSx={{
                    width: "13rem",
                    gap: "1rem",
                    border: `1px solid ${themeColors.neutralBlack}`,
                    height: "4.6rem",
                    borderRadius: 999,
                }}
                disabled={disabled}
                selected={options.find(({ value }) => value === states.period) || options[0]}
            />
        </FlexRowAlignCenter>
    );
};

export default TimePeriodOptions;
