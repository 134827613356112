import { StudySessionProgressEnum } from "@knowt/syncing/graphql/schema";
import { themeColors } from "./themeColors";

export const getProgressPillProps = (progress?: StudySessionProgressEnum) => {
    if (!progress) {
        return {
            label: undefined,
            backgroundColor: undefined,
            color: undefined,
            progressBg: undefined,
            progressValue: undefined,
        };
    }

    return {
        label: getLabel(progress),
        backgroundColor: getBackgroundColor(progress),
        color: getColor(progress),
        progressBg: getProgressBg(progress),
        progressValue: getProgressValue(progress),
    };
};

export const getLabel = (progress: StudySessionProgressEnum) => {
    switch (progress) {
        case StudySessionProgressEnum.NEW:
            return "New cards";
        case StudySessionProgressEnum.LEARNING:
            return "Still learning";
        case StudySessionProgressEnum.REVIEWING:
            return "Almost done";
        case StudySessionProgressEnum.MASTERED:
            return "Mastered";
    }
};

export const getProgressValue = (progress: StudySessionProgressEnum) => {
    switch (progress) {
        case StudySessionProgressEnum.NEW:
            return 0;
        case StudySessionProgressEnum.LEARNING:
            return 25;
        case StudySessionProgressEnum.REVIEWING:
            return 75;
        case StudySessionProgressEnum.MASTERED:
            return 100;
    }
};

export const getColor = (progress: StudySessionProgressEnum) => {
    switch (progress) {
        case StudySessionProgressEnum.NEW:
            return themeColors.mcStudyDark1;
        case StudySessionProgressEnum.LEARNING:
            return themeColors.mcStudyDark2;
        case StudySessionProgressEnum.REVIEWING:
            return themeColors.mcStudyDark3;
        case StudySessionProgressEnum.MASTERED:
            return themeColors.mcStudyDark4;
    }
};

export const getBackgroundColor = (progress: StudySessionProgressEnum) => {
    switch (progress) {
        case StudySessionProgressEnum.NEW:
            return themeColors.mcStudyLight1;
        case StudySessionProgressEnum.LEARNING:
            return themeColors.mcStudyLight2;
        case StudySessionProgressEnum.REVIEWING:
            return themeColors.mcStudyLight3;
        case StudySessionProgressEnum.MASTERED:
            return themeColors.mcStudyLight4;
    }
};

export const getProgressBg = (progress: StudySessionProgressEnum) => {
    switch (progress) {
        case StudySessionProgressEnum.NEW:
            return themeColors.mcStudyMed1;
        case StudySessionProgressEnum.LEARNING:
            return themeColors.mcStudyMed2;
        case StudySessionProgressEnum.REVIEWING:
            return themeColors.mcStudyMed3;
        case StudySessionProgressEnum.MASTERED:
            return themeColors.mcStudyMed4;
    }
};
