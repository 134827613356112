import { StudySessionProgressEnum } from "@knowt/syncing/graphql/schema";
import { TIME_SECONDS } from "@/utils/dateTimeUtils";

export const SHOW_EARLY_BY = TIME_SECONDS.MINUTE * 20;

export const ORDERED_FLASHCARD_STUDY_SECTIONS = [
    StudySessionProgressEnum.NEW,
    StudySessionProgressEnum.LEARNING,
    StudySessionProgressEnum.REVIEWING,
    StudySessionProgressEnum.MASTERED,
];

export const DEFAULT_STUDY_PROGRESS = {
    [StudySessionProgressEnum.NEW]: 0,
    [StudySessionProgressEnum.LEARNING]: 0,
    [StudySessionProgressEnum.REVIEWING]: 0,
    [StudySessionProgressEnum.MASTERED]: 0,
};
