import ToggleSwitch from "@/components/ToggleSwitch";
import CloseButton from "@/components/CircularButton/styled/CloseButton";
import ClickableText from "@/components/styled/button/ClickableText";
import { themeColors } from "@/utils/themeColors";
import { useFlashcardSetViewer } from "@knowt/syncing/hooks/flashcardSetViewer/useFlashcardSetViewer";
import {
    FlashcardSide,
    QuestionType,
    ReviewStudySessionSetting,
    StudySessionType,
} from "@knowt/syncing/graphql/schema";
import { getAnswerSide, getAnswerToggles } from "@knowt/syncing/utils/genericUtils";
import useCombinedState from "@knowt/syncing/utils/hooks/useCombinedState";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { addEmitEventListener, removeEmitEventListener } from "../../utils/eventEmitter";
import { START_STUDY_EVENT } from "./SettingsMatchingMode";
import { useStudySettingsContextSelector } from "../../StudySettings";
import { HeaderSection, Divider, Label, Option, OptionLabel, Section } from "../styled/SharedLayouts";
import { validateAnswerSide } from "../../utils/utils";
import { ReviewModeSettingsInput } from "../../types";
import useConfirmDialog from "@/hooks/useConfirmDialog";
import { assertTruthy } from "@knowt/syncing/utils/assertions";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";

const SettingsReviewMode = () => {
    const { smDown } = useBreakPoints();

    const itemId = useStudySettingsContextSelector(state => state.itemId);
    const startNewRound = useStudySettingsContextSelector(state => state.startNewRound);
    const studySession = useStudySettingsContextSelector(state => state.studySession);
    const flashcardSet = useStudySettingsContextSelector(state => state.flashcardSet);
    const onClose = useStudySettingsContextSelector(state => state.onClose);
    const updateStudySessionViewer = useStudySettingsContextSelector(state => state.updateStudySessionViewer);
    const fromOverview = useStudySettingsContextSelector(state => state.fromOverview);

    const { openConfirmDialog } = useConfirmDialog();
    const reviewModeSettings = studySession?.settings.REVIEW;

    const { isStarredCardsExist } = useFlashcardSetViewer({ flashcardSetId: itemId });

    const settingsExists = reviewModeSettings && reviewModeSettings.answerSide !== undefined;

    const [settings, updateSettings] = useCombinedState<ReviewModeSettingsInput>({
        ...getAnswerToggles(reviewModeSettings?.answerSide || FlashcardSide.DEFINITION),
        answerSide: reviewModeSettings?.answerSide || FlashcardSide.DEFINITION,
        questionTypes: reviewModeSettings?.questionTypes || [QuestionType.NONE],
        starred: !!reviewModeSettings?.starred,
        shuffled: !!reviewModeSettings?.shuffled,
        fuzzy: !!reviewModeSettings?.fuzzy ?? true,
        reType: false,
        sort: !!reviewModeSettings?.sort ?? false,
    });

    const settingsAreChanged = (
        settings: ReviewModeSettingsInput,
        reviewModeSettings?: ReviewStudySessionSetting | null
    ) => {
        if (!reviewModeSettings) {
            return true;
        }

        return (
            settings.questionTypes !== reviewModeSettings.questionTypes ||
            getAnswerSide({ answerWithTerm: settings.answerWithTerm, answerWithDef: settings.answerWithDef }) !==
                reviewModeSettings.answerSide ||
            settings.starred !== reviewModeSettings.starred ||
            settings.shuffled !== reviewModeSettings.shuffled ||
            settings.fuzzy !== reviewModeSettings.fuzzy ||
            settings.reType !== reviewModeSettings.reType ||
            settings.sort !== reviewModeSettings.sort
        );
    };

    const startStudy = async () => {
        try {
            validateAnswerSide({ answerWithTerm: settings.answerWithTerm, answerWithDef: settings.answerWithDef });
        } catch (err) {
            toast.error(err.message);
            return;
        }

        if (!settingsAreChanged(settings, reviewModeSettings)) {
            return onClose();
        }

        // TODO: remove this after we migrate to studySession
        assertTruthy(updateStudySessionViewer, "updateStudySessionViewer is not defined");
        await updateStudySessionViewer({
            isShuffled: settings.shuffled,
            flashcards: flashcardSet?.flashcards ?? [],
            // if we toggle the sorting option, reset the index to 0
            ...(reviewModeSettings?.sort !== settings.sort && { position: 0 }),
        });

        await startNewRound({
            flashcardSetId: itemId,
            type: StudySessionType.REVIEW,
            settings: {
                REVIEW: {
                    questionTypes: settings.questionTypes,
                    answerSide: getAnswerSide({
                        answerWithTerm: settings.answerWithTerm,
                        answerWithDef: settings.answerWithDef,
                    }),
                    fuzzy: settings.fuzzy,
                    shuffled: settings.shuffled,
                    starred: settings.starred,
                    reType: settings.reType,
                    sort: settings.sort,
                },
            },
        })
            .then(() => {
                onClose();
            })
            .catch(err => {
                toast.error(err.message);
            });
    };

    useEffect(() => {
        addEmitEventListener(START_STUDY_EVENT, startStudy);

        return () => {
            removeEmitEventListener(START_STUDY_EVENT, startStudy);
        };
    });

    const isWritingMode = settings.questionTypes.includes(QuestionType.WRITING);

    return (
        <>
            <HeaderSection>
                <div className="bold" style={{ fontSize: "3rem" }}>
                    {smDown ? "" : "Flashcards "}Options
                </div>
                <CloseButton onClick={startStudy} />
            </HeaderSection>

            <Section>
                <Label>Question Format</Label>
                <Option>
                    <OptionLabel label="Answer with Term" />
                    <ToggleSwitch
                        checked={settings.answerWithTerm}
                        onChange={() => updateSettings({ answerWithTerm: !settings.answerWithTerm })}
                    />
                </Option>
                <Option>
                    <OptionLabel label="Answer with Definition" />
                    <ToggleSwitch
                        checked={settings.answerWithDef}
                        onChange={() => updateSettings({ answerWithDef: !settings.answerWithDef })}
                    />
                </Option>
            </Section>
            <Divider />

            <Section>
                <Label>Learning Options</Label>
                <Option>
                    <OptionLabel label="Cards sorting" />
                    <ToggleSwitch
                        checked={settings.sort}
                        onChange={val =>
                            updateSettings({
                                sort: val,
                            })
                        }
                    />
                </Option>

                {!fromOverview && (
                    <Option>
                        <OptionLabel label="Writing Review Mode" />
                        <ToggleSwitch
                            checked={isWritingMode}
                            onChange={val =>
                                val
                                    ? updateSettings({ questionTypes: [QuestionType.WRITING] })
                                    : updateSettings({ questionTypes: [QuestionType.NONE] })
                            }
                        />
                    </Option>
                )}
                <Option>
                    <OptionLabel label="Study starred terms only" />
                    <ToggleSwitch
                        checked={settings.starred}
                        onChange={() => updateSettings({ starred: !settings.starred })}
                        disabled={!isStarredCardsExist}
                    />
                </Option>
                <Option>
                    <OptionLabel label="Shuffle terms" />
                    <ToggleSwitch
                        checked={settings.shuffled}
                        onChange={() => updateSettings({ shuffled: !settings.shuffled })}
                    />
                </Option>
                {!fromOverview && (
                    <Option>
                        <OptionLabel label="Smart grading" />
                        <ToggleSwitch
                            checked={settings.fuzzy}
                            onChange={() => {
                                if (!isWritingMode) {
                                    toast.error("You must enable writing review mode to use this feature");
                                } else {
                                    updateSettings({ fuzzy: !settings.fuzzy });
                                }
                            }}
                            disabled={!isWritingMode}
                        />
                    </Option>
                )}
                {/* TODO: add back retype */}
                {/* <Option>
                    <OptionLabel label="Re-type answers" />
                    <ToggleSwitch
                        checked={settings.reType}
                        onChange={() => {
                            if (!isWritingMode) {
                                toast.error("You must enable writing review mode to use this feature");
                            } else {
                                updateSettings({ reType: !settings.reType });
                            }
                        }}
                        disabled={!isWritingMode}
                    />
                </Option> */}
            </Section>

            {settingsExists && <Divider />}
            <Section>
                <ClickableText style={{ color: themeColors.primary }} onClick={startStudy}>
                    Save options
                </ClickableText>
                {reviewModeSettings?.sort && (
                    <ClickableText
                        style={{ color: themeColors.icon3 }}
                        onClick={async () => {
                            onClose();
                            openConfirmDialog({
                                title: `Are you sure you want to reset?`,
                                subtitle:
                                    "Resetting will not save your options, but rather start your flashcards sorting again from scratch",
                                style: { borderRadius: "4rem" },
                                rounded: true,
                                cardSize: "60rem",
                                spacing: 2.2,
                                subtitleFontSize: "1.9rem",
                                subtitleFontWeight: "500",
                                buttonColor: "black",
                                simpleCancelBtn: true,
                                onConfirm: async () => {
                                    toast.loading("Resetting progress...", { id: "RESET_STUDY" });
                                    await startNewRound({
                                        flashcardSetId: itemId,
                                        type: StudySessionType.REVIEW,
                                        settings: {
                                            REVIEW: {
                                                ...reviewModeSettings,
                                                reset: true,
                                            },
                                        },
                                    });
                                    toast.success("Study session cleared!", { id: "RESET_STUDY" });
                                    window.location.reload();
                                },
                            });
                        }}>
                        Reset progress & restart
                    </ClickableText>
                )}
            </Section>
        </>
    );
};

export default SettingsReviewMode;
