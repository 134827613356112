import { isEqual } from "lodash";
import shuffle from "lodash/shuffle";
import { useCallback, useRef, useState } from "react";
import { callUpdateFlashcardSetViewer } from "./graphqlUtils";
import { useSWRImmutable } from "@knowt/syncing/hooks/swr";
import { getOrCreateFlashcardSetViewer } from "@/hooks/flashcardSetViewer/utils";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import {
    Flashcard,
    FlashcardSetViewer,
    FlashcardSetViewerInput,
    FlashcardSide,
    FlashcardStudyFrom,
} from "@knowt/syncing/graphql/schema";
import { toggleArrayElements } from "@/utils/arrayUtils";
import { deepScrapeEmptyFields } from "@/utils/dataCleaning";
import { populateCacheWithFallbackData } from "@/hooks/swr";
import { assertTruthy } from "@/utils/assertions";

export const INITIAL_FLASHCARD_VIEWER_DATA: FlashcardSetViewer = {
    __typename: "FlashcardSetViewer",
    // Not used anymore, use study session instead
    studyFrom: FlashcardStudyFrom.ALL,
    answerSide: FlashcardSide.DEFINITION,
    shuffled: false,
    flashcardSetId: "",
    userId: "",
};

const useFlashcardSetViewerUpdateHelpers = ({
    flashcardSetId,
    loading,
    error,
}: {
    flashcardSetId: string | null | undefined;
    loading: boolean;
    error: Error;
}) => {
    const { userId } = useCurrentUser();

    const pendingUpdates = useRef<FlashcardSetViewerInput | undefined>(undefined);
    const lastCommit = useRef<number>(null);

    const [isDirty, setIsDirty] = useState(false);

    const commitUpdates = useCallback(async () => {
        if (!userId) {
            setIsDirty(false);
            return;
        }

        if (loading || error) return;

        const commitStartTime = Date.now();
        lastCommit.current = commitStartTime;

        const pendingData = pendingUpdates.current;
        pendingUpdates.current = undefined;

        if (pendingData) {
            await callUpdateFlashcardSetViewer({
                ...pendingData,
                userId,
                flashcardSetId,
            });
        }

        const isLastCommit = lastCommit.current === commitStartTime;
        const noPendingUpdates = !pendingUpdates.current;

        if (isLastCommit && noPendingUpdates) setIsDirty(false);
    }, [error, flashcardSetId, loading, userId]);

    const enqueueUpdate = useCallback((data: Omit<FlashcardSetViewerInput, "userId" | "flashcardSetId">) => {
        pendingUpdates.current = { ...pendingUpdates.current, ...data };
        setIsDirty(true);
    }, []);

    return { isDirty, commitUpdates, enqueueUpdate };
};

export const useFlashcardSetViewer = ({
    flashcardSetId,
    fallbackData,
    autoCommit = false,
}: {
    flashcardSetId?: string | null | undefined;
    fallbackData?: FlashcardSetViewer;
    autoCommit?: boolean;
}) => {
    const { userId: _userId, viewerId, loginInProgress } = useCurrentUser();
    const userId = _userId || viewerId;

    const { data: flashcardSetViewerData, mutate } = useSWRImmutable<FlashcardSetViewer>(
        userId && flashcardSetId && ["flashcardSetViewer", userId, flashcardSetId],
        async () => {
            const flashcardSetViewer = await getOrCreateFlashcardSetViewer({ userId, flashcardSetId });
            _setFlashcardIndex(flashcardSetViewer.position || 0);
            return flashcardSetViewer;
        },
        {
            fallbackData: fallbackData ?? undefined,
            use: [populateCacheWithFallbackData],
        }
    );

    const [flashcardIndex, _setFlashcardIndex] = useState(flashcardSetViewerData?.position ?? fallbackData?.position);

    const isLoading = !flashcardSetViewerData || loginInProgress;

    const { enqueueUpdate, commitUpdates, isDirty } = useFlashcardSetViewerUpdateHelpers({
        flashcardSetId,
        loading: isLoading,
        error: null,
    });

    const update = useCallback(
        async (
            updates: Partial<FlashcardSetViewer> | ((updates: FlashcardSetViewer) => Partial<FlashcardSetViewer>)
        ) => {
            if (isLoading) return;
            let starredChanged = false;

            const optimisticUpdatedData = await mutate(
                oldData => {
                    assertTruthy(oldData, "oldData must be in the cache by the time we call update");

                    if (typeof updates === "function") {
                        updates = updates(oldData);

                        if (updates.starred && !isEqual(oldData.starred, updates.starred)) {
                            starredChanged = true;
                        }
                    }

                    return { ...oldData, ...updates };
                },
                { revalidate: false }
            );

            if (starredChanged) {
                // if the starred changed, and its shuffled, we need to update the order
                await callUpdateFlashcardSetViewer({ userId, flashcardSetId, ...optimisticUpdatedData });
            } else if (typeof updates !== "function") {
                enqueueUpdate(updates);
            }

            if (autoCommit) {
                await commitUpdates();
            }

            return optimisticUpdatedData;
        },
        [isLoading, mutate, autoCommit, userId, flashcardSetId, enqueueUpdate, commitUpdates]
    );

    const toggleStarFlashcards = useCallback(
        async (flashcardIds: string[]) =>
            update(oldData => {
                const newStarred = toggleArrayElements(oldData.starred ?? [], flashcardIds);
                return { starred: newStarred };
            }),
        [update]
    );

    const starFlashcards = useCallback(
        async (flashcardIds: string[]) =>
            update(oldData => {
                const newStarred = [...new Set([...oldData.starred, ...flashcardIds])];
                return { starred: newStarred };
            }),
        [update]
    );

    const unStarFlashcards = useCallback(
        async flashcardIds =>
            update(oldData => {
                const idSet = new Set([...flashcardIds]);
                const newStarred = [...oldData.starred].filter(id => !idSet.has(id));
                return { starred: newStarred };
            }),
        [update]
    );

    const toggleFlashcardStarByIds = useCallback(
        (ids: string[], newState?: boolean) => {
            if (newState !== undefined) {
                if (newState) starFlashcards(ids);
                else unStarFlashcards(ids);
            } else {
                toggleStarFlashcards(ids);
            }
        },
        [toggleStarFlashcards, starFlashcards, unStarFlashcards]
    );

    const updateStudySessionViewer = useCallback(
        async ({
            isShuffled,
            position,
            order,
            flashcards,
        }: {
            isShuffled: boolean;
            position?: number;
            order?: string[];
            flashcards: (Flashcard | null | undefined)[];
        }) => {
            if (position !== undefined) {
                _setFlashcardIndex(position);
            }

            if (isShuffled) {
                await update(
                    deepScrapeEmptyFields({
                        // shuffed array of indexes
                        shuffled: true,
                        order:
                            order ?? isShuffled !== flashcardSetViewerData.shuffled
                                ? shuffle(flashcards?.map(flashcard => flashcard?.flashcardId))
                                : undefined,
                        position,
                    })
                );
            } else {
                await update(deepScrapeEmptyFields({ shuffled: false, order: null, position }, ["order"]));
            }
        },
        [flashcardSetViewerData?.shuffled, update]
    );

    const setFlashcardIndex = useCallback(
        async index => {
            _setFlashcardIndex(index);
            update({ position: index });
        },
        [_setFlashcardIndex, update]
    );

    const isStarred = useCallback(
        ({ flashcardId }: { flashcardId: string }) => flashcardSetViewerData?.starred?.includes?.(flashcardId),
        [flashcardSetViewerData?.starred]
    );

    const isStarredCardsExist = flashcardSetViewerData?.starred?.length > 0;

    return {
        isLoading,
        flashcardSetViewerData: flashcardSetViewerData ?? INITIAL_FLASHCARD_VIEWER_DATA,
        isStarred,
        updateFlashcardSetViewer: update,
        updateStudySessionViewer,
        flashcardIndex: flashcardIndex ?? 0,
        setFlashcardIndex,
        toggleFlashcardStarByIds,
        isStarredCardsExist,
        commitUpdates,
        isDirty,
    };
};
