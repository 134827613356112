const listeners = {};

export const addEmitEventListener = (eventName, callback) => {
    if (!listeners[eventName]) {
        listeners[eventName] = [];
    }
    listeners[eventName].push(callback);
};

export const removeEmitEventListener = (eventName, callback) => {
    if (listeners[eventName]) {
        listeners[eventName] = listeners[eventName].filter(listener => listener !== callback);
    }
};

export const emitEvent = (eventName, data = null) => {
    if (listeners[eventName]) {
        listeners[eventName].forEach(callback => {
            callback(data);
        });
    }
};
