import { tooltips } from "../../utils/constants/tooltips";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import MoreInfoTooltipButton from "@/components/CircularButton/styled/MoreInfoTooltipButton";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";

export const HeaderSection = ({
    children,
    style,
    ...divProps
}: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <FlexRowAlignCenter
            {...divProps}
            style={{
                justifyContent: "space-between",
                marginBottom: "3.9rem",
                ...style,
                color: themeColors.neutralBlack,
            }}>
            {children}
        </FlexRowAlignCenter>
    );
};

export const Section = ({
    children,
    style,
    ...divProps
}: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <FlexColumn
            as="section"
            {...divProps}
            style={{ gap: "2.1rem", fontSize: "1.6rem", color: themeColors.neutralBlack, ...style }}>
            {children}
        </FlexColumn>
    );
};

export const Label = ({
    children,
    tooltip,
    style,
    ...spanProps
}: { children: React.ReactNode; tooltip?: string } & React.HTMLAttributes<HTMLSpanElement>) => {
    return (
        <FlexRowAlignCenter
            style={{
                gap: spacing.XS,
            }}>
            <span
                {...spanProps}
                style={{ fontSize: "1.8rem", fontWeight: "600", color: themeColors.neutralBlack, ...style }}>
                {children}
            </span>
            {tooltip && <MoreInfoTooltipButton tooltip={tooltip} />}
        </FlexRowAlignCenter>
    );
};

export const OptionLabel = ({ label }: { label: string } & React.HTMLAttributes<HTMLSpanElement>) => {
    return (
        <FlexRowAlignCenter style={{ gap: spacing.XS }}>
            <span>{label}</span>
            {tooltips[label] && <MoreInfoTooltipButton tooltip={tooltips[label]} />}
        </FlexRowAlignCenter>
    );
};

export const Option = ({
    children,
    style,
    ...spanProps
}: { children: React.ReactNode } & React.HTMLAttributes<HTMLSpanElement>) => {
    return (
        <FlexRowAlignCenter
            as={"span"}
            {...spanProps}
            style={{
                fontSize: "1.6rem",
                color: themeColors.neutralBlack,
                justifyContent: "space-between",
                gap: "1rem",
                ...style,
            }}>
            {children}
        </FlexRowAlignCenter>
    );
};

export const Divider = () => {
    return (
        <FlexColumnAlignJustifyCenter style={{ padding: "2.6rem 0" }}>
            <div style={{ border: "1px solid #F6F5F5", width: "100%" }} />
        </FlexColumnAlignJustifyCenter>
    );
};
