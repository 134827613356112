import { Flashcard, FlashcardSide } from "@knowt/syncing/graphql/schema";
import { Pen, Star, Volume2 } from "lucide-react";
import { CSSProperties } from "react";
import { Flex } from "@/components/Flex";
import CircularOutlineIcon from "@/components/CircularButton/styled/CircularOutlineIcon";
import { useTextToSpeechContextSelector } from "@/contexts/TextToSpeechContext";
import { useCheckAuth } from "@/hooks/useCheckAuth";
import { iconSizes } from "@/utils/iconProps";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { FlashcardHintButton } from "@/features/StudyPages";
import { getOppositeFlashcardSide } from "@knowt/syncing/hooks/flashcards/utils";
import { AuthEvent } from "@/features/Auth";

type Props = {
    flashcard: Flashcard;
    isStarred: boolean;
    toggleStarFlashcard: () => void;
    showEditFlashcard: (flashcard: Flashcard) => void;
    audioSide: FlashcardSide;
    withHint?: boolean;
    style?: CSSProperties;
    readOnly?: boolean;
};

export const FlashcardControls = ({
    readOnly,
    flashcard,
    isStarred,
    toggleStarFlashcard,
    showEditFlashcard,
    audioSide,
    withHint = false,
    style,
}: Props) => {
    const { checkAuth } = useCheckAuth();

    const handlePlayAudio = useTextToSpeechContextSelector(state => state.handlePlayAudio);
    const loadingAudio = useTextToSpeechContextSelector(state => state.loadingAudio);
    const playingAudio = useTextToSpeechContextSelector(state => state.playingAudio);

    const { flashcardId } = flashcard;

    return (
        <Flex
            style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: spacing.XS,
                ...style,
            }}>
            {withHint && (
                <FlashcardHintButton
                    flashcard={flashcard}
                    question={{ answerSide: getOppositeFlashcardSide(audioSide) }}
                />
            )}
            <CircularOutlineIcon
                size={iconSizes.MD_S}
                Icon={Volume2}
                isActive={playingAudio.flashcardId === flashcardId}
                isLoading={loadingAudio.flashcardId === flashcardId}
                onClick={() =>
                    checkAuth(() => handlePlayAudio(audioSide, { flashcard }), {
                        event: AuthEvent.PLAY_AUDIO,
                    })
                }
                tooltip={"read this flashcard out loud"}
            />

            {!readOnly && (
                <CircularOutlineIcon
                    size={iconSizes.SM}
                    Icon={Pen}
                    onClick={() => showEditFlashcard(flashcard)}
                    tooltip={"edit this flashcard"}
                />
            )}

            <CircularOutlineIcon
                size={iconSizes.SM}
                Icon={Star}
                isActive={isStarred}
                fillWhenActive
                fill={themeColors.icon2}
                onClick={() =>
                    checkAuth(() => toggleStarFlashcard(), {
                        event: AuthEvent.STAR_FLASHCARD,
                    })
                }
                tooltip={isStarred ? "unstar this flashcard" : "star this flashcard"}
            />
        </Flex>
    );
};
