import { MatchStudySessionSetting, StudySessionType } from "@knowt/syncing/graphql/schema";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import CloseButton from "../../../../components/CircularButton/styled/CloseButton";
import { useStudySettingsContextSelector } from "../../StudySettings";
import StudySettingsNumberInput from "../StudySettingsNumberInput";
import { HeaderSection, Divider, Label, Option, OptionLabel, Section } from "../styled/SharedLayouts";
import StartButton from "../StartButton";
import ToggleSwitch from "@/components/ToggleSwitch";
import { useFlashcardSetViewer } from "@knowt/syncing/hooks/flashcardSetViewer/useFlashcardSetViewer";
import { addEmitEventListener, removeEmitEventListener } from "../../utils/eventEmitter";
import ClickableText from "@/components/styled/button/ClickableText";
import { themeColors } from "@/utils/themeColors";
import StudyDatePicker from "../styled/StudyDatePicker";
import useCombinedState from "@knowt/syncing/utils/hooks/useCombinedState";
import { MatchModeSettingsInput } from "../../types";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";

export const START_STUDY_EVENT = "startStudyEvent";

const SettingsMatchingMode = () => {
    const { smDown } = useBreakPoints();

    const itemId = useStudySettingsContextSelector(state => state.itemId);
    const startNewRound = useStudySettingsContextSelector(state => state.startNewRound);
    const studySession = useStudySettingsContextSelector(state => state.studySession);
    const flashcardSet = useStudySettingsContextSelector(state => state.flashcardSet);
    const onClose = useStudySettingsContextSelector(state => state.onClose);

    const matchModeSettings = studySession?.settings.MATCH;

    const { isStarredCardsExist } = useFlashcardSetViewer({ flashcardSetId: itemId });

    const settingsExists = matchModeSettings && matchModeSettings.grid !== undefined;

    const [settings, updateSettings] = useCombinedState<MatchModeSettingsInput>({
        examDate: studySession?.examDate ?? undefined,
        length: matchModeSettings?.length ?? Math.min(flashcardSet?.flashcards?.length ?? 0, 6),
        grid: matchModeSettings?.grid ?? true,
        starred: !!matchModeSettings?.starred,
    });

    const settingsAreChanged = (
        settings: MatchModeSettingsInput,
        matchModeSettings?: MatchStudySessionSetting | null
    ) => {
        if (!matchModeSettings) {
            return true;
        }

        return (
            settings.length !== matchModeSettings.length ||
            settings.grid !== matchModeSettings.grid ||
            settings.starred !== matchModeSettings.starred
        );
    };

    const startStudy = async () => {
        if (!settingsAreChanged(settings, matchModeSettings)) {
            return onClose();
        }

        if (settings.length < 4) {
            toast.error("Please enter a minimum of 4 cards to continue");
            return;
        }

        await startNewRound({
            flashcardSetId: itemId,
            examDate: settings.examDate,
            type: StudySessionType.MATCH,
            settings: {
                MATCH: {
                    grid: settings.grid,
                    length: settings.length,
                    starred: settings.starred,
                },
            },
        })
            .then(() => {
                onClose();
            })
            .catch(err => {
                toast.error(err.message);
            });
    };

    useEffect(() => {
        addEmitEventListener(START_STUDY_EVENT, startStudy);

        return () => {
            removeEmitEventListener(START_STUDY_EVENT, startStudy);
        };
    });

    return (
        <>
            <HeaderSection>
                <div className="bold" style={{ fontSize: "3rem" }}>
                    {smDown ? "" : "Matching Game "}Options
                </div>
                {!settingsExists && <StartButton label={"start"} onClick={startStudy} />}
                {settingsExists && <CloseButton onClick={startStudy} />}
            </HeaderSection>

            <React.Fragment>
                <Section>
                    <Label>Studying for an exam?</Label>
                    <Option style={{ flexWrap: "wrap" }}>
                        <OptionLabel label="When is your exam?" />
                        <StudyDatePicker
                            onChange={date => updateSettings({ examDate: date })}
                            date={settings.examDate}
                        />
                    </Option>
                </Section>
                <Divider />
            </React.Fragment>

            <Section>
                <Label>Learning Options</Label>
                <Option>
                    <OptionLabel label="How many cards do you want to match?" />
                    <StudySettingsNumberInput
                        value={settings.length ?? 4}
                        onChange={n => updateSettings({ length: n })}
                    />
                </Option>
                <Option>
                    <OptionLabel label="Grid Mode On" />
                    <ToggleSwitch checked={settings.grid} onChange={() => updateSettings({ grid: !settings.grid })} />
                </Option>
                <Option>
                    <OptionLabel label="Study starred terms only" />
                    <ToggleSwitch
                        checked={settings.starred}
                        onChange={() => {
                            if (!isStarredCardsExist) {
                                toast.error("You don't have any starred terms");
                            } else {
                                updateSettings({ starred: !settings.starred });
                            }
                        }}
                        disabled={!isStarredCardsExist}
                    />
                </Option>
            </Section>

            {settingsExists && <Divider />}
            {settingsExists && (
                <Section>
                    <ClickableText style={{ color: themeColors.primary }} onClick={startStudy}>
                        Save options
                    </ClickableText>
                    <ClickableText style={{ color: themeColors.icon3 }} onClick={startStudy}>
                        Restart
                    </ClickableText>
                </Section>
            )}
        </>
    );
};

export default SettingsMatchingMode;
