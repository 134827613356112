import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import {
    Flashcard,
    FlashcardSet,
    FlashcardSetStudySessionConnection,
    FlashcardSetStudySessionInput,
    StudySession,
    StudySessionType,
} from "@knowt/syncing/graphql/schema";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import GoBackButton from "../../components/CircularButton/styled/GoBackButton";
import SettingsLearnMode from "./components/modes/SettingsLearnMode";
import SettingsMatchingMode, { START_STUDY_EVENT } from "./components/modes/SettingsMatchingMode";
import SettingsPracticeMode from "./components/modes/SettingsPracticeMode";
import SettingsReviewMode from "./components/modes/SettingsReviewMode";
import SettingsSpacedMode from "./components/modes/SettingsSpacedMode";
import { themeColors } from "@/utils/themeColors";
import { spacing } from "@/utils/spacing";
import { borderRadius } from "@/utils/borderRadius";
import { FlexColumn } from "@/components/Flex";
import { OutstreamVideoAd } from "@/features/Ads";
import { emitEvent } from "./utils/eventEmitter";
import br from "@/styles/breakpoints.module.css";
import ResponsiveDialog from "@/features/ResponsiveDialog";

type StudySettingsContextValue = {
    onClose: () => void;
    studySessionType: StudySessionType;
    startNewRound: (settings: FlashcardSetStudySessionInput) => Promise<FlashcardSetStudySessionConnection | undefined>;
    updateStudySessionViewer?: (settings: {
        isShuffled: boolean;
        position?: number;
        order?: string[];
        flashcards: (Flashcard | null | undefined)[];
    }) => void;
    studySession: StudySession | null;
    flashcardSet: FlashcardSet;
    itemId: string;
    handleRetakeTest?: () => void;
    fromOverview?: boolean;
};

const StudySettingsContext = createContext<StudySettingsContextValue | null>(null);

const getSettingsBodyPerType = (studySessionType: StudySessionType) => {
    if (studySessionType === StudySessionType.LEARN) {
        return <SettingsLearnMode />;
    }

    if (studySessionType === StudySessionType.TEST) {
        return <SettingsPracticeMode />;
    }

    if (studySessionType === StudySessionType.SPACED) {
        return <SettingsSpacedMode />;
    }

    if (studySessionType === StudySessionType.MATCH) {
        return <SettingsMatchingMode />;
    }

    if (studySessionType === StudySessionType.REVIEW) {
        return <SettingsReviewMode />;
    }
};

export const StudySettingsScreen = ({
    isOpen,
    onClose,
    startNewRound,
    updateStudySessionViewer,
    studySessionType,
    itemId,
    flashcardSet,
}: {
    isOpen: boolean;
    onClose: () => void;
    startNewRound: (settings: FlashcardSetStudySessionInput) => Promise<FlashcardSetStudySessionConnection | undefined>;
    updateStudySessionViewer?: (settings: {
        isShuffled: boolean;
        position?: number;
        order?: string[];
        flashcards: (Flashcard | null | undefined)[];
    }) => void;
    studySessionType: StudySessionType;
    itemId: string;
    flashcardSet: FlashcardSet;
}) => {
    return (
        <StudySettingsContext.Provider
            value={{
                onClose: () => {
                    onClose();
                    // when pressing enter to flip the card, it used to open the settings popup again
                    document?.activeElement?.blur();
                },
                startNewRound,
                updateStudySessionViewer,
                studySessionType,
                itemId,
                studySession: null,
                flashcardSet,
            }}>
            <ResponsiveDialog
                isOpen={isOpen}
                onClose={onClose}
                style={{ position: "relative", flex: 1, flexDirection: "row", backgroundColor: themeColors.background }}
                DialogStyle={{
                    justifyContent: "space-between",
                    padding: "2.1rem 4.1rem 3.5rem 4.1rem",
                    width: "100vw",
                }}
                DialogProps={{ fullScreen: true }}
                SlideMenuStyle={{ padding: "2rem 2rem" }}>
                <FlexColumn style={{ flex: 1 }}>
                    <GoBackButton sx={{ marginBottom: spacing.MD }} fallbackHref={`/flashcards/${itemId}`} />
                    <FlexColumn
                        style={{
                            backgroundColor: themeColors.neutralWhite,
                            padding: "3.3rem clamp(2rem, 4vw, 5.4rem)",
                            borderRadius: borderRadius.card,
                            maxWidth: "87rem",
                            margin: "0 auto",
                            width: "100%",
                        }}>
                        {studySessionType && getSettingsBodyPerType(studySessionType)}
                    </FlexColumn>
                </FlexColumn>
                <StudySettingsAds />
            </ResponsiveDialog>
        </StudySettingsContext.Provider>
    );
};

export const StudySettingsPopup = ({
    isOpen,
    onClose,
    studySessionType,
    startNewRound,
    updateStudySessionViewer,
    studySession,
    flashcardSet,
    itemId,
    handleRetakeTest,
    fromOverview,
}: {
    isOpen: boolean;
    onClose: () => void;
    studySessionType: StudySessionType;
    startNewRound: (settings: FlashcardSetStudySessionInput) => Promise<FlashcardSetStudySessionConnection | undefined>;
    updateStudySessionViewer?: (settings: {
        isShuffled: boolean;
        position?: number;
        order?: string[];
        flashcards: (Flashcard | null | undefined)[];
    }) => void;
    studySession: StudySession;
    flashcardSet: FlashcardSet;
    itemId: string;
    handleRetakeTest?: () => void;
    fromOverview?: boolean;
}) => {
    return (
        <StudySettingsContext.Provider
            value={{
                onClose,
                studySessionType,
                itemId,
                startNewRound,
                updateStudySessionViewer,
                studySession,
                flashcardSet,
                handleRetakeTest: () => {
                    if (handleRetakeTest) {
                        handleRetakeTest();
                        onClose();
                    }
                },
                fromOverview,
            }}>
            <ResponsiveDialog
                isOpen={isOpen}
                onClose={() => emitEvent(START_STUDY_EVENT)}
                DialogStyle={{
                    width: "87.7rem",
                    maxWidth: "200rem",
                    borderRadius: "3rem",
                    padding: "3.5rem 4.1rem",
                }}
                SlideMenuStyle={{ padding: "2rem" }}>
                {studySessionType && getSettingsBodyPerType(studySessionType)}
            </ResponsiveDialog>
        </StudySettingsContext.Provider>
    );
};

const StudySettingsAds = () => {
    const { canShowAds } = useCurrentUser();

    if (!canShowAds) return null;

    return (
        <FlexColumn
            className={br.lgDownDisplayNone}
            id="booads"
            style={{
                flex: 1,
                minHeight: "50rem",
                padding: "0 2rem",
                alignSelf: "flex-start",
                position: "sticky",
                top: "50%",
                transform: `translateY(-50%)`,
                marginLeft: "3rem",
            }}>
            <OutstreamVideoAd />
        </FlexColumn>
    );
};

export const useStudySettingsContextSelector = <T,>(selector: (state: StudySettingsContextValue) => T) =>
    useContextSelector(StudySettingsContext, selector);
