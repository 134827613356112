import { useState } from "react";
import { Label, Option, OptionLabel, Section } from "./styled/SharedLayouts";
import ToggleSwitch from "@/components/ToggleSwitch";
import Select from "@/components/Select";
import { backgroundMusicList } from "../utils/constants/localStoragekeys";
import { getBackgroundMusic, changeAudioSettings, handleBackgroundMusicChange, getAudioSettings } from "../utils/utils";
import { themeColors } from "@/utils/themeColors";
import Mixpanel from "@/utils/analytics/Mixpanel";

const AudioSettings = () => {
    const isAudioOn = getAudioSettings();

    const [selectedBackgroundMusic, setSelectedBackgroundMusic] = useState(getBackgroundMusic());

    const handleChange = (value: string | null) => {
        setSelectedBackgroundMusic(value);
        Mixpanel.track("Background Music - Changed", { music: value });
        handleBackgroundMusicChange(value);
    };

    return (
        <Section>
            <Label>Audio</Label>
            <Option>
                <OptionLabel label="Sound effects" />
                <ToggleSwitch checked={isAudioOn} onChange={() => changeAudioSettings(!isAudioOn)} />
            </Option>
            <Option>
                <OptionLabel label="Background music" />
                <Select
                    options={backgroundMusicList}
                    onChange={({ value }) => handleChange(value)}
                    menuSx={{ width: "25rem", padding: "1rem" }}
                    btnSx={{
                        width: "fit-content",
                        gap: "1rem",
                        border: `1px solid ${themeColors.neutralBlack}`,
                        height: "4.6rem",
                        borderRadius: 999,
                    }}
                    selected={
                        backgroundMusicList.find(({ value }) => value === selectedBackgroundMusic) ||
                        backgroundMusicList[0]
                    }
                />
            </Option>
        </Section>
    );
};

export default AudioSettings;
