import { platform } from "@/platform";
import {
    Flashcard,
    FlashcardSet,
    FlashcardSetStudySessionConnection,
    FlashcardSetStudySessionInput,
    FlashcardSetStudySessionQuestion,
    FlashcardSide,
    GradeFlashcardSetStudySessionQuestionInput,
    QuestionType,
    ReviewStudySessionSetting,
    SpacedRepetitionButton,
    StudySession,
    StudySessionType,
    FlashcardStudyState,
} from "@knowt/syncing/graphql/schema";
import { fromEntries, wait } from "@/utils/genericUtils";
import { capitalize } from "@/utils/stringUtils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { KeyedMutator, mutate } from "swr";
import { useSWRImmutable } from "@knowt/syncing/hooks/swr";
import { useFlashcardSet } from "../flashcards/useFlashcards";
import { useCurrentUser } from "../user/useCurrentUser";
import {
    callGetStudySession,
    callGradeFlashcardSetStudySession,
    callListClassFlashcardStudyStates,
    callListClassStudySessions,
    callListFlashcardStudyStates,
    callListRecentStudySessions,
    callStartFlashcardSetStudySessionRound,
} from "./graphqlUtils";
import { gradeFlashcards } from "./utils";
import { getSessionCompletion } from "./statsUtils";
import { SHOW_EARLY_BY } from "./constants";
import { now } from "@/utils/SyncUtils";
import { useRunningLapTimer } from "./useRunningLapTimer";
import { populateCacheWithFallbackData } from "../swr";

export type UserAnswer = {
    // flashcardId of the answer that was submitted
    selectedFlashcardId?: string;
    // the answer that was submitted, text of the flashcard, based on the side
    answer?: string;
    button?: SpacedRepetitionButton;
    questionType: QuestionType;
    isCorrect?: boolean;
    // Used for true false only.
    tfIsCorrect?: boolean;
    side: FlashcardSide;
    mode: StudySessionType;
    timeTaken: number;
    timestamp: number;
    submitted?: boolean;
    repeated?: boolean;
};

export type UserAnswers = { [flashcardId: string]: UserAnswer };

const useSubmitGradingHelpers = ({
    flashcardSet,
    mutateStudySession,
    loading,
    error,
}: {
    flashcardSet?: FlashcardSet | null;
    mutateStudySession: KeyedMutator<StudySession>;
    loading: boolean;
    error: Error;
}) => {
    const flashcardSetId = flashcardSet?.flashcardSetId;
    const { userId } = useCurrentUser();

    const pendingUpdates = useRef<GradeFlashcardSetStudySessionQuestionInput[]>([]);
    const lastCommit = useRef<number>(null);

    const [lastSubmitted, setLastSubmitted] = useState<number>(null);
    const [isDirty, setIsDirty] = useState(false);

    const commitUpdates = useCallback(async () => {
        if (!userId) {
            setIsDirty(false);
            return;
        }

        if (loading || error) return;

        const commitStartTime = Date.now();
        lastCommit.current = commitStartTime;

        const pendingQuestions = pendingUpdates.current;
        pendingUpdates.current = [];

        let updatedStudySession: StudySession = undefined;

        if (pendingQuestions.length) {
            const { studySession, studyStates } = await callGradeFlashcardSetStudySession({
                flashcardSetId,
                questions: pendingQuestions,
            });

            // since dynamodb writes are eventually consistent, we need to wait for the write to be consistent
            // before updating the cache
            await wait(1000);

            updatedStudySession = studySession;

            const updatedStudyStatesMap = fromEntries(
                studyStates.map(studyState => [studyState.flashcardId, studyState])
            );

            await mutate(
                ["flashcard-study-states", userId, flashcardSetId],
                async (data: Record<string, FlashcardStudyState>) => ({
                    ...data,
                    ...updatedStudyStatesMap,
                }),
                { revalidate: false }
            );

            await mutateStudySession(updatedStudySession, false);
        }

        setLastSubmitted(Date.now());

        const isLastCommit = lastCommit.current === commitStartTime;
        const noPendingUpdates = !pendingUpdates.current.length;

        if (isLastCommit && noPendingUpdates) setIsDirty(false);

        return updatedStudySession;
    }, [error, flashcardSetId, mutateStudySession, loading, userId]);

    const enqueueQuestionSubmission = useCallback(
        (question: GradeFlashcardSetStudySessionQuestionInput & { submitted?: boolean }) => {
            // if the last question is the same as the current question, replace it
            const insertedIndex = pendingUpdates.current.findIndex(
                ({ flashcardId }) => flashcardId === question.flashcardId
            );

            if (insertedIndex !== -1) {
                // replace the time taken with added time
                // the time taken gets set when the answer is submitted, then added onto when they advance to the next question. There may be a small gap where pending updates gets committed before the time taken is updated, and we will lose the time taken for there, but it's not a big deal.
                pendingUpdates.current[insertedIndex] = {
                    ...pendingUpdates.current[insertedIndex],
                    timeTaken: pendingUpdates.current[insertedIndex].timeTaken + question.timeTaken,
                };
            } else if (!question.submitted) {
                // if it's already submitted, don't submit it again
                pendingUpdates.current.push(question);
            }

            setIsDirty(true);
        },
        []
    );

    return { lastSubmitted, isDirty, commitUpdates, enqueueQuestionSubmission };
};

export const useStudySession = ({
    flashcardSet: _flashcardSet,
    flashcardSetId,
    fallbackData,
    type,
    autoCommit = false,
}: {
    flashcardSet?: FlashcardSet | undefined;
    flashcardSetId: string;
    fallbackData?: StudySession | undefined;
    type: StudySessionType;
    autoCommit?: boolean;
}) => {
    const { userId } = useCurrentUser();

    const [userAnswers, setUserAnswers] = useState<UserAnswers>({});
    const [isDisplayingAnswer, setIsDisplayingAnswer] = useState(false);
    const [isStudySettingsOpen, setIsStudySettingsOpen] = useState(false);
    const [disableShortcuts, setDisableShortcuts] = useState(false);
    const [incorrectAnswers, setIncorrectAnswers] = useState(new Set<string>());

    const { flashcardSet } = useFlashcardSet({ flashcardSetId, fallbackData: _flashcardSet });

    const { start: startTimer, lap } = useRunningLapTimer();

    const { data: studySession, mutate: mutateStudySession } = useSWRImmutable(
        userId && flashcardSetId && ["study-session", userId, flashcardSetId],
        async ([_, _userId, flashcardSetId]) => callGetStudySession({ flashcardSetId }),
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    const { lastSubmitted, isDirty, commitUpdates, enqueueQuestionSubmission } = useSubmitGradingHelpers({
        flashcardSet,
        mutateStudySession,
        loading: !studySession,
        error: null,
    });

    useEffect(() => {
        if (studySession === undefined) return;

        if (!studySession?.settings?.[type] && type !== StudySessionType.REVIEW) {
            setIsStudySettingsOpen(true);
        }
    }, [studySession, type]);

    const totalFlashcards = useMemo(() => {
        if (!studySession?.progress) return undefined;
        return Object.values(studySession?.progress).reduce((acc, curr) => acc + curr, 0);
    }, [studySession?.progress]);

    const {
        data: roundData,
        error,
        mutate: mutateRoundData,
    } = useSWRImmutable(
        userId && flashcardSetId && ["round-data", userId, flashcardSetId, type],
        async () => null as FlashcardSetStudySessionConnection
    );

    const questions = useMemo(() => roundData?.questions, [roundData?.questions]);

    const flashcardData = useMemo(() => {
        if (!flashcardSet?.flashcards || !questions) return undefined;

        const usedIds = new Set(questions.flatMap(question => [question.flashcardId, ...question.distractors]));
        return fromEntries(
            flashcardSet?.flashcards
                ?.filter(flashcard => usedIds.has(flashcard.flashcardId))
                ?.map(flashcard => [flashcard.flashcardId, flashcard])
        );
    }, [flashcardSet?.flashcards, questions]);

    const isLoading = !questions || !flashcardData || studySession === undefined;

    const [currQuestions, setCurrQuestions] = useState(questions);

    useEffect(() => {
        setCurrQuestions(questions);
    }, [questions]);

    const currQuestion = useMemo(() => currQuestions?.[0], [currQuestions]);
    const currAnswer = currQuestion ? userAnswers[currQuestion.flashcardId] : undefined;

    useEffect(() => {
        // commit any pending updates when the round ends
        if (!currQuestions?.length && type !== StudySessionType.SPACED) {
            commitUpdates();
        }
    }, [type, currQuestions?.length, commitUpdates]);

    const startNewRound = useCallback(
        async ({ flashcardSetId, settings, type, examDate, studyFrom }: FlashcardSetStudySessionInput) => {
            // commit any pending updates
            await commitUpdates();

            const oldRoundData = roundData;
            setIsDisplayingAnswer(false);

            setUserAnswers({});
            setIncorrectAnswers(new Set());

            try {
                const roundData = await callStartFlashcardSetStudySessionRound({
                    flashcardSetId,
                    settings,
                    type,
                    examDate,
                    studyFrom,
                });

                const mixpanel = await platform.analytics.mixpanel();
                const session = roundData?.studySession;
                if (!oldRoundData) {
                    mixpanel.track(`Flashcard Set - ${capitalize(type)} Mode`, {
                        flashcardSetId,
                        noteId: flashcardSet?.noteId,
                        progress: session?.progress,
                        settings: session?.settings[type],
                        examDate: session?.examDate,
                        completion: getSessionCompletion(session?.progress),
                    });
                }

                await mutateStudySession(roundData.studySession, false);
                await mutateRoundData(roundData, false);
                startTimer();
                return roundData;
            } catch (err) {
                setIsStudySettingsOpen(true);
            }
        },
        [commitUpdates, flashcardSet?.noteId, mutateRoundData, mutateStudySession, roundData, startTimer]
    );

    useEffect(() => {
        if (roundData && type !== StudySessionType.REVIEW) {
            setIsDisplayingAnswer(false);
        }
    }, [type, roundData]);

    const submitGrading = useCallback(
        async (data: UserAnswers) => {
            const submittedUserAnswers = {} as UserAnswers;

            const questions = Object.keys(data)
                .map(flashcardId => {
                    const answer = data[flashcardId];

                    if (answer?.answer === undefined) return null;

                    setAnswer(flashcardId, { ...answer, submitted: true });
                    submittedUserAnswers[flashcardId] = { ...answer, submitted: true };
                    return {
                        flashcardId,
                        answer: answer.answer,
                        button: answer.button,
                        selectedFlashcardId: answer.selectedFlashcardId,
                        isCorrect: answer.isCorrect,
                        mode: answer.mode,
                        questionType: answer.questionType,
                        side: answer.side,
                        timeTaken: answer.timeTaken,
                        timestamp: answer.timestamp,
                        submitted: answer.submitted,
                    } as GradeFlashcardSetStudySessionQuestionInput & { submitted?: boolean };
                })
                .filter(Boolean);

            questions.forEach(enqueueQuestionSubmission);

            if (autoCommit) {
                await commitUpdates();
            }
            return submittedUserAnswers;
        },
        [autoCommit, commitUpdates, enqueueQuestionSubmission]
    );

    const setAnswer = useCallback((flashcardId: string, userAnswer: UserAnswer) => {
        setUserAnswers(prev => ({ ...prev, [flashcardId]: userAnswer }));
    }, []);

    const onSubmitQuestion = useCallback(
        async ({
            userAnswer,
            gradeImmediately = false,
            question = currQuestion,
            data = flashcardData,
        }: {
            userAnswer: UserAnswer;
            // TODO: rename this variable, it has to do with submitting the grading, not grading the answer
            gradeImmediately?: boolean;
            question?: Pick<FlashcardSetStudySessionQuestion, "flashcardId">;
            data?: Record<string, Flashcard>;
        }) => {
            if (!question) {
                return userAnswer;
            }

            const gradedUserAnswers = await gradeFlashcards({
                userAnswers: { [question.flashcardId]: { ...userAnswer, timeTaken: lap() } },
                flashcardData: data,
                settings: studySession?.settings,
                mode: userAnswer?.mode,
            });

            const gradedUserAnswer = gradedUserAnswers[question.flashcardId];

            if (!gradedUserAnswer?.isCorrect) {
                setIncorrectAnswers(prev => new Set([...prev, question.flashcardId]));
            }

            if (userAnswer.mode === StudySessionType.REVIEW) {
                mutateStudySession(
                    data => ({
                        ...data,
                        know: data.know + (gradedUserAnswer.isCorrect ? 1 : 0),
                        dKnow: data.dKnow + (!gradedUserAnswer.isCorrect ? 1 : 0),
                    }),
                    {
                        revalidate: false,
                    }
                );

                mutate(
                    ["flashcard-study-states", userId, flashcardSetId],
                    (data: Record<string, FlashcardStudyState>) => ({
                        ...data,
                        [question.flashcardId]: {
                            ...data[question.flashcardId],
                            sort: gradedUserAnswer.isCorrect,
                        },
                    }),
                    { revalidate: false }
                );
            }

            setAnswer(question.flashcardId, gradedUserAnswers[question.flashcardId]);
            setIsDisplayingAnswer(true);

            if (gradeImmediately) {
                await submitGrading(gradedUserAnswers);
            }

            return gradedUserAnswers?.[question.flashcardId];
        },
        [
            currQuestion,
            flashcardData,
            lap,
            setAnswer,
            studySession?.settings,
            submitGrading,
            flashcardSetId,
            mutateStudySession,
            userId,
        ]
    );

    const revertSorting = async (flashcardId: string) => {
        let lastSort;

        await mutate(
            ["flashcard-study-states", userId, flashcardSetId],
            (data: Record<string, FlashcardStudyState>) => {
                lastSort = data[flashcardId].sort;
                data[flashcardId].sort = undefined;
                return data;
            },
            { revalidate: false }
        );

        await mutateStudySession(
            data => ({
                ...data,
                know: data.know - (lastSort ? 1 : 0),
                dKnow: data.dKnow - (lastSort ? 0 : 1),
            }),
            { revalidate: false }
        );
    };

    const advanceStudySession = useCallback(
        async (_removeQuestion = false, overrideAnswer: Partial<UserAnswer> = {}) => {
            if (!currQuestions) {
                return;
            }

            const oldQuestion = currQuestions[0];
            const submittedAnswer = { ...userAnswers[oldQuestion.flashcardId], ...overrideAnswer, timeTaken: lap() };
            const updatedAnswer = await submitGrading({ [oldQuestion.flashcardId]: submittedAnswer });

            // If the user got the question correct, or if the user didn't answer the question, remove it from the list
            const removeQuestion = submittedAnswer?.isCorrect || _removeQuestion || submittedAnswer.answer === "";

            if (!removeQuestion) {
                setAnswer(oldQuestion.flashcardId, {
                    ...updatedAnswer[oldQuestion.flashcardId],
                    submitted: true,
                    isCorrect: undefined,
                    answer: undefined,
                    selectedFlashcardId: undefined,
                });
            }
            const addToEnd = removeQuestion ? [] : [oldQuestion];
            setCurrQuestions(prev => [...prev.slice(1), ...addToEnd]);
            setIsDisplayingAnswer(false);
        },
        [currQuestions, lap, setAnswer, submitGrading, userAnswers]
    );

    const updateSettings = useCallback(
        async (settings: Partial<ReviewStudySessionSetting>) => {
            const DEFAULT_REVIEW_SETTINGS: ReviewStudySessionSetting = {
                answerSide: FlashcardSide.DEFINITION,
                fuzzy: true,
                questionTypes: [],
                reType: false,
                shuffled: false,
                starred: false,
            };

            const newSettings = {
                ...studySession?.settings,
                [type]: {
                    ...DEFAULT_REVIEW_SETTINGS,
                    ...studySession?.settings?.[type],
                    ...settings,
                },
            };

            mutateStudySession(prev => ({ ...prev, settings: newSettings }), { revalidate: false });

            // Temporary way to do it, we should probably make an API
            await startNewRound({ flashcardSetId, settings: newSettings, type });
        },
        [flashcardSetId, mutateStudySession, startNewRound, studySession?.settings, type]
    );

    return {
        studySession,
        roundData,
        totalFlashcards,
        lastSubmitted,
        isDirty,
        commitUpdates,
        mutateStudySession,
        revertSorting,
        clearRoundData: useCallback(() => mutateRoundData(() => null, false), [mutateRoundData]),
        flashcardData,
        isLoading,
        questions,
        currQuestions,
        setCurrQuestions,
        currQuestion,
        currAnswer,
        currFlashcard: flashcardData ? flashcardData[currQuestion?.flashcardId] : null,
        currSettings: studySession?.settings?.[type],
        isStudySettingsOpen,
        setIsStudySettingsOpen,
        onSubmitQuestion,
        advanceStudySession,
        isDisplayingAnswer,
        setIsDisplayingAnswer,
        // only show under these very specific conditions
        isShowingSpacedBreakScreen:
            type === StudySessionType.SPACED &&
            currQuestions?.length === 0 &&
            studySession?.nextDue !== Number.MAX_SAFE_INTEGER.toString() &&
            Number(studySession?.nextDue ?? "0") > now() + SHOW_EARLY_BY &&
            studySession?.nextNewDue !== Number.MAX_SAFE_INTEGER.toString() &&
            (Number(studySession?.nextNewDue ?? "0") > now() + SHOW_EARLY_BY || studySession.progress.NEW === 0),
        userAnswers,
        setAnswer,
        setUserAnswers,
        submitGrading,
        startNewRound,
        updateSettings,
        error,
        disableShortcuts,
        setDisableShortcuts,
        termLanguage: flashcardSet?.termLanguage,
        definitionLanguage: flashcardSet?.definitionLanguage,
        incorrectAnswers,
    };
};

export const useRecentlyStudied = () => {
    const { userId } = useCurrentUser();

    const { data, error } = useSWRImmutable(
        userId && ["recently-studied", userId],
        async () => await callListRecentStudySessions()
    );

    return {
        recentlyStudied: data?.items,
        isLoading: !data && !error,
    };
};

export const useFlashcardStudyStates = ({ flashcardSetId }: { flashcardSetId: string | null | undefined }) => {
    const { userId } = useCurrentUser();

    const { data } = useSWRImmutable(
        userId && flashcardSetId && ["flashcard-study-states", userId, flashcardSetId],
        async ([_, _userId, flashcardSetId]) => {
            if (!userId) return null;
            return await callListFlashcardStudyStates({ flashcardSetId });
        }
    );

    return {
        flashcardStudyStates: data || {},
    };
};

export const useClassStudySessions = ({
    classId,
    itemId,
    isEnabled = true,
}: {
    classId: string | null | undefined;
    itemId: string | null | undefined;
    isEnabled?: boolean;
}): { studySessions: StudySession[] | null | undefined; isLoading: boolean } => {
    const { userId } = useCurrentUser();

    const { data: studySessions, isLoading } = useSWRImmutable(
        isEnabled && userId && classId && itemId && ["class-flashcard-study-sessions", userId, classId, itemId],
        ([, , classId]) => callListClassStudySessions({ classId, itemId })
    );

    return { studySessions, isLoading };
};

export const useClassFlashcardStudyStates = ({
    classId,
    flashcardSetId,
    isEnabled = true,
}: {
    classId: string | null | undefined;
    flashcardSetId: string | null | undefined;
    isEnabled?: boolean;
}) => {
    const { userId } = useCurrentUser();

    const { data: flashcardStudyStates, isLoading } = useSWRImmutable(
        isEnabled &&
            userId &&
            classId &&
            flashcardSetId && ["class-flashcard-study-states", userId, classId, flashcardSetId],
        ([, , classId, flashcardSetId]) => callListClassFlashcardStudyStates({ classId, flashcardSetId })
    );

    return { flashcardStudyStates, isLoading };
};
