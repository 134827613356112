// eslint-disable-next-line no-restricted-imports
import { Box, SxProps } from "@mui/material";
import { themeColors } from "@/utils/themeColors";
import React, { useState } from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { X } from "lucide-react";
import { borderRadius } from "@/utils/borderRadius";
import { FlexColumn } from "@/components/Flex";
import useHints from "../hooks/useHints";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        zindex: 1111,
        backgroundColor: themeColors.neutral1,
        color: "rgba(0, 0, 0, 0.87)",
        minWidth: 150,
        fontSize: "15px",
        padding: "3rem 2rem 2rem",
        borderRadius: borderRadius.card,
    },
}));

const ToolTipContent = ({ onClick, text }) => {
    return (
        <FlexColumn style={{ gap: "1rem", color: themeColors.neutralBlack }}>
            <X
                size={15}
                style={{ position: "absolute", right: "1rem", top: "1rem", cursor: "pointer" }}
                onClick={e => {
                    e.stopPropagation();
                    onClick(e);
                }}
            />
            <p className="knowtFontBold" style={{ fontFamily: "var(--knowt-font-name)" }}>
                Hint:
            </p>
            <span color="inherit">{text}</span>
        </FlexColumn>
    );
};

const AnswerHintBtn = ({
    question,
    answer,
    borderColor = themeColors.neutralBlack,
    onClick,
    text,
    sx,
    className,
}: {
    question: string;
    answer: string;
    borderColor?: string;
    text: React.ReactNode;
    onClick?: () => void;
    sx?: SxProps;
    className?: string;
}) => {
    const [open, setOpen] = useState(false);

    const { hint, generateHint } = useHints({ question, answer });

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        if (!open) generateHint();
        if (onClick) {
            return onClick();
        }
        setOpen(!open);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Grid item>
                <HtmlTooltip
                    PopperProps={{
                        placement: "top-end",
                        //disablePortal: true,
                    }}
                    componentsProps={{
                        arrow: {
                            sx: {
                                "&::before": {
                                    backgroundColor: themeColors.neutral1,
                                },
                            },
                        },
                    }}
                    onClose={e => {
                        e.stopPropagation();
                        handleTooltipClose();
                    }}
                    open={!!hint && open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={<ToolTipContent onClick={handleTooltipClose} text={hint} />}
                    arrow>
                    <Box
                        className={className}
                        onClick={e => {
                            e.stopPropagation();
                            handleClick();
                        }}
                        sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 999,
                            fontFamily: "var(--knowt-font-name)",
                            fontWeight: "600",
                            border: `1px solid ${borderColor}`,
                            color: open ? themeColors.neutralWhite : themeColors.neutralBlack,
                            backgroundColor: open ? themeColors.neutralBlack : "unset",
                            fontSize: "1.1rem",
                            width: "auto",
                            padding: "0 1.5rem",
                            height: "3.8rem",
                            "&:hover": {
                                background: themeColors.neutralBlack,
                                color: themeColors.neutralWhite,
                            },
                            "&:active": {
                                transform: "scale(0.97)",
                            },
                            transition: "all 0.2s ease",
                            ...sx,
                        }}>
                        {text}
                    </Box>
                </HtmlTooltip>
            </Grid>
        </ClickAwayListener>
    );
};

export default AnswerHintBtn;
