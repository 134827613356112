import { useDialogContextSelector } from "@/contexts/DialogContext";
import { objectWithout } from "@/utils/dataCleaning";

const useConfirmDialog = () => {
    const openConfirmDialog = useDialogContextSelector(state => state.openConfirmDialog);
    const closeConfirmDialog = useDialogContextSelector(state => state.closeConfirmDialog);
    const isOpen = useDialogContextSelector(state => state.confirmDialog.open);

    const dialogState = objectWithout(
        useDialogContextSelector(state => state.confirmDialog),
        "open"
    );

    return {
        isOpen,
        dialogState,
        openConfirmDialog,
        closeConfirmDialog,
    };
};

export default useConfirmDialog;
