"use client";
import { getPlainTextFromContent } from "@knowt/editor/helpers/getPlainTextFromContent";
import { callDetectLanguage, callTextToSpeech } from "@knowt/syncing/hooks/flashcards/graphqlUtils";
import { useFlashcardSet } from "@knowt/syncing/hooks/flashcards/useFlashcards";
import { Flashcard, FlashcardSide, Language } from "@knowt/syncing/graphql/schema";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";

type TextToSpeechContextValue = {
    loadingAudio: { flashcardId: string | null };
    playingAudio: { flashcardId: string | null };
    handlePlayAudio: (on: FlashcardSide, { flashcard }) => Promise<void>;
    handleStopAudio: () => void;
};

const TextToSpeechContext = createContext<TextToSpeechContextValue | null>(null);

type CommonTTSActionProps = {
    flashcard: Flashcard;
    flashcardSetId: string;
    flashcardId: string;
    text: string;
    side: FlashcardSide;
};

export const TextToSpeechContextProvider = ({ children, flashcardSetId }) => {
    const { flashcardSet, updateFlashcard } = useFlashcardSet({
        flashcardSetId,
        // we don't want to commit the updates, the backend already handles that case
        autoCommit: false,
    });

    const audioRef = useRef<HTMLAudioElement>();
    useEffect(() => {
        audioRef.current = new Audio();
    }, []);

    const [playingAudio, setPlayingAudio] = useState<{ flashcardId: string | null; on: FlashcardSide | null }>({
        flashcardId: null,
        on: null,
    });

    const [loadingAudio, setLoadingAudio] = useState<{ flashcardId: string | null }>({
        flashcardId: null,
    });

    const getDetectLanguage = useCallback(
        async ({
            text,
            flashcardId,
            side,
        }: Pick<CommonTTSActionProps, "text" | "flashcardId" | "side">): Promise<Language> => {
            const detectedLanguage = await callDetectLanguage({
                flashcardSetId: flashcardSetId,
                flashcardId: flashcardId,
                text: text,
                side,
            });

            return detectedLanguage.language;
        },
        [flashcardSetId]
    );

    const getLanguage = useCallback(
        async ({
            side,
            text,
            flashcardId,
        }: Pick<CommonTTSActionProps, "side" | "text" | "flashcardId">): Promise<Language> => {
            const language = flashcardSet?.[side.toLowerCase() + "Language"];

            if (!language) {
                return await getDetectLanguage({ text, side, flashcardId });
            }

            return language;
        },
        [flashcardSet, getDetectLanguage]
    );

    const generateAudio = useCallback(
        async ({
            flashcardId,
            text,
            side,
        }: Pick<CommonTTSActionProps, "flashcardId" | "text" | "side">): Promise<string> => {
            const language = await getLanguage({ side, text, flashcardId });

            const response = await callTextToSpeech({
                text: text,
                flashcardSetId: flashcardSetId,
                flashcardId: flashcardId,
                side,
                voice: { language: language },
            });

            return response.url;
        },
        [flashcardSetId, getLanguage]
    );

    const getAudio = useCallback(
        async ({ flashcard, side }: Pick<CommonTTSActionProps, "flashcard" | "side">): Promise<string> => {
            const { flashcardId } = flashcard;
            const audio = side === FlashcardSide.TERM ? flashcard.termAudio : flashcard.definitionAudio;
            const textSide = side === FlashcardSide.TERM ? "term" : "definition";

            if (!audio) {
                // TODO: we don't have parser "Md to Plane Text" anymore
                // const text = flashcardMdToText({ markdown: flashcard[textSide] });
                const text = getPlainTextFromContent({ content: flashcard[textSide] || "", type: "flashcard" });

                const newCreatedAudio = await generateAudio({ flashcardId, text, side });
                await updateFlashcard(flashcardId, { [side.toLowerCase() + "Audio"]: newCreatedAudio });
                return newCreatedAudio;
            }

            return audio;
        },
        [generateAudio, updateFlashcard]
    );

    const playAudioLoud = useCallback(
        async (
            audios: string[],
            {
                flashcardId,
                on,
            }: {
                flashcardId: string;
                on: FlashcardSide;
            }
        ) => {
            if (!audioRef.current) throw new Error("audioRef is null");

            for (let i = 0; i < audios.length; i++) {
                audioRef.current.src = audios[i];

                audioRef.current
                    .play()
                    .then(() => {
                        setPlayingAudio({ flashcardId, on });
                    })
                    .catch(() => {
                        // ignore "media resource was aborted" error
                    });

                await new Promise(resolve => (audioRef.current.onended = resolve));
            }
            setPlayingAudio({ flashcardId: null, on: null });
        },
        []
    );

    const shouldStopAudioPlayingInstead = useCallback(
        (on: FlashcardSide, { flashcardId }) => {
            return playingAudio.flashcardId === flashcardId && playingAudio.on === on;
        },
        [playingAudio.flashcardId, playingAudio.on]
    );

    const handleStopAudio = useCallback(() => {
        if (!audioRef.current) throw new Error("audioRef is null");

        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setPlayingAudio({ flashcardId: null, on: null });
    }, []);

    const handlePlayAudio = useCallback(
        async (on: FlashcardSide, { flashcard }) => {
            if (!audioRef.current) throw new Error("audioRef is null");

            audioRef.current.pause();
            const audios: string[] = [];

            const { flashcardId } = flashcard;
            if (shouldStopAudioPlayingInstead(on, { flashcardId })) {
                handleStopAudio();
                return;
            }

            setLoadingAudio({ flashcardId: flashcard.flashcardId });
            if (on === FlashcardSide.TERM) {
                const audio = await getAudio({ flashcard, side: FlashcardSide.TERM });
                audios.push(audio);
            } else if (on === FlashcardSide.DEFINITION) {
                const audio = await getAudio({ flashcard, side: FlashcardSide.DEFINITION });
                audios.push(audio);
            } else if (on === FlashcardSide.BOTH) {
                const termAudio = await getAudio({ flashcard, side: FlashcardSide.TERM });
                const definitionAudio = await getAudio({ flashcard, side: FlashcardSide.DEFINITION });
                audios.push(termAudio, definitionAudio);
            }
            setLoadingAudio({ flashcardId: null });

            await playAudioLoud(audios, { flashcardId: flashcard.flashcardId, on });
        },
        [getAudio, handleStopAudio, playAudioLoud, shouldStopAudioPlayingInstead]
    );

    return (
        <TextToSpeechContext.Provider
            value={useMemo(
                () => ({ loadingAudio, playingAudio, handlePlayAudio, handleStopAudio }),
                [handlePlayAudio, handleStopAudio, loadingAudio, playingAudio]
            )}>
            {children}
        </TextToSpeechContext.Provider>
    );
};

export const useTextToSpeechContextSelector = <T,>(selector: (value: TextToSpeechContextValue) => T) =>
    useContextSelector(TextToSpeechContext, selector);
