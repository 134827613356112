import {
    gradeFlashcardSetStudySession,
    startFlashcardSetStudySessionRound,
    clearFlashcardSetStudySession,
} from "@/graphql/mutations";
import {
    getStudySession,
    listClassFlashcardStudyStates,
    listClassStudySessions,
    listFlashcardStudyStates,
    listRecentStudySessions,
} from "@/graphql/queries";
import {
    FlashcardSetStudySessionConnection,
    FlashcardSetStudySessionInput,
    FlashcardSetStudySessionRound,
    FlashcardStudyState,
    GradeFlashcardSetStudySessionQuestionInput,
    StudySession,
    StudySessionConnection,
} from "@knowt/syncing/graphql/schema";
import { ServerClientWithCookies, client, listData, listGroupedData } from "@/utils/SyncUtils";
import { deepScrapeEmptyFields } from "@/utils/dataCleaning";
import { retry } from "@/utils/genericUtils";

export const callGetStudySession = async ({
    flashcardSetId,
    serverClient,
}: {
    flashcardSetId: string;
    serverClient?: ServerClientWithCookies;
}): Promise<StudySession | null> => {
    const input = {
        itemId: flashcardSetId,
    };

    return await client
        .query({
            query: getStudySession,
            variables: { input },
            serverClient,
        })
        .then(({ data }) => data.getStudySession)
        .catch(() => null);
};

export const callStartFlashcardSetStudySessionRound = async ({
    flashcardSetId,
    settings,
    type,
    examDate,
    studyFrom,
}: FlashcardSetStudySessionInput): Promise<FlashcardSetStudySessionConnection> => {
    const input = deepScrapeEmptyFields({
        flashcardSetId,
        settings,
        type,
        examDate,
        studyFrom,
    });

    return await client
        .mutate({
            mutation: startFlashcardSetStudySessionRound,
            variables: { input },
        })
        .then(({ data }) => data.startFlashcardSetStudySessionRound);
};

export const callListFlashcardStudyStates = async ({ flashcardSetId }) => {
    const input = {
        flashcardSetId,
    };

    return (await listGroupedData({
        listQuery: listFlashcardStudyStates,
        input,
        groupingKey: "flashcardId",
        queryName: "listFlashcardStudyStates",
        ignoreTrashed: false,
    })) as Record<string, FlashcardStudyState>;
};

export const callListRecentStudySessions = async ({
    serverClient,
}: {
    serverClient?: ServerClientWithCookies;
} = {}): Promise<StudySessionConnection> => {
    return await client
        .query({
            query: listRecentStudySessions,
            variables: { input: {} },
            serverClient,
        })
        .then(({ data }) => data.listRecentStudySessions)
        .catch(() => null);
};

export const callListClassStudySessions = async ({
    classId,
    itemId,
    serverClient,
}: {
    classId: string;
    itemId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listData({
        listQuery: listClassStudySessions,
        input: { classId, itemId },
        queryName: "listClassStudySessions",
        ignoreTrashed: false,
        serverClient,
    })) as StudySession[];
};

export const callListClassFlashcardStudyStates = async ({
    classId,
    flashcardSetId,
    serverClient,
}: {
    classId: string;
    flashcardSetId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (await listData({
        listQuery: listClassFlashcardStudyStates,
        input: { classId, flashcardSetId },
        queryName: "listClassFlashcardStudyStates",
        ignoreTrashed: false,
        serverClient,
    })) as FlashcardStudyState[];
};

export const callGradeFlashcardSetStudySession = async ({
    flashcardSetId,
    questions,
    serverClient,
}: {
    flashcardSetId: string;
    questions: GradeFlashcardSetStudySessionQuestionInput[];
    serverClient?: ServerClientWithCookies;
}): Promise<FlashcardSetStudySessionRound> => {
    const input = {
        flashcardSetId,
        questions,
    };

    return await retry(
        async () =>
            await client
                .mutate({
                    mutation: gradeFlashcardSetStudySession,
                    variables: { input },
                    serverClient,
                })
                .then(({ data }) => data.gradeFlashcardSetStudySession)
    );
};

export const callClearFlashcardSetStudySession = async ({
    flashcardSetId,
}: {
    flashcardSetId: string;
}): Promise<StudySession> => {
    const input = {
        itemId: flashcardSetId,
    };

    return await client
        .mutate({
            mutation: clearFlashcardSetStudySession,
            variables: { input },
        })
        .then(({ data }) => data.clearFlashcardSetStudySession)
        .catch(() => null);
};
