import { themeColors } from "@/utils/themeColors";

const StudySettingsNumberInput = ({
    onChange,
    disabled,
    value,
}: {
    value?: number;
    disabled?: boolean;
    onChange: (n: number) => void;
}) => {
    return (
        <input
            value={value}
            disabled={disabled}
            onChange={e => {
                const newN = Number(e.target.value);

                if (newN && newN > 0) {
                    onChange(newN);
                } else {
                    onChange(0);
                }
            }}
            onKeyDown={e => {
                // allow backspace
                if (e.key === "Backspace") {
                    return;
                }
                // only allow numbers and arrow keys
                if (!/[0-9]/i.test(e.key) && !/Arrow/i.test(e.key)) {
                    e.preventDefault();
                }
            }}
            style={{
                outline: "none",
                color: themeColors.neutralBlack,
                backgroundColor: "transparent",
                border: `1px solid ${themeColors.neutralBlack}`,
                fontSize: "1.6rem",
                height: "4.6rem",
                width: "7rem",
                padding: "0 1rem",
                textAlign: "center",
                fontWeight: "600",
                borderRadius: "5rem",
            }}
        />
    );
};

export default StudySettingsNumberInput;
