import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import useAdvanceTimer from "@knowt/syncing/hooks/study/useAdvanceTimer";
import { themeColors } from "@/utils/themeColors";
import { borderRadius } from "@/utils/borderRadius";
import { VIDEO_WAITING_TIME } from "@/features/Ads";
import { Check } from "lucide-react";

const StartButton = ({
    onClick: _onClick,
    label,
}: {
    onClick: (e?: React.MouseEvent) => Promise<void>;
    label: string;
}) => {
    const { subscriptionType } = useCurrentUser();

    const { timer, isTimerActive, isLoading, onClick } = useAdvanceTimer({
        length: VIDEO_WAITING_TIME,
        subscriptionType,
        advanceFunc: async () => await _onClick(),
    });

    const isEndOfTimer = timer < VIDEO_WAITING_TIME / 30;

    return (
        <CircularRectTextButton
            id="start-button"
            onClick={onClick}
            sx={{
                width: "15rem",
                height: "5rem",
                borderRadius: borderRadius.longInput,
                background: themeColors.primary,
                fontSize: "1.8rem",
                color: themeColors.pureWhite,
                transition: "all 0.2s ease-in-out",
            }}>
            <span
                style={{
                    position: "absolute",
                    width: "15rem",
                    height: "5rem",
                    zIndex: 9999,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                {isLoading && !isTimerActive ? <Check size={25} color={themeColors.pureWhite} /> : label}
            </span>
            {/* fill background based on timer */}
            <span
                style={{
                    position: "absolute",
                    width: "15rem",
                    height: "5rem",
                    zIndex: 999,
                }}>
                <span
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: `${
                            100 - Math.min(isTimerActive ? 0.9 : 1, Math.max(0, timer / VIDEO_WAITING_TIME)) * 100
                        }%`,
                        height: "100%",
                        background: themeColors.primaryDark,
                        borderRadius: isEndOfTimer
                            ? borderRadius.longInput
                            : `${borderRadius.longInput} 0 0 ${borderRadius.longInput}`,
                        transition: "all 0.2s ease-in-out",
                    }}
                />
            </span>
        </CircularRectTextButton>
    );
};

export default StartButton;
