import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { Flashcard, FlashcardSide, QuestionType, StudySession, StudySessionType } from "@knowt/syncing/graphql/schema";
import noop from "@knowt/syncing/utils/noop";
import clsx from "clsx";
import { ArrowLeft, ArrowRight, Check, Copy, Maximize, Pen, Settings, Shuffle, Star, Undo2, X } from "lucide-react";
import { useRouter } from "next13-progressbar";
import dynamic from "next/dynamic";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import type { SwiperClass } from "swiper/react";
import { FlashcardCarouselCard } from "./Card";
import styles from "./flashcardsCarousel.module.css";
import { Flex, FlexRowAlignCenter } from "@/components/Flex";
import { ClassSharingPillWithHandler, SharingPillWithHandler } from "@/features/ShareSettings";
import { useReviewModeContextSelector } from "@knowt/syncing/context/FlashcardReviewModeContext";
import CircularOutlineIcon from "@/components/CircularButton/styled/CircularOutlineIcon";
import { useCheckAuth } from "@/hooks/useCheckAuth";
import { useMounted } from "@/hooks/useMounted";
import { borderRadius } from "@/utils/borderRadius";
import { iconSizes } from "@/utils/iconProps";
import { duplicateFlashcardSet, getOppositeFlashcardSide } from "@knowt/syncing/hooks/flashcards/utils";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import CircularFilledIcon from "@/components/CircularButton/styled/CircularFilledIcon";
import FlashcardSetOptionsMenu from "@/components/cards/UserContentCard/components/FlashcardSetOptionsMenu";
import br from "@/styles/breakpoints.module.css";
import LazyLoaded from "@/components/LazyLoaded";
import { StudySettingsPopup } from "@/features/StudySettings";
import { usePathname } from "next/navigation";
import { useTextToSpeechContextSelector } from "@/contexts/TextToSpeechContext";
import { AuthEvent } from "@/features/Auth";
import { now } from "@knowt/syncing/utils/SyncUtils";
import { FlashcardWithSide } from "@knowt/syncing/graphql/customSchema";
import useDOMEventListener from "@/hooks/useDOMEventListener";
import { useAutoSave } from "@/hooks/useAutoSave";

const FlashcardsCarouselList = dynamic(() => import("./List"));

type FlashcardsCarouselProps = {
    showEditFlashcard: (flashcard: Flashcard) => void;
    showControls?: boolean;
};

const FlashcardsCarousel = ({ showEditFlashcard, showControls = true }: FlashcardsCarouselProps) => {
    const flashcardSet = useReviewModeContextSelector(context => context.flashcardSet);
    const flashcards = useReviewModeContextSelector(context => context.flashcards);
    const numOfCardsInCarousel = useReviewModeContextSelector(context => context.numOfCardsInCarousel);
    const flashcardIndex = useReviewModeContextSelector(context => context.flashcardIndex);
    const setFlashcardIndex = useReviewModeContextSelector(context => context.setFlashcardIndex);
    const studySession = useReviewModeContextSelector(context => context.studySession);
    const starredFlashcardIds = useReviewModeContextSelector(context => context.starProps.starredFlashcardIds);
    const startNewRound = useReviewModeContextSelector(context => context.startNewRound);
    const updateStudySessionViewer = useReviewModeContextSelector(context => context.updateStudySessionViewer);
    const updateSettings = useReviewModeContextSelector(context => context.updateSettings);
    const isStudySettingsOpen = useReviewModeContextSelector(context => context.isStudySettingsOpen);
    const setIsStudySettingsOpen = useReviewModeContextSelector(context => context.setIsStudySettingsOpen);
    const readOnly = useReviewModeContextSelector(context => context.readOnly);
    const sortMode = useReviewModeContextSelector(context => context.sortMode);
    const onSubmitQuestion = useReviewModeContextSelector(context => context.onSubmitQuestion);
    const commitUpdates = useReviewModeContextSelector(context => context.commitUpdates);
    const isDirty = useReviewModeContextSelector(context => context.isDirty);
    const commitViewerUpdates = useReviewModeContextSelector(context => context.commitViewerUpdates);
    const isViewerDirty = useReviewModeContextSelector(context => context.isViewerDirty);
    const onRevertSort = useReviewModeContextSelector(context => context.onRevertSort);

    // Autosave if we are using sorting. isDirty will be false otherwise
    useAutoSave({
        isDirty: () => isDirty || isViewerDirty,
        save: async () => {
            commitUpdates();
            commitViewerUpdates();
        },
        interval: 15_000,
    });

    const handleStopAudio = useTextToSpeechContextSelector(state => state.handleStopAudio);

    const settings = studySession?.settings.REVIEW;

    const flashcardSetId = flashcardSet?.flashcardSetId;
    const swiperRef = useRef(null);
    const mounted = useMounted();

    const { user } = useCurrentUser();
    const { checkAuth } = useCheckAuth();
    const router = useRouter();
    const pathname = usePathname();
    const onOverviewPage = useMemo(() => !pathname.includes("study/"), [pathname]);

    const [showDummyCard, setShowDummyCard] = useState(true);

    useEffect(() => {
        if (!showDummyCard) return;
        if (mounted) {
            setTimeout(() => {
                setShowDummyCard(false);
            }, 500);
        }
    }, [mounted, showDummyCard]);

    const onSortKnow = async () => {
        handleStopAudio();
        if (!swiperRef.current) return;
        (swiperRef.current as { swiper: SwiperClass }).swiper.slideNext(200, true);

        await onSubmitQuestion({
            userAnswer: {
                answer: "",
                isCorrect: true,
                side: flashcards[flashcardIndex].side,
                mode: StudySessionType.REVIEW,
                questionType: QuestionType.NONE,
                timeTaken: 0,
                timestamp: now(),
            },
            question: {
                flashcardId: flashcards[flashcardIndex].flashcardId,
            },
            gradeImmediately: true,
        });

        if (flashcardIndex === numOfCardsInCarousel - 1) {
            commitUpdates();
            commitViewerUpdates();
        }
    };

    const onSortDontKnow = async () => {
        handleStopAudio();

        if (!swiperRef.current) return;
        (swiperRef.current as { swiper: SwiperClass }).swiper.slideNext(200, true);

        await onSubmitQuestion({
            userAnswer: {
                answer: "",
                isCorrect: false,
                side: flashcards[flashcardIndex].side,
                mode: StudySessionType.REVIEW,
                questionType: QuestionType.NONE,
                timeTaken: 0,
                timestamp: now(),
            },
            question: {
                flashcardId: flashcards[flashcardIndex].flashcardId,
            },
            gradeImmediately: true,
        });
    };

    useDOMEventListener("keydown", (event: KeyboardEvent) => {
        if (!sortMode) return;

        // if 1, yes
        if (event.key === "1") {
            event.preventDefault();
            onSortDontKnow();
        }

        if (event.key === "2") {
            onSortKnow();
        }
    });

    const dummyCard = useMemo(() => {
        const index = Math.min(flashcardIndex ?? 0, flashcards?.length - 1);
        // TODO: dummy is needed in case there are no flashcards. for some reason we need some dummy card rendered, maybe bc of the relative/absolute positioning below.
        return (
            flashcards?.[index] ||
            ({
                __typename: "Flashcard",
                flashcardId: "dummy",
                term: "dummy",
                definition: "dummy",
                side: FlashcardSide.TERM,
            } as FlashcardWithSide)
        );
    }, [flashcardIndex, flashcards]);

    const renderSettingsButtons = () => {
        return (
            <FlexRowAlignCenter style={{ gap: "inherit" }}>
                {showControls && (
                    <div className={br.smDownDisplayNone}>
                        <CircularFilledIcon
                            Icon={Maximize}
                            size={iconSizes.SM}
                            onClick={() => {
                                checkAuth(() => router.push(`/study/flashcards/${flashcardSetId}/review`), {
                                    event: AuthEvent.STUDY_FLASHCARDS_REVIEW_MODE,
                                });
                            }}
                            tooltip={"make this bigger"}
                        />
                    </div>
                )}
                <CircularFilledIcon
                    Icon={Shuffle}
                    size={iconSizes.SM}
                    onClick={() =>
                        checkAuth(
                            () => {
                                updateSettings({ shuffled: !settings?.shuffled });
                                updateStudySessionViewer({
                                    isShuffled: !settings?.shuffled,
                                });
                            },
                            {
                                event: AuthEvent.SHUFFLE_FLASHCARDS,
                            }
                        )
                    }
                    isActive={!!settings?.shuffled}
                    tooltip={settings?.shuffled ? "unshuffle this set" : "shuffle this set"}
                />
                <CircularFilledIcon
                    Icon={Star}
                    size={iconSizes.SM}
                    onClick={() =>
                        checkAuth(
                            () => {
                                if (!starredFlashcardIds?.length && !settings?.starred) {
                                    return toast.success("You haven't starred any flashcards yet!");
                                }
                                updateSettings({
                                    starred: !settings?.starred,
                                });
                                // reset the order in case it was set from legacy code
                                updateStudySessionViewer({
                                    isShuffled: !!settings?.shuffled,
                                });
                            },
                            {
                                event: AuthEvent.STAR_FLASHCARD,
                            }
                        )
                    }
                    fill={themeColors.icon2}
                    fillWhenActive
                    isActive={settings?.starred}
                    tooltip={settings?.starred ? "study from all flashcards" : "study from starred flashcards only"}
                />
                <CircularFilledIcon
                    Icon={Settings}
                    size={iconSizes.SM}
                    onClick={() =>
                        checkAuth(() => setIsStudySettingsOpen(true), {
                            event: AuthEvent.OPEN_STUDY_SETTINGS,
                        })
                    }
                    tooltip={"your study settings"}
                />
                {settings?.sort && (
                    <CircularFilledIcon
                        Icon={Undo2}
                        size={iconSizes.SM}
                        isDisabled={!flashcardIndex}
                        onClick={() =>
                            checkAuth(() => onRevertSort(), {
                                event: AuthEvent.OPEN_STUDY_SETTINGS,
                            })
                        }
                        tooltip={"undo the last sort action"}
                    />
                )}
            </FlexRowAlignCenter>
        );
    };

    const renderSortNavigation = () => (
        <FlexRowAlignCenter
            className={styles.prevNextButtons}
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: themeColors.neutralWhite,
                borderRadius: borderRadius.shortInput,
                gap: spacing.LG_S,
                padding: `${spacing.XS} ${spacing.XS}`,
            }}>
            <CircularOutlineIcon
                // radius="4.5rem"
                onClick={onSortDontKnow}
                Icon={X}
                buttonColor={themeColors.errorSecondary}
                color={themeColors.errorPrimary}
                borderColor={themeColors.errorPrimary}
                size={iconSizes.MD}
            />
            {flashcardIndex + 1 >= numOfCardsInCarousel ? (
                <Check color={themeColors.primaryDark} />
            ) : (
                <p className="bodyBold2">{`${Math.min(flashcardIndex + 1, numOfCardsInCarousel - 1)} / ${
                    numOfCardsInCarousel - 1
                }`}</p>
            )}
            <CircularOutlineIcon
                // radius="4.5rem"
                onClick={onSortKnow}
                Icon={Check}
                buttonColor={themeColors.successSecondary}
                color={themeColors.successPrimary}
                borderColor={themeColors.successPrimary}
                size={iconSizes.MD}
            />
        </FlexRowAlignCenter>
    );

    const renderNextButton = () => (
        <CircularOutlineIcon
            // radius="4.5rem"
            onClick={() => {
                handleStopAudio();
                if (!swiperRef.current) return;
                setFlashcardIndex(flashcardIndex + 1);
                (swiperRef.current as { swiper: SwiperClass }).swiper.slideNext(200, true);
            }}
            isDisabled={flashcardIndex + 1 >= numOfCardsInCarousel}
            Icon={ArrowRight}
            buttonColor={themeColors.successSecondary}
            hoverColor={themeColors.neutralWhite}
            color={themeColors.successPrimary}
            borderColor={themeColors.successPrimary}
            size={iconSizes.MD}
        />
    );

    const renderPrevButton = () => (
        <CircularOutlineIcon
            // radius="4.5rem"
            isDisabled={flashcardIndex === 0}
            onClick={() => {
                handleStopAudio();
                if (!swiperRef.current) return;
                setFlashcardIndex(flashcardIndex - 1);
                (swiperRef.current as { swiper: SwiperClass }).swiper.slidePrev(200, true);
            }}
            Icon={ArrowLeft}
            buttonColor={themeColors.successSecondary}
            hoverColor={themeColors.neutralWhite}
            color={themeColors.successPrimary}
            borderColor={themeColors.successPrimary}
            size={iconSizes.MD}
        />
    );

    const renderFlashcardNavigation = () => (
        <FlexRowAlignCenter
            className={styles.prevNextButtons}
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: themeColors.neutralWhite,
                borderRadius: borderRadius.shortInput,
                gap: spacing.LG_S,
                padding: `${spacing.XS} ${spacing.XS}`,
            }}>
            {renderPrevButton()}
            {flashcardIndex + 1 >= numOfCardsInCarousel ? (
                <Check color={themeColors.primaryDark} />
            ) : (
                <p className="bodyBold2">{`${Math.min(flashcardIndex + 1, numOfCardsInCarousel - 1)} / ${
                    numOfCardsInCarousel - 1
                }`}</p>
            )}
            {renderNextButton()}
        </FlexRowAlignCenter>
    );

    const renderSortStats = () => {
        const know = studySession?.know ?? 0;

        const dontKnow = studySession?.dKnow ?? 0;

        return (
            <FlexRowAlignCenter
                style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: spacing.SM,
                }}>
                <p className="secondaryText1">{`Know: ${know}`}</p>
                <p className="secondaryText1">{`Don't know: ${dontKnow}`}</p>
            </FlexRowAlignCenter>
        );
    };

    return (
        // parent of this component must specify the number of it's width
        <div style={{ width: "100%" }}>
            {sortMode && renderSortStats()}
            <div style={{ position: "relative" }}>
                {mounted && (
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                        }}>
                        <FlashcardsCarouselList
                            swiperRef={swiperRef}
                            sortMode={sortMode}
                            showEditFlashcard={showEditFlashcard}
                            renderPrevButton={renderPrevButton}
                            renderNextButton={renderNextButton}
                        />
                    </div>
                )}
                <div style={{ opacity: showDummyCard ? 1 : 0 }}>
                    <FlashcardCarouselCard
                        flashcard={dummyCard}
                        onClick={noop}
                        side={getOppositeFlashcardSide(dummyCard?.side)}
                        starProps={{
                            isStarred: starredFlashcardIds?.includes(dummyCard?.flashcardId) ?? false,
                            toggleFlashcardStarById: noop,
                        }}
                        showEditFlashcard={noop}
                        readOnly={readOnly}
                    />
                </div>
            </div>
            {/* FLASHCARD CONTROLS */}
            <Flex
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: spacing.MD,
                    position: "relative",
                    height: "fitc-content",
                    gap: spacing.XS,
                }}
                className={clsx("scrollbar-thin", styles.scrollXOnMobile)}>
                {renderSettingsButtons()}
                {sortMode ? renderSortNavigation() : renderFlashcardNavigation()}
                {showControls && (
                    <FlexRowAlignCenter style={{ gap: "inherit" }}>
                        {!readOnly && (
                            <CircularFilledIcon
                                Icon={Pen}
                                size={iconSizes.SM}
                                onClick={() => router.push(`/flashcards/${flashcardSetId}/edit`)}
                                tooltip={"edit this flashcard set"}
                            />
                        )}
                        <CircularFilledIcon
                            Icon={Copy}
                            size={iconSizes.SM}
                            onClick={() => {
                                checkAuth(
                                    async () => {
                                        const newFlashcardSet = await toast.promise(
                                            duplicateFlashcardSet(flashcardSetId, user),
                                            {
                                                loading: "Copying to your notebook",
                                                success: "Flashcard set duplicated successfully!",
                                                error: "Failed to duplicate the Flashcard set",
                                            }
                                        );

                                        router.push(`/flashcards/${newFlashcardSet.flashcardSetId}/edit`);
                                    },
                                    {
                                        event: AuthEvent.DUPLICATE_FLASHCARDS,
                                    }
                                );
                            }}
                            tooltip={"duplicate this flashcard set"}
                        />
                        {flashcardSet?.classId ? (
                            <ClassSharingPillWithHandler flashcardSet={flashcardSet} iconOnly />
                        ) : (
                            <SharingPillWithHandler flashcardSet={flashcardSet} iconOnly />
                        )}
                        <FlashcardSetOptionsMenu
                            flashcardSet={flashcardSet}
                            parentRef={null}
                            btnSx={{
                                backgroundColor: themeColors.neutralWhite,
                                width: "3.8rem",
                                height: "3.8rem",
                                marginTop: "0",
                            }}
                        />
                    </FlexRowAlignCenter>
                )}
            </Flex>
            <LazyLoaded load={isStudySettingsOpen && onOverviewPage}>
                <StudySettingsPopup
                    isOpen={isStudySettingsOpen}
                    onClose={() => setIsStudySettingsOpen(false)}
                    studySessionType={StudySessionType.REVIEW}
                    updateStudySessionViewer={updateStudySessionViewer}
                    itemId={flashcardSetId as string}
                    startNewRound={startNewRound}
                    studySession={studySession as StudySession}
                    flashcardSet={flashcardSet}
                    fromOverview
                />
            </LazyLoaded>
        </div>
    );
};

export default memo(FlashcardsCarousel);
