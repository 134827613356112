import { now } from "@/utils/SyncUtils";
import { useCallback, useState } from "react";

export const useRunningLapTimer = () => {
    const [isRunning, setIsRunning] = useState(false);
    const [laps, setLaps] = useState<number[]>([]);

    const start = useCallback(() => {
        setIsRunning(true);
        setLaps([now()]);
    }, []);

    const stop = useCallback(() => {
        setIsRunning(false);
        setLaps([]);
    }, []);

    const lap = useCallback(() => {
        if (!isRunning) return 0;

        const lastLap = laps[laps.length - 1];
        const lapTime = now() - lastLap;
        setLaps([...laps, now()]);

        return lapTime;
    }, [isRunning, laps]);

    return { isRunning, start, stop, lap, laps };
};
