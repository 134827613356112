"use client";
import { withSide } from "@/hooks/study/utils";
import { FlashcardWithSide } from "@knowt/syncing/graphql/customSchema";
import {
    Flashcard,
    FlashcardSet,
    FlashcardSetViewer,
    FlashcardSide,
    StudySession,
    StudySessionType,
} from "@knowt/syncing/graphql/schema";
import { useFlashcardSetViewer } from "@knowt/syncing/hooks/flashcardSetViewer/useFlashcardSetViewer";
import { useFlashcardSet } from "@knowt/syncing/hooks/flashcards/useFlashcards";
import { useFlashcardStudyStates, useStudySession } from "@knowt/syncing/hooks/study/useStudySession";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import difference from "lodash/difference";
import intersection from "lodash/intersection";
import React, { useCallback, useMemo } from "react";

type ReviewModeContextValue = {
    flashcardSet?: FlashcardSet;
    flashcardSetViewerData: FlashcardSetViewer;
    flashcards: FlashcardWithSide[];
    updateFlashcard: (
        flashcardId: string,
        data: Partial<Flashcard> | ((oldFlashcard: Flashcard) => Partial<Flashcard>)
    ) => void;
    isShowingEndScreen: boolean;
    numOfCardsInCarousel: number;
    showSignupCard: boolean;
    flashcardIndex: number;
    setFlashcardIndex: (n: number) => void;
    starProps: {
        starredFlashcardIds?: string[] | null;
        toggleFlashcardStarById: (id?: string) => void;
    };
    isLoading: boolean;
    readOnly?: boolean;
    showControls?: boolean;
    updateStudySessionViewer: (viewer: { isShuffled: boolean; position?: number; order?: string[] }) => void;
    commitViewerUpdates: () => Promise<void>;
    isViewerDirty: boolean;
    sortMode: boolean;
    isFlashcardLoading: boolean;
    onRevertSort: () => void;
} & ReturnType<typeof useStudySession>;

type ReviewModeContextProps = {
    flashcardSet?: FlashcardSet | undefined;
    flashcardSetId: string;
    studySession?: StudySession | undefined;
    children: React.ReactNode;
};

const ReviewModeContext = createContext<ReviewModeContextValue | null>(null);

export const ReviewModeContextProvider = ({
    flashcardSet: serverFlashcardSet,
    flashcardSetId,
    studySession: serverStudySession,
    children,
}: ReviewModeContextProps) => {
    const { userId, user } = useCurrentUser();

    const studySessionProps = useStudySession({
        flashcardSetId,
        type: StudySessionType.REVIEW,
        fallbackData: serverStudySession,
    });

    const { flashcardStudyStates } = useFlashcardStudyStates({ flashcardSetId });

    const studySession = studySessionProps.studySession;
    const settings = studySession?.settings?.REVIEW;
    const updateSettings = studySessionProps.updateSettings;
    const revertSorting = studySessionProps.revertSorting;

    const {
        flashcardSet,
        updateFlashcard,
        loading: isFlashcardLoading,
    } = useFlashcardSet({ flashcardSetId, fallbackData: serverFlashcardSet });

    const flashcardsWithSide = useMemo(() => {
        const cards = flashcardSet?.flashcards?.map(card => ({
            ...card,
            side: withSide(settings?.answerSide ?? FlashcardSide.DEFINITION),
        }));

        if (settings?.review) {
            // we are in review mode, filter out the "know" cards
            return cards?.filter(card => flashcardStudyStates[card.flashcardId]?.sort !== true);
        }

        return cards;
    }, [flashcardSet, flashcardStudyStates, settings]);

    const {
        flashcardSetViewerData,
        updateStudySessionViewer: _updateStudySessionViewer,
        flashcardIndex,
        setFlashcardIndex,
        toggleFlashcardStarByIds,
        commitUpdates: commitViewerUpdates,
        isDirty: isViewerDirty,
    } = useFlashcardSetViewer({ flashcardSetId });

    // casting because if it's not null/undefined, it has to be a string array
    // and cannot be a (string|null) array as the type `initialFlashcardIndex[] | null | undefined` suggests
    const starred = flashcardSetViewerData?.starred as string[] | null | undefined;
    const order = flashcardSetViewerData?.order;

    const flashcards = useMemo(() => {
        let cards = flashcardsWithSide || [];
        if (order?.length) {
            const flashcardMap =
                flashcardsWithSide?.reduce((acc, curr) => {
                    // shouldn't happen, but just for TS to be happy
                    if (!curr?.flashcardId) return acc;
                    acc[curr.flashcardId] = curr;
                    return acc;
                }, {} as Record<string, FlashcardWithSide>) || {};

            cards = order
                .map(id => {
                    // shouldn't happen, but just for TS to be happy
                    if (!id) return null;
                    return flashcardMap[id];
                })
                .filter(Boolean) as FlashcardWithSide[];
        }

        if (settings?.starred && starred?.length === 0) {
            updateSettings({ starred: false });
        }

        // TODO: set index to first card that is not sorted

        return cards.filter(card => {
            if (!card) return false;
            return settings?.starred ? starred?.includes(card?.flashcardId) : true;
        });
    }, [flashcardsWithSide, order, settings, updateSettings, starred]);

    const onRevertSort = useCallback(() => {
        if (flashcardIndex === 0) return;
        const lastFlashcardId = flashcards[flashcardIndex - 1]?.flashcardId;
        revertSorting(lastFlashcardId);
        setFlashcardIndex(flashcardIndex - 1);
    }, [revertSorting, flashcards, flashcardIndex, setFlashcardIndex]);

    const getSortAwareShuffle = useCallback(
        ({
            sort,
            shuffle = true,
        }: {
            sort: boolean;
            shuffle: boolean;
        }): { order: string[] | undefined; position: number } => {
            const currentOrder = flashcardSetViewerData?.order;

            const alreadySorted = Object.values(flashcardStudyStates)
                .filter(state => state.sort === true || state.sort === false)
                .map(state => state.flashcardId);

            const notSorted = Object.values(flashcardStudyStates)
                .filter(state => state.sort === null || state.sort === undefined)
                .map(state => state.flashcardId);

            if (!sort) {
                // we don't care about the order
                return {
                    order: shuffle ? [...notSorted, ...alreadySorted].sort(() => Math.random() - 0.5) : undefined,
                    position: 0,
                };
            }

            if (sort && shuffle) {
                // TODO; if we already shuffled and sorted, keep that same order. if currentOrder is a subset of the alreadySorted, then we can just append the notSorted to the end
                if (currentOrder?.length && difference(currentOrder, alreadySorted).length === 0) {
                    return {
                        order: [
                            ...intersection(currentOrder, alreadySorted),
                            ...difference(alreadySorted, currentOrder).sort(() => Math.random() - 0.5),
                            ...notSorted.sort(() => Math.random() - 0.5),
                        ],
                        position: currentOrder.length,
                    };
                }
                // we care about the order, and we want it shuffled
                return {
                    order: [
                        ...alreadySorted.sort(() => Math.random() - 0.5),
                        ...notSorted.sort(() => Math.random() - 0.5),
                    ],
                    position: 0,
                };
            }

            if (currentOrder?.length) {
                // we already have a shuffled set

                return {
                    order: [...alreadySorted, ...notSorted],
                    position: alreadySorted.length,
                };
            } else {
                // TODO:
            }
        },
        [flashcardSetViewerData?.order, flashcardStudyStates]
    );

    const numOfFlashcards = flashcards?.length ?? 0;
    const showSignupCard = !user && numOfFlashcards >= 4;
    const numOfCardsInCarousel = numOfFlashcards + (showSignupCard ? 0 : 1);

    const value = useMemo(() => {
        return {
            // TODO: because of null returned from graphql, fix after release
            flashcardSet: flashcardSet ?? undefined,
            flashcardSetViewerData,
            flashcards: !showSignupCard ? flashcards : flashcards.slice(0, 5),
            showSignupCard,
            readOnly: flashcardSet?.userId !== userId,
            updateFlashcard,
            isShowingEndScreen: (flashcardIndex || 0) >= numOfCardsInCarousel - 1,
            numOfCardsInCarousel,
            // in the case that someone turns on starred mode while they were in the middle of a set
            flashcardIndex: Math.min(flashcardIndex, numOfCardsInCarousel - 1) ?? 0,
            setFlashcardIndex,
            starProps: {
                starredFlashcardIds: starred,
                toggleFlashcardStarById: id => id && toggleFlashcardStarByIds([id]),
            },
            studySession: studySession ?? undefined,
            getSortAwareShuffle,
            updateStudySessionViewer: ({ isShuffled, position, order }) =>
                _updateStudySessionViewer({
                    isShuffled,
                    position,
                    order,
                    flashcards: flashcardSet?.flashcards,
                }),
            ...studySessionProps,
            isLoading: !flashcardSet || !flashcardSetViewerData || !flashcards,
            commitViewerUpdates,
            isViewerDirty,
            sortMode: !!settings?.sort,
            isFlashcardLoading,
            onRevertSort,
        };
    }, [
        studySessionProps,
        _updateStudySessionViewer,
        commitViewerUpdates,
        flashcardIndex,
        flashcardSet,
        flashcardSetViewerData,
        flashcards,
        getSortAwareShuffle,
        isViewerDirty,
        numOfCardsInCarousel,
        setFlashcardIndex,
        settings?.sort,
        showSignupCard,
        starred,
        studySession,
        toggleFlashcardStarByIds,
        updateFlashcard,
        userId,
        isFlashcardLoading,
        onRevertSort,
    ]);

    return <ReviewModeContext.Provider value={value}>{children}</ReviewModeContext.Provider>;
};

export const useReviewModeContextSelector = <T,>(selector: (value: ReviewModeContextValue) => T) =>
    useContextSelector(ReviewModeContext, selector);
