import ToggleSwitch from "@/components/ToggleSwitch";
import ClickableText from "@/components/styled/button/ClickableText";
import { themeColors } from "@/utils/themeColors";
import { useFlashcardSetViewer } from "@knowt/syncing/hooks/flashcardSetViewer/useFlashcardSetViewer";
import { QuestionType, StudySessionType, TestStudySessionSetting } from "@knowt/syncing/graphql/schema";
import { toggleArrayElements } from "@knowt/syncing/utils/arrayUtils";
import { getAnswerSide, getAnswerToggles } from "@knowt/syncing/utils/genericUtils";
import useCombinedState from "@knowt/syncing/utils/hooks/useCombinedState";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import CloseButton from "../../../../components/CircularButton/styled/CloseButton";
import { addEmitEventListener, removeEmitEventListener } from "../../utils/eventEmitter";
import { START_STUDY_EVENT } from "./SettingsMatchingMode";
import { useStudySettingsContextSelector } from "../../StudySettings";
import { HeaderSection, Divider, Label, Option, OptionLabel, Section } from "../styled/SharedLayouts";
import StartButton from "../StartButton";
import StudyDatePicker from "../styled/StudyDatePicker";
import StudySettingsNumberInput from "../StudySettingsNumberInput";
import { validateAnswerSide, validateLength, validateQuestionTypes } from "../../utils/utils";
import { PracticeModeSettingsInput } from "../../types";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";

const SettingsPracticeMode = () => {
    const { smDown } = useBreakPoints();

    const itemId = useStudySettingsContextSelector(state => state.itemId);
    const startNewRound = useStudySettingsContextSelector(state => state.startNewRound);
    const studySession = useStudySettingsContextSelector(state => state.studySession);
    const flashcardSet = useStudySettingsContextSelector(state => state.flashcardSet);
    const onClose = useStudySettingsContextSelector(state => state.onClose);
    const handleRetakeTest = useStudySettingsContextSelector(state => state.handleRetakeTest);

    const practiceModeSettings = studySession?.settings.TEST;

    const settingsExists = practiceModeSettings && practiceModeSettings.answerSide !== undefined;

    const { isStarredCardsExist } = useFlashcardSetViewer({ flashcardSetId: itemId });

    const [settings, updateSettings] = useCombinedState<PracticeModeSettingsInput>({
        examDate: studySession?.examDate ?? undefined,
        length: practiceModeSettings?.length ?? Math.min(flashcardSet?.flashcards?.length ?? 100, 20),
        questionTypes: practiceModeSettings?.questionTypes || [QuestionType.MULTI, QuestionType.WRITING],
        ...getAnswerToggles(practiceModeSettings?.answerSide),
        starred: !!practiceModeSettings?.starred,
        shuffled: practiceModeSettings?.shuffled ?? true,
        fuzzy: practiceModeSettings?.fuzzy ?? true,
    });

    const settingsAreChanged = (
        settings: PracticeModeSettingsInput,
        practiceModeSettings?: TestStudySessionSetting | null
    ) => {
        if (!practiceModeSettings) {
            return true;
        }

        return (
            settings.examDate !== (studySession?.examDate ?? undefined) ||
            settings.length !== practiceModeSettings.length ||
            settings.questionTypes !== practiceModeSettings.questionTypes ||
            getAnswerSide({ answerWithTerm: settings.answerWithTerm, answerWithDef: settings.answerWithDef }) !==
                practiceModeSettings.answerSide ||
            settings.starred !== practiceModeSettings.starred ||
            settings.shuffled !== practiceModeSettings.shuffled ||
            settings.fuzzy !== practiceModeSettings.fuzzy
        );
    };

    const startStudy = async (newTest = false) => {
        try {
            validateLength(settings.length);
            validateQuestionTypes(settings.questionTypes);
            validateAnswerSide({ answerWithTerm: settings.answerWithTerm, answerWithDef: settings.answerWithDef });
        } catch (err) {
            toast.error(err.message);
            return;
        }

        if (!newTest && !settingsAreChanged(settings, practiceModeSettings)) {
            return onClose();
        }

        await startNewRound({
            flashcardSetId: itemId,
            type: StudySessionType.TEST,
            examDate: settings.examDate,
            settings: {
                TEST: {
                    answerSide: getAnswerSide({
                        answerWithTerm: settings.answerWithTerm,
                        answerWithDef: settings.answerWithDef,
                    }),
                    fuzzy: settings.fuzzy,
                    length: settings.length,
                    questionTypes: settings.questionTypes,
                    shuffled: settings.shuffled,
                    starred: settings.starred,
                },
            },
        })
            .then(() => {
                onClose();
            })
            .catch(err => {
                toast.error(err.message);
            });
    };

    useEffect(() => {
        addEmitEventListener(START_STUDY_EVENT, startStudy);

        return () => {
            removeEmitEventListener(START_STUDY_EVENT, startStudy);
        };
    });

    return (
        <>
            <HeaderSection>
                <div className="bold" style={{ fontSize: "3rem", color: themeColors.neutralBlack }}>
                    {smDown ? "" : "Test "}Options
                </div>
                {!settingsExists && <StartButton label={"start"} onClick={() => startStudy()} />}
                {settingsExists && <CloseButton onClick={startStudy} />}
            </HeaderSection>

            <Section>
                <Label>Studying for an exam?</Label>
                <Option style={{ flexWrap: "wrap" }}>
                    <span>When is your exam?</span>
                    <StudyDatePicker onChange={date => updateSettings({ examDate: date })} date={settings.examDate} />
                </Option>
            </Section>
            <Divider />

            <Section>
                <Label>Length of Practice Test</Label>
                <Option>
                    <span>Set how many questions you want in your practice test</span>
                    <StudySettingsNumberInput value={settings.length} onChange={n => updateSettings({ length: n })} />
                </Option>
            </Section>
            <Divider />

            <Section>
                <Label>Question Types</Label>
                <Option>
                    <OptionLabel label="Flashcards" />
                    <ToggleSwitch
                        checked={settings.questionTypes.includes(QuestionType.NONE)}
                        onChange={() =>
                            updateSettings({
                                questionTypes: toggleArrayElements(settings.questionTypes, [QuestionType.NONE]),
                            })
                        }
                    />
                </Option>
                <Option>
                    <OptionLabel label="Multiple Choice" />
                    <ToggleSwitch
                        checked={settings.questionTypes.includes(QuestionType.MULTI)}
                        onChange={() =>
                            updateSettings({
                                questionTypes: toggleArrayElements(settings.questionTypes, [QuestionType.MULTI]),
                            })
                        }
                    />
                </Option>
                <Option>
                    <OptionLabel label="Written" />
                    <ToggleSwitch
                        checked={settings.questionTypes.includes(QuestionType.WRITING)}
                        onChange={() =>
                            updateSettings({
                                questionTypes: toggleArrayElements(settings.questionTypes, [QuestionType.WRITING]),
                            })
                        }
                    />
                </Option>
                <Option>
                    <OptionLabel label="True & False" />
                    <ToggleSwitch
                        checked={settings.questionTypes.includes(QuestionType.TF)}
                        onChange={() =>
                            updateSettings({
                                questionTypes: toggleArrayElements(settings.questionTypes, [QuestionType.TF]),
                            })
                        }
                    />
                </Option>
            </Section>
            <Divider />

            <Section>
                <Label>Question Format</Label>
                <Option>
                    <OptionLabel label="Answer with Term" />
                    <ToggleSwitch
                        checked={settings.answerWithTerm}
                        onChange={() => updateSettings({ answerWithTerm: !settings.answerWithTerm })}
                    />
                </Option>
                <Option>
                    <OptionLabel label="Answer with Definition" />
                    <ToggleSwitch
                        checked={settings.answerWithDef}
                        onChange={() => updateSettings({ answerWithDef: !settings.answerWithDef })}
                    />
                </Option>
            </Section>
            <Divider />

            <Section>
                <Label>Learning Options</Label>
                <Option>
                    <OptionLabel label="Study starred terms only" />
                    <ToggleSwitch
                        checked={settings.starred}
                        onChange={() => {
                            if (!isStarredCardsExist) {
                                toast.error("You don't have any starred terms");
                            } else {
                                updateSettings({ starred: !settings.starred });
                            }
                        }}
                        disabled={!isStarredCardsExist}
                    />
                </Option>
                <Option>
                    <OptionLabel label="Shuffle terms" />
                    <ToggleSwitch
                        checked={settings.shuffled}
                        onChange={() => updateSettings({ shuffled: !settings.shuffled })}
                    />
                </Option>
                <Option>
                    <OptionLabel label="Smart grading" />
                    <ToggleSwitch
                        checked={settings.fuzzy}
                        onChange={() => updateSettings({ fuzzy: !settings.fuzzy })}
                    />
                </Option>
            </Section>

            {settingsExists && <Divider />}
            {settingsExists && (
                <Section>
                    <ClickableText style={{ color: themeColors.primary }} onClick={() => startStudy()}>
                        Save options
                    </ClickableText>
                    <ClickableText style={{ color: themeColors.errorPrimary }} onClick={handleRetakeTest}>
                        Retake test
                    </ClickableText>
                    <ClickableText style={{ color: themeColors.errorPrimary }} onClick={() => startStudy(true)}>
                        Create a new test
                    </ClickableText>
                </Section>
            )}
        </>
    );
};

export default SettingsPracticeMode;
