import { TIME, TIME_SECONDS } from "@/utils/dateTimeUtils";

export const DEFAULT_INTERVALS = [
    TIME_SECONDS.MINUTE,
    15 * TIME_SECONDS.MINUTE,
    TIME_SECONDS.DAY,
    4 * TIME_SECONDS.DAY,
];

export const getSpacedRepetitionMultiplier = (_examDate?: string) => {
    if (!_examDate) {
        return 1;
    }

    const today = new Date();
    const examDate = new Date(Number(_examDate) * 1000);

    const daysUntilExam = Math.floor((examDate.getTime() - today.getTime()) / TIME.DAY);

    if (daysUntilExam < 0) {
        return 1;
    }

    if (daysUntilExam < 7) {
        return (daysUntilExam + 1) / 7 / 4;
    }

    if (daysUntilExam < 14) {
        return 1 / 2;
    }

    if (daysUntilExam < 21) {
        return 3 / 4;
    }

    return 1;
};

export const getMappedValuePeriod = (result: number): { value: number; period: TIME_SECONDS } => {
    const periodsToCheck = [TIME_SECONDS.DAY, TIME_SECONDS.HOUR, TIME_SECONDS.MINUTE, TIME_SECONDS.SECOND];

    for (const period of periodsToCheck) {
        const value = Math.floor(result / period);
        if (value > 0 && result % period === 0) {
            return { value, period };
        }
    }

    return { value: 0, period: TIME_SECONDS.MINUTE };
};
