import { SubscriptionType } from "@knowt/syncing/graphql/schema";
import { useCallback, useEffect, useState } from "react";

const useAdvanceTimer = ({
    subscriptionType,
    length = 5_000,
    advanceFunc,
}: {
    subscriptionType: SubscriptionType;
    length?: number;
    advanceFunc: () => Promise<void>;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [timer, setTimer] = useState(length);
    const [timerStarted, setTimerStarted] = useState(false);

    const isTimerActive = subscriptionType === SubscriptionType.BASIC && timerStarted;

    const advance = useCallback(async () => {
        setIsLoading(true);
        await advanceFunc();
        setIsLoading(false);
    }, [advanceFunc]);

    useEffect(() => {
        if (timer <= 0 && !isLoading) {
            advance();
        }
    }, [timer, advance, isLoading]);

    const startTimeout = () => {
        if (timer <= 0) {
            return;
        }

        const time = Math.floor(Math.random() * 100);

        setTimeout(() => {
            setTimer(prev => prev - time);
            startTimeout();
        }, time);
    };

    const onClick = async () => {
        if (isLoading) return;

        setTimerStarted(true);
        // set timer for 5 seconds
        if (subscriptionType === SubscriptionType.BASIC) {
            startTimeout();
        } else {
            setTimer(0);
            advance();
        }
    };

    return {
        onClick,
        isLoading,
        isTimerActive,
        timer,
    };
};

export default useAdvanceTimer;
