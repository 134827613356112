import ToggleSwitch from "@/components/ToggleSwitch";
import CloseButton from "@/components/CircularButton/styled/CloseButton";
import ClickableText from "@/components/styled/button/ClickableText";
import useConfirmDialog from "@/hooks/useConfirmDialog";
import { themeColors } from "@/utils/themeColors";
import { useFlashcardSetViewer } from "@knowt/syncing/hooks/flashcardSetViewer/useFlashcardSetViewer";
import { callClearFlashcardSetStudySession } from "@knowt/syncing/hooks/study/graphqlUtils";
import {
    LearnStudySessionSetting,
    QuestionType,
    StudySessionProgressEnum,
    StudySessionType,
} from "@knowt/syncing/graphql/schema";
import { toggleArrayElements } from "@knowt/syncing/utils/arrayUtils";
import { getAnswerSide, getAnswerToggles } from "@knowt/syncing/utils/genericUtils";
import useCombinedState from "@knowt/syncing/utils/hooks/useCombinedState";
import { useSearchParams } from "next/navigation";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { addEmitEventListener, removeEmitEventListener } from "../../utils/eventEmitter";
import { START_STUDY_EVENT } from "./SettingsMatchingMode";
import { useStudySettingsContextSelector } from "../../StudySettings";
import AudioSettings from "../AudioSettings";
import { Divider, Label, Option, OptionLabel, Section, HeaderSection } from "../styled/SharedLayouts";
import StartButton from "../StartButton";
import StudyDatePicker from "../styled/StudyDatePicker";
import { validateAnswerSide, validateQuestionTypes } from "../../utils/utils";
import { LearnModeSettingsInput } from "../../types";
import StudySettingsNumberInput from "../StudySettingsNumberInput";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";

export const LEARN_MODE_MAX_LENGTH = 7;

const SettingsLearnMode = () => {
    const { smDown } = useBreakPoints();

    const itemId = useStudySettingsContextSelector(state => state.itemId);
    const startNewRound = useStudySettingsContextSelector(state => state.startNewRound);
    const studySession = useStudySettingsContextSelector(state => state.studySession);
    const onClose = useStudySettingsContextSelector(state => state.onClose);

    const searchParams = useSearchParams();
    const studyFrom = searchParams.get("studyFrom") as StudySessionProgressEnum | undefined;

    const { openConfirmDialog } = useConfirmDialog();
    const learnModeSettings = studySession?.settings.LEARN;

    const { isStarredCardsExist } = useFlashcardSetViewer({ flashcardSetId: itemId });

    const settingsExists = learnModeSettings && learnModeSettings.answerSide !== undefined;

    const [settings, updateSettings] = useCombinedState<LearnModeSettingsInput>({
        examDate: studySession?.examDate ?? undefined,
        length: learnModeSettings?.length ?? LEARN_MODE_MAX_LENGTH,
        questionTypes: learnModeSettings?.questionTypes || [QuestionType.TF, QuestionType.MULTI, QuestionType.WRITING],
        ...getAnswerToggles(learnModeSettings?.answerSide),
        starred: !!learnModeSettings?.starred,
        shuffled: learnModeSettings?.shuffled ?? true,
        fuzzy: learnModeSettings?.fuzzy ?? true,
        reType: !!learnModeSettings?.reType,
    });

    const settingsAreChanged = (
        settings: LearnModeSettingsInput,
        learnModeSettings?: LearnStudySessionSetting | null
    ) => {
        if (!learnModeSettings) {
            return true;
        }

        return (
            settings.examDate !== (studySession?.examDate ?? undefined) ||
            settings.questionTypes !== learnModeSettings.questionTypes ||
            getAnswerSide({ answerWithTerm: settings.answerWithTerm, answerWithDef: settings.answerWithDef }) !==
                learnModeSettings.answerSide ||
            settings.starred !== learnModeSettings.starred ||
            settings.shuffled !== learnModeSettings.shuffled ||
            settings.fuzzy !== learnModeSettings.fuzzy ||
            settings.reType !== learnModeSettings.reType
        );
    };

    const startStudy = async () => {
        try {
            validateQuestionTypes(settings.questionTypes);
            validateAnswerSide({ answerWithTerm: settings.answerWithTerm, answerWithDef: settings.answerWithDef });
        } catch (err) {
            toast.error(err.message);
            return;
        }

        if (!settingsAreChanged(settings, learnModeSettings)) {
            return onClose();
        }

        const answerSide = getAnswerSide({
            answerWithTerm: settings.answerWithTerm,
            answerWithDef: settings.answerWithDef,
        });

        await startNewRound({
            flashcardSetId: itemId,
            type: StudySessionType.LEARN,
            examDate: settings.examDate,
            settings: {
                LEARN: {
                    answerSide,
                    fuzzy: settings.fuzzy,
                    questionTypes: settings.questionTypes,
                    shuffled: settings.shuffled,
                    starred: settings.starred,
                    reType: settings.reType,
                },
            },
            studyFrom,
        })
            .then(() => {
                onClose();
            })
            .catch(err => {
                toast.error(err.message);
            });
    };

    useEffect(() => {
        addEmitEventListener(START_STUDY_EVENT, startStudy);
        return () => {
            removeEmitEventListener(START_STUDY_EVENT, startStudy);
        };
    });

    return (
        <>
            <HeaderSection>
                <div className="bold" style={{ fontSize: "3rem", color: themeColors.neutralBlack }}>
                    {smDown ? "" : "Learn Mode "}Options
                </div>
                {!settingsExists && <StartButton label={"start"} onClick={startStudy} />}
                {settingsExists && <CloseButton onClick={startStudy} />}
            </HeaderSection>

            <React.Fragment>
                <Section>
                    <Label>Studying for an exam?</Label>
                    <Option style={{ flexWrap: "wrap" }}>
                        <OptionLabel label="When is your exam?" />
                        <StudyDatePicker
                            date={settings.examDate}
                            onChange={date => updateSettings({ examDate: date })}
                        />
                    </Option>

                    <Option>
                        <OptionLabel label="Length of Rounds" />
                        <StudySettingsNumberInput
                            value={settings.length}
                            onChange={n => updateSettings({ length: n })}
                        />
                    </Option>
                </Section>
                <Divider />
            </React.Fragment>

            <Section>
                <Label>Question Types</Label>
                <Option>
                    <OptionLabel label="Flashcards" />
                    <ToggleSwitch
                        checked={settings.questionTypes.includes(QuestionType.NONE)}
                        onChange={() =>
                            updateSettings({
                                questionTypes: toggleArrayElements(settings.questionTypes, [QuestionType.NONE]),
                            })
                        }
                    />
                </Option>
                <Option>
                    <OptionLabel label="Multiple Choice" />
                    <ToggleSwitch
                        checked={settings.questionTypes.includes(QuestionType.MULTI)}
                        onChange={() =>
                            updateSettings({
                                questionTypes: toggleArrayElements(settings.questionTypes, [QuestionType.MULTI]),
                            })
                        }
                    />
                </Option>
                <Option>
                    <OptionLabel label="Written" />
                    <ToggleSwitch
                        checked={settings.questionTypes.includes(QuestionType.WRITING)}
                        onChange={() =>
                            updateSettings({
                                questionTypes: toggleArrayElements(settings.questionTypes, [QuestionType.WRITING]),
                            })
                        }
                    />
                </Option>
                <Option>
                    <OptionLabel label="True & False" />
                    <ToggleSwitch
                        checked={settings.questionTypes.includes(QuestionType.TF)}
                        onChange={() =>
                            updateSettings({
                                questionTypes: toggleArrayElements(settings.questionTypes, [QuestionType.TF]),
                            })
                        }
                    />
                </Option>
            </Section>
            <Divider />

            <Section>
                <Label>Question Format</Label>
                <Option>
                    <OptionLabel label="Answer with Term" />
                    <ToggleSwitch
                        checked={settings.answerWithTerm}
                        onChange={() => updateSettings({ answerWithTerm: !settings.answerWithTerm })}
                    />
                </Option>
                <Option>
                    <OptionLabel label="Answer with Definition" />
                    <ToggleSwitch
                        checked={settings.answerWithDef}
                        onChange={() => updateSettings({ answerWithDef: !settings.answerWithDef })}
                    />
                </Option>
            </Section>
            <Divider />

            <Section>
                <Label>Learning Options</Label>
                <Option>
                    <OptionLabel label="Study starred terms only" />
                    <ToggleSwitch
                        checked={settings.starred}
                        onChange={() => {
                            if (!isStarredCardsExist) {
                                toast.error("You don't have any starred terms");
                            } else {
                                updateSettings({ starred: !settings.starred });
                            }
                        }}
                        disabled={!isStarredCardsExist}
                    />
                </Option>
                <Option>
                    <OptionLabel label="Shuffle terms" />
                    <ToggleSwitch
                        checked={settings.shuffled}
                        onChange={() => updateSettings({ shuffled: !settings.shuffled })}
                    />
                </Option>
                <Option>
                    <OptionLabel label="Smart grading" />
                    <ToggleSwitch
                        checked={settings.fuzzy}
                        onChange={() => updateSettings({ fuzzy: !settings.fuzzy })}
                    />
                </Option>
                <Option>
                    <OptionLabel label="Re-type answers" />
                    <ToggleSwitch
                        checked={settings.reType}
                        onChange={() => updateSettings({ reType: !settings.reType })}
                    />
                </Option>
            </Section>

            <Divider />
            <AudioSettings />

            {settingsExists && <Divider />}
            {settingsExists && (
                <Section>
                    <ClickableText style={{ color: themeColors.primary }} onClick={startStudy}>
                        Save options
                    </ClickableText>
                    <ClickableText
                        style={{ color: themeColors.icon3 }}
                        onClick={async () => {
                            onClose();
                            openConfirmDialog({
                                title: `Are you sure you want to reset?`,
                                subtitle:
                                    "Resetting will not save your options, but rather start your learn mode again from scratch",
                                style: { borderRadius: "4rem" },
                                rounded: true,
                                cardSize: "60rem",
                                spacing: 2.2,
                                subtitleFontSize: "1.9rem",
                                subtitleFontWeight: "500",
                                buttonColor: "black",
                                simpleCancelBtn: true,
                                onConfirm: async () => {
                                    toast.loading("Resetting progress...", { id: "RESET_STUDY" });
                                    await callClearFlashcardSetStudySession({
                                        flashcardSetId: itemId,
                                    });
                                    toast.success("Study session cleared!", { id: "RESET_STUDY" });
                                    window.location.reload();
                                },
                            });
                        }}>
                        Reset progress & restart
                    </ClickableText>
                </Section>
            )}
        </>
    );
};

export default SettingsLearnMode;
