import Image from "next/image";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import styles from "./flashcardsToPrint.module.css";
import { ASSETS_URL } from "@/config/deployConstants";
import HtmlText from "@/components/HtmlText";
import { FlashcardSet } from "@knowt/syncing/graphql/schema";
import { FlexRowAlignCenter } from "@/components/Flex";

type FlashcardsToPrintProps = {
    flashcardSet: FlashcardSet;
};

export type FlashcardsToPrintHandle = {
    print: () => void;
};

const FlashcardsToPrint = forwardRef<FlashcardsToPrintHandle, FlashcardsToPrintProps>(({ flashcardSet }, ref) => {
    const [isPrinted, setIsPrinted] = useState(false);

    const componentRef = useRef<HTMLDivElement>(null);

    const printFlashcards = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: flashcardSet.title || "Knowt",
        bodyClass: styles.printedBodyClass,
        pageStyle: `
            @page {
            size: A4;
            margin: 4rem 0;
            }`,
        onAfterPrint: () => setIsPrinted(false),
    });

    const areFlashcardsUnavailable = () => {
        return (
            !flashcardSet.flashcards ||
            flashcardSet.flashcards.every(flashcard => {
                const { term, definition } = flashcard ?? {};
                return (term === null || term === undefined) && (definition === null || definition === undefined);
            })
        );
    };

    useImperativeHandle(ref, () => ({
        print: () => {
            if (areFlashcardsUnavailable()) {
                throw new Error("Flashcards are not yet loaded");
            }

            setIsPrinted(true);

            // We are using a timeout here because we need to wait for the
            // component's content to be rendered before we can print it.
            setTimeout(() => {
                printFlashcards();
            }, 100);
        },
    }));

    const title = flashcardSet.title || "Flashcard Set";

    if (!isPrinted) {
        return null;
    }

    return (
        <div ref={componentRef} className={styles.container}>
            <div className={styles.logo}>
                <Image
                    src={`${ASSETS_URL}/images/PDFLogo.png`}
                    alt={"knowt logo"}
                    width={80}
                    height={80}
                    style={{ objectFit: "contain" }}
                />
            </div>
            <div className={styles.title}>{title}</div>
            <table className={styles.table}>
                {flashcardSet.flashcards?.map(flashcard => (
                    <tr className={styles.row} key={flashcard?.flashcardId}>
                        <td className={styles.cell}>
                            <FlexRowAlignCenter style={{ gap: "1rem" }}>
                                {flashcard?.image && (
                                    <Image src={flashcard?.image} alt="term image" width={100} height={100} />
                                )}
                                <HtmlText text={flashcard?.term} />
                            </FlexRowAlignCenter>
                        </td>
                        <td className={styles.cell} style={{ borderLeft: "1px dashed #000" }}>
                            <FlexRowAlignCenter style={{ gap: "1rem", justifyContent: "space-between" }}>
                                <HtmlText text={flashcard?.definition} />
                                {flashcard?.secondaryImage && (
                                    <Image
                                        src={flashcard?.secondaryImage}
                                        alt="definition image"
                                        width={100}
                                        height={100}
                                    />
                                )}
                            </FlexRowAlignCenter>
                        </td>
                    </tr>
                ))}
            </table>
        </div>
    );
});

FlashcardsToPrint.displayName = "FlashcardsToPrint";
export default FlashcardsToPrint;
