import { Editor } from "@knowt/editor/react";
import { useFlashcardSet } from "@knowt/syncing/hooks/flashcards/useFlashcards";
import { Flashcard, FlashcardSide } from "@knowt/syncing/graphql/schema";
import noop from "@knowt/syncing/utils/noop";
import { useCallback, useEffect, useRef, useState } from "react";
import FlashcardEditorToolbar from "../flashcards/FlashcardCard/EditableFlashcardCard/FlashcardEditorToolbar";
import Dialog from "@/components/Dialog";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import SlideMenu from "@/components/SlideMenu";
import FlashcardEditor, {
    FlashcardEditorHandle,
} from "@/components/flashcards/FlashcardCard/EditableFlashcardCard/FlashcardEditor";
import { ImageButton } from "@/components/flashcards/FlashcardCard/EditableFlashcardCard/SharedComponents";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { themeColors } from "@/utils/themeColors";
import CircularOutlineIcon from "../CircularButton/styled/CircularOutlineIcon";
import { iconSizes } from "@/utils/iconProps";
import { XIcon } from "lucide-react";
import { useInterval } from "react-use";
import { SetState } from "@knowt/syncing/types/common";
import { DialogProps } from "@mui/material/Dialog";
import { objectWithout } from "@knowt/syncing/utils/dataCleaning";

const EditableFlashcardPopup = ({
    isOpen,
    onClose,
    flashcard: initialFlashcard = {
        flashcardId: "",
        term: "",
        definition: "",
        image: null,
        secondaryImage: null,
    },
    dialogProps,
}: {
    flashcard: Flashcard & { side?: FlashcardSide };
    isOpen: boolean;
    onClose: () => void;
    dialogProps: Partial<DialogProps>;
}) => {
    const { mdDown, smDown } = useBreakPoints();

    const [localFlashcard, _setLocalFlashcard] = useState<Flashcard>(objectWithout(initialFlashcard, "side"));

    const [isDirty, setIsDirty] = useState(false);

    const setLocalFlashcard: SetState<Flashcard> = useCallback(flashcard => {
        setIsDirty(true);
        _setLocalFlashcard(flashcard);
    }, []);

    useEffect(() => {
        setLocalFlashcard(objectWithout(initialFlashcard, "side"));
    }, [initialFlashcard]);

    const { flashcardId } = localFlashcard;

    const { flashcardSet, updateFlashcard, updateFlashcardSetMetaData, commitUpdates } = useFlashcardSet({
        flashcardSetId: localFlashcard.flashcardSetId,
        autoCommit: false,
    });

    const termInputRef = useRef<FlashcardEditorHandle>(null);
    const definitionInputRef = useRef<FlashcardEditorHandle>(null);

    const [focusedEditor, setFocusedEditor] = useState<Editor | null>(null);

    const save = async () => {
        await updateFlashcard(flashcardId, localFlashcard);
        commitUpdates();
    };

    const handleClose = () => {
        save();
        onClose();
    };

    useInterval(() => isDirty && save(), 3_000);

    const renderTermSection = () => {
        return (
            <Flex style={{ flexDirection: mdDown ? "column-reverse" : "row", gap: "inherit", width: "100%" }}>
                <div style={{ marginLeft: mdDown ? "auto" : "0" }}>
                    <ImageButton
                        image={localFlashcard.secondaryImage}
                        onImageChange={async ({ image }) =>
                            setLocalFlashcard({ ...localFlashcard, secondaryImage: image })
                        }
                    />
                </div>
                <div style={{ marginTop: "2rem", width: "100%" }}>
                    <FlashcardEditor
                        key={localFlashcard.flashcardId}
                        ref={termInputRef}
                        defaultValue={localFlashcard.term}
                        handleInputChange={term => setLocalFlashcard({ ...localFlashcard, term, termAudio: null })}
                        accentProps={{ isAccentButtonsVisible: false }}
                        editorProps={{
                            flashcard: {
                                side: FlashcardSide.TERM,
                                term: {
                                    ref: termInputRef,
                                    content: localFlashcard.term,
                                    language: flashcardSet?.termLanguage,
                                },
                                definition: {
                                    ref: definitionInputRef,
                                    content: localFlashcard.definition,
                                    language: flashcardSet?.definitionLanguage,
                                },
                                onChooseLanguage: selectedLang =>
                                    updateFlashcardSetMetaData?.({ definitionLanguage: selectedLang }),
                            },
                            onFocus: ({ editor }) => setFocusedEditor(editor),
                            onBlur: () => setFocusedEditor(null),
                        }}
                        handleImagePaste={secondaryImage => setLocalFlashcard({ ...localFlashcard, secondaryImage })}
                    />
                </div>
            </Flex>
        );
    };

    const renderDefinitionSection = () => {
        return (
            <Flex style={{ flexDirection: mdDown ? "column" : "row", gap: "inherit", width: "100%" }}>
                <div style={{ marginTop: "2rem", width: "100%" }}>
                    <FlashcardEditor
                        key={localFlashcard.flashcardId}
                        ref={definitionInputRef}
                        defaultValue={localFlashcard.definition}
                        handleInputChange={definition =>
                            setLocalFlashcard({ ...localFlashcard, definition, definitionAudio: null })
                        }
                        accentProps={{ isAccentButtonsVisible: false }}
                        editorProps={{
                            flashcard: {
                                side: FlashcardSide.DEFINITION,
                                term: {
                                    ref: termInputRef,
                                    content: localFlashcard.term,
                                    language: flashcardSet?.termLanguage,
                                },
                                definition: {
                                    ref: definitionInputRef,
                                    content: localFlashcard.definition,
                                    language: flashcardSet?.definitionLanguage,
                                },
                                onChooseLanguage: selectedLang =>
                                    updateFlashcardSetMetaData?.({ definitionLanguage: selectedLang }),
                            },
                            onFocus: ({ editor }) => setFocusedEditor(editor),
                            onBlur: () => setFocusedEditor(null),
                        }}
                        handleImagePaste={image => setLocalFlashcard({ ...localFlashcard, image })}
                    />
                </div>

                {/* MOST RIGHT `flashcard.secondaryImage` INPUT */}
                <div style={{ marginLeft: mdDown ? "auto" : "0" }}>
                    <ImageButton
                        image={localFlashcard.image}
                        onImageChange={async ({ image }) => setLocalFlashcard({ ...localFlashcard, image })}
                    />
                </div>
            </Flex>
        );
    };

    const renderHeader = () => {
        const TOOLBAR_HEIGHT = "3.8rem";

        return (
            <FlexRowAlignCenter style={{ justifyContent: "flex-end", position: "relative", minHeight: TOOLBAR_HEIGHT }}>
                <div
                    style={{ position: "absolute", left: "50%", transform: "translateX(-50%)" }}
                    onMouseDown={event => {
                        // to not blur the input while using the toolbar
                        event.preventDefault();
                    }}>
                    <FlashcardEditorToolbar editor={focusedEditor} style={{ height: TOOLBAR_HEIGHT }} />
                </div>
                <CircularOutlineIcon
                    radius={"3.5rem"}
                    size={iconSizes.SM}
                    Icon={XIcon}
                    style={{ backgroundColor: themeColors.card }}
                    onClick={handleClose}
                />
            </FlexRowAlignCenter>
        );
    };

    const renderContent = () => (
        <FlexColumn
            style={{ gap: "4rem" }}
            onKeyDown={e => {
                e.stopPropagation();
            }}>
            {renderHeader()}
            <Flex style={{ gap: "2rem", flexDirection: mdDown ? "column" : "row" }}>
                {renderTermSection()}
                {renderDefinitionSection()}
            </Flex>
        </FlexColumn>
    );

    const desktopContent = (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth={false}
            PaperProps={{
                style: {
                    backgroundColor: themeColors.card,
                    color: themeColors.neutralBlack,
                    boxShadow: `0 0 12px -3px ${themeColors.shadow}`,
                    borderRadius: "2rem",
                    padding: "3.5rem",
                    width: "80%",
                },
            }}
            {...dialogProps}>
            {renderContent()}
        </Dialog>
    );

    const mobileContent = (
        <SlideMenu isOpen={isOpen} onClose={handleClose} onClickAway={noop}>
            <div style={{ padding: "2.47rem 2rem 2rem 2rem" }}>{renderContent()}</div>
        </SlideMenu>
    );

    return !smDown ? desktopContent : mobileContent;
};

export default EditableFlashcardPopup;
