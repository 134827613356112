"use client";

import { useFlashcardSetViewer } from "@knowt/syncing/hooks/flashcardSetViewer/useFlashcardSetViewer";
import { useFlashcardSet } from "@knowt/syncing/hooks/flashcards/useFlashcards";
import { Flashcard, FlashcardSide, StudySessionProgressEnum } from "@knowt/syncing/graphql/schema";
import { Pen, Star, Volume2 } from "lucide-react";
import { useMemo, useState } from "react";
import Circular from "@/components/Circular";
import CircularProgress from "@/components/CircularProgress";
import LazyLoaded from "@/components/LazyLoaded";
import EditableFlashcardPopup from "@/components/Popup/EditableFlashcardPopup";
import CircularOutlineIcon from "@/components/CircularButton/styled/CircularOutlineIcon";
import { useTextToSpeechContextSelector } from "@/contexts/TextToSpeechContext";
import { useCheckAuth } from "@/hooks/useCheckAuth";
import { preventStopPropogation } from "@/utils/domUtils";
import { iconSizes } from "@/utils/iconProps";
import { getProgressPillProps } from "@/utils/progressPill";
import { themeColors } from "@/utils/themeColors";
import AnswerHintBtn from "./AnswerHintBtn";
import { getPlainTextFromContent } from "@knowt/editor/helpers/getPlainTextFromContent";
import br from "@/styles/breakpoints.module.css";
import { AuthEvent } from "@/features/Auth";
import { getOppositeFlashcardSide } from "@knowt/syncing/hooks/flashcards/utils";

export const FlashcardStarButton = ({ flashcard }: { flashcard: Flashcard }) => {
    // TODO: make sure this isn't calling the backend N times
    const { isStarred, toggleFlashcardStarByIds } = useFlashcardSetViewer({
        flashcardSetId: flashcard?.flashcardSetId,
    });

    const isStar = isStarred({ flashcardId: flashcard?.flashcardId });

    if (!flashcard) return null;

    return (
        <CircularOutlineIcon
            tabIndex={-1}
            size={iconSizes.SM}
            onClick={() => toggleFlashcardStarByIds([flashcard.flashcardId])}
            Icon={Star}
            fill={themeColors.icon2}
            fillWhenActive
            isActive={isStar}
            tooltip={isStar ? "unstar this flashcard" : "star this flashcard"}
        />
    );
};

export const FlashcardPenButton = ({
    flashcard,
    onOpen,
    className,
}: {
    flashcard?: Flashcard;
    onOpen?: (open: boolean) => void;
    className?: string;
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { updateFlashcard } = useFlashcardSet({ flashcardSetId: flashcard?.flashcardSetId });

    if (!flashcard) return null;

    return (
        <>
            <CircularOutlineIcon
                tabIndex={-1}
                className={className}
                size={iconSizes.SM_S}
                onClick={() => {
                    onOpen?.(true);
                    setIsOpen(true);
                }}
                Icon={Pen}
                tooltip={"edit this flashcard"}
            />
            <LazyLoaded load={isOpen}>
                <EditableFlashcardPopup
                    isOpen={isOpen}
                    onClose={() => {
                        onOpen?.(false);
                        setIsOpen(false);
                    }}
                    flashcard={flashcard}
                    updateFlashcard={updateFlashcard}
                />
            </LazyLoaded>
        </>
    );
};

export const FlashcardAudioButton = ({
    flashcard,
    side,
    onClick,
    className,
}: {
    flashcard?: Flashcard;
    side: FlashcardSide;
    onClick?: (e) => void;
    className?: string;
}) => {
    const { checkAuth } = useCheckAuth();

    const handlePlayAudio = useTextToSpeechContextSelector(state => state.handlePlayAudio);
    const loadingAudio = useTextToSpeechContextSelector(state => state.loadingAudio);
    const playingAudio = useTextToSpeechContextSelector(state => state.playingAudio);

    if (!flashcard) return null;

    return (
        <CircularOutlineIcon
            tabIndex={-1}
            className={className}
            size={iconSizes.SM}
            isActive={playingAudio.flashcardId === flashcard?.flashcardId}
            isLoading={loadingAudio.flashcardId === flashcard?.flashcardId}
            onClick={async e => {
                preventStopPropogation(e);
                checkAuth(
                    async () => {
                        await handlePlayAudio(side, { flashcard });
                        onClick?.(e);
                    },
                    {
                        event: AuthEvent.PLAY_AUDIO,
                    }
                );
            }}
            Icon={Volume2}
            tooltip={"read this flashcard out loud"}
        />
    );
};

export const ProgressPill = ({ progress }: { progress: StudySessionProgressEnum }) => {
    const { backgroundColor, label, color, progressBg, progressValue } = getProgressPillProps(progress);

    return (
        <Circular
            radius="auto"
            style={{
                color,
                backgroundColor,
                width: "15rem",
                height: "3.8rem",
                gap: "0.8rem",
                justifyContent: "flex-start",
                padding: "0 0 0 1.5rem",
                fontSize: "1.5rem",
            }}>
            <CircularProgress value={progressValue} bgColor={progressBg} color={color} size={"2rem"} thickness={8} />
            {label}
        </Circular>
    );
};

export const FlashcardHintButton = ({ flashcard, question, onClick }) => {
    const { answerSide } = question;

    const questionSide = getOppositeFlashcardSide(answerSide);

    const rawAnswer = flashcard?.[answerSide.toLowerCase()];
    const rawQuestion = flashcard?.[questionSide.toLowerCase()];

    const answer = useMemo(() => getPlainTextFromContent({ content: rawAnswer, type: "flashcard" }), [rawAnswer]);

    const questionText = useMemo(
        () => getPlainTextFromContent({ content: rawQuestion, type: "flashcard" }),
        [rawQuestion]
    );

    return (
        <>
            <div className={br.smDownDisplayNone}>
                <AnswerHintBtn text="get a hint" answer={answer} question={questionText} onClick={onClick} />
            </div>
            <div className={br.smUpDisplayNone}>
                <AnswerHintBtn text="hint" answer={answer} question={questionText} onClick={onClick} />
            </div>
        </>
    );
};
