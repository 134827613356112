import dayjs from "dayjs";
import BorderedInputDatePicker from "@/components/DatePicker/styled/BorderedInputDatePicker";

export const STUDY_DATE_PICKER_ID = "study-date-picker";

const StudyDatePicker = ({ date, onChange }: { date?: string; onChange: (date: string) => void }) => {
    return (
        <BorderedInputDatePicker
            value={date ? dayjs.unix(Number(date)).toDate() : null}
            DatePickerProps={{
                minDate: dayjs(),
            }}
            onChange={date => {
                if (date) {
                    onChange(date.unix().toString());
                }
            }}
            id={STUDY_DATE_PICKER_ID}
            placeholder="Pick your exam date"
        />
    );
};

export default StudyDatePicker;
