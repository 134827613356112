import React, { useState } from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import { Folder } from "@knowt/syncing/graphql/schema";
import noop from "@knowt/syncing/utils/noop";
import LazyLoaded from "@/components/LazyLoaded";
import dynamic from "next/dynamic";

const MoveToPopup = dynamic(() => import("../MoveToPopup"));

type MoveToFolderContextProps = {
    isOpen: boolean;
    onMove: ({ classId, folderId }: { classId: string | null; folderId: string | null }) => Promise<void>;
    selectedFolders: { [folderId: string]: Folder };
};

type MoveToPopupContextValue = {
    openMoveToPopup: (props: Pick<MoveToFolderContextProps, "onMove" | "selectedFolders">) => void;
};

const MoveToPopupContext = createContext<MoveToPopupContextValue>({ openMoveToPopup: () => null });

const DEFAULT_STATE: MoveToFolderContextProps = {
    isOpen: false,
    onMove: async () => noop(),
    selectedFolders: {},
};

export const MoveToPopupProvider = ({ children }: { children: React.ReactNode }) => {
    const [states, setStates] = useState<MoveToFolderContextProps>({ ...DEFAULT_STATE });

    const openMoveToPopup = ({
        onMove,
        selectedFolders,
    }: Pick<MoveToFolderContextProps, "onMove" | "selectedFolders">) => {
        setStates({ isOpen: true, onMove, selectedFolders });
    };

    const closeMoveToPopup = () => {
        setStates({ ...DEFAULT_STATE });
    };

    return (
        <MoveToPopupContext.Provider value={{ openMoveToPopup }}>
            <LazyLoaded load={states.isOpen}>
                <MoveToPopup
                    isOpen={states.isOpen}
                    onClose={() => closeMoveToPopup()}
                    onMove={async ({ classId, folderId }) => {
                        await states.onMove({ classId, folderId });
                        closeMoveToPopup();
                    }}
                    selectedFolders={states.selectedFolders}
                />
            </LazyLoaded>
            {children}
        </MoveToPopupContext.Provider>
    );
};

export const useMoveToPopupContextSelector = <T,>(selector: (state: MoveToPopupContextValue) => T) =>
    useContextSelector(MoveToPopupContext, selector);
