import { StudySessionProgressEnum } from "@knowt/syncing/graphql/schema";
import Skeleton from "@mui/material/Skeleton";
import Circular from "@/components/Circular";
import CircularProgress from "@/components/CircularProgress";
import { getProgressPillProps } from "@/utils/progressPill";

export const studyProgressTooltips: { [key in StudySessionProgressEnum]: string } = {
    [StudySessionProgressEnum.NEW]: "flashcards you haven't started studying yet",
    [StudySessionProgressEnum.LEARNING]: "flashcards you still need to practice",
    [StudySessionProgressEnum.REVIEWING]: "flashcards you've almost mastered",
    [StudySessionProgressEnum.MASTERED]: "flashcards you're a pro at",
};

const ProgressPill = ({ progress, style }: { progress?: StudySessionProgressEnum; style?: React.CSSProperties }) => {
    if (!progress)
        return (
            <Skeleton
                animation="wave"
                variant="rounded"
                width={"16rem"}
                height={"4rem"}
                style={{
                    borderRadius: 999,
                }}
            />
        );

    const { backgroundColor, label, color, progressBg, progressValue } = getProgressPillProps(progress);

    return (
        <Circular
            radius="auto"
            style={{
                width: "16rem",
                minWidth: "16rem",
                height: "4rem",
                color,
                backgroundColor,
                gap: "0.8rem",
                justifyContent: "flex-start",
                padding: "0 0 0 1.5rem",
                fontSize: "1.5rem",
                ...style,
                transition: "all 0.2s ease-in-out",
            }}
            tooltip={studyProgressTooltips[progress]}>
            <CircularProgress
                value={progressValue}
                bgColor={progressBg}
                color={color}
                size={"2rem"}
                thickness={8}
                title={studyProgressTooltips[progress]}
            />
            {label}
        </Circular>
    );
};

export default ProgressPill;
