"use client";

import { useBookmark } from "@knowt/syncing/hooks/bookmarks/useBookmarks";
import { useFlashcardSet } from "@knowt/syncing/hooks/flashcards/useFlashcards";
import {
    deleteFlashcardSets,
    duplicateFlashcardSet,
    moveFlashcardSets,
    restoreFlashcardSets,
    trashFlashcardSets,
} from "@knowt/syncing/hooks/flashcards/utils";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { FlashcardSet, ItemType } from "@knowt/syncing/graphql/schema";
import { renameKey, retry, wait } from "@knowt/syncing/utils/genericUtils";
import Portal from "@mui/base/Portal";
import {
    Bookmark,
    BookmarkMinus,
    Copy,
    Download,
    ExternalLink,
    FolderInput,
    Link,
    Lock,
    Merge,
    Pen,
    PencilLine,
    Pin,
    PinOff,
    Printer,
    Tag,
} from "lucide-react";
import { useRouter } from "next13-progressbar";
import dynamic from "next/dynamic";
import { useParams, usePathname } from "next/navigation";
import React, { memo, useCallback, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { ThreeDotsBtn } from "./UserContentCardHandlerButtons";
import { FlexRowAlignCenter } from "@/components/Flex";
import LazyLoaded from "@/components/LazyLoaded";
import { useTaggingContextSelector } from "@/features/Tagging";
import WithOptionsMenu, { WithMenuOption } from "@/components/WithOptionsMenu";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { useCheckAuth } from "@/hooks/useCheckAuth";
import useConfirmDialog from "@/hooks/useConfirmDialog";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { copyToClipboard } from "@/utils/clipboard";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { isOnHome } from "@/utils/pathUtils";
import { SxProps } from "@mui/material/index";
import FlashcardsToPrint from "@/components/toPrint/FlashcardsToPrint";
import { FlashcardsToPrintHandle } from "@/components/toPrint/FlashcardsToPrint/FlashcardsToPrint";
import { useNote } from "@knowt/syncing/hooks/notes/useNotes";
import { getNoteUrl } from "@/utils/url";
import { AuthEvent } from "@/features/Auth";
import { themeColors } from "@/utils/themeColors";
import { useMoveToPopupContextSelector } from "@/features/MoveToPopup";
import { useClassSharingPopupContextSelector } from "@/features/ShareSettings";

const ExportFlashcardsPopup = dynamic(() => import("@/components/Popup/ExportFlashcardsPopup"));
const FlashcardSetNamingPopup = dynamic(() => import("@/components/Popup/FlashcardSetNamingPopup"));

type FlashcardSetOptionsMenuProps = {
    flashcardSet: FlashcardSet;
    parentRef: React.MutableRefObject<HTMLDivElement> | null;
    btnSx?: SxProps;
};

/**
 * Opinionated menu for flashcard card
 * NOTE: this is very similar to `./NoteThreeDotsMenuHandler` but the behavior (the hook inside) is different.
 * so we separate the component for each
 */
const FlashcardSetOptionsMenu = ({
    flashcardSet: initialFlashcardSet,
    parentRef,
    btnSx,
}: FlashcardSetOptionsMenuProps) => {
    const router = useRouter();
    const pathname = usePathname();
    const { folderId } = useParams<{ folderId: string }>();

    const { userId, user } = useCurrentUser();
    const { checkAuth } = useCheckAuth();
    const { openConfirmDialog } = useConfirmDialog();
    const { smDown } = useBreakPoints();

    const [shouldHookLoad, setShouldHookLoad] = useState(false);

    const flashCardToPrintRef = useRef<FlashcardsToPrintHandle>(null);

    const { updateFlashcardSetMetaData, flashcardSet: reactiveFlashcardSet } = useFlashcardSet({
        flashcardSetId: initialFlashcardSet.flashcardSetId,
        isEnabled: shouldHookLoad,
    });

    const flashcardSet = reactiveFlashcardSet ?? initialFlashcardSet;
    const { flashcardSetId, noteId } = flashcardSet;
    const { note, update: updateNote } = useNote({ noteId: noteId });

    const draft = flashcardSet.draft;
    const flashcardRoute = draft ? `/flashcards/${flashcardSetId}/edit` : `/flashcards/${flashcardSetId}`;

    const { bookmarked, toggleBookmarked } = useBookmark({
        itemId: flashcardSetId,
        type: ItemType.FLASHCARDSET,
        classId: flashcardSet.classId,
        itemUserId: flashcardSet.userId,
    });

    const isPublic = flashcardSet.public;
    const readOnly = !userId || !flashcardSet.userId || flashcardSet.userId !== userId;
    const isPasswordProtected = flashcardSet.password;
    const isClassItem = !!flashcardSet?.classId;

    const setPublicAndGetLink = useCallback(
        async (newIsPublic: boolean) => {
            try {
                await updateFlashcardSetMetaData({ public: newIsPublic });
                if (newIsPublic) {
                    copyToClipboard(encodeURI(window.location.origin) + flashcardRoute);
                }
                toast.success(
                    newIsPublic ? "Flashcard link successfully copied!" : "Flashcard sharing settings updated."
                );
                Mixpanel.track("Flashcard Set - Shared", {
                    flashcardSetId,
                    public: newIsPublic,
                });
            } catch (error) {
                toast.error("Failed updating the Flashcard");
            }
        },
        [flashcardRoute, flashcardSetId, updateFlashcardSetMetaData]
    );

    const openTaggingPopup = useTaggingContextSelector(state => state.openTaggingPopup);
    const closeTaggingPopup = useTaggingContextSelector(state => state.closeTaggingPopup);

    const openClassSharingPopup = useClassSharingPopupContextSelector(state => state.openClassSharingPopup);

    const [isExportPopupOpen, setIsExportPopupOpen] = useState(false);
    const [isRenamePopupOpen, setIsRenamePopupOpen] = useState(false);

    const openMoveToPopup = useMoveToPopupContextSelector(state => state?.openMoveToPopup);

    const deletePermanently = () => {
        openConfirmDialog({
            title: "Are you sure you want to delete?",
            subtitle: "This flashcard will not be able to be recovered if you continue to delete it.",
            style: { borderRadius: "4rem" },
            rounded: true,
            cardSize: smDown ? "31rem" : "58rem",
            spacing: 2.2,
            subtitleFontSize: "1.9rem",
            subtitleFontWeight: "500",
            buttonColor: "black",
            simpleCancelBtn: true,
            onConfirm: async () => {
                toast.success("Flashcard set deleted permanently");
                router.push(noteId ? getNoteUrl({ noteId, title: note?.title as string }) : "/");

                await wait(1000);

                await deleteFlashcardSets({ flashcardSetIds: [flashcardSetId], userId });
                if (noteId) {
                    await updateNote({ flashcardSetId: null });
                }
            },
        });
    };

    const SavedIcon = readOnly ? BookmarkMinus : PinOff;
    const UnsavedIcon = readOnly ? Bookmark : Pin;

    const saveText = bookmarked ? (readOnly ? "Unsave" : "Unpin") : readOnly ? "Save" : "Pin";

    const saveOption: WithMenuOption = {
        label: saveText,
        onClick: async () => {
            checkAuth(async () => await toggleBookmarked(), {
                event: AuthEvent.BOOKMARK_ITEM,
            });
        },
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                {bookmarked ? (
                    <SavedIcon strokeWidth={strokeWidth.normal} size={iconSizes.SM} />
                ) : (
                    <UnsavedIcon strokeWidth={strokeWidth.normal} size={iconSizes.SM} />
                )}
                {saveText}
            </FlexRowAlignCenter>
        ),
    };

    const printFlashcardSet = () => {
        flashCardToPrintRef.current?.print();
    };

    const retryPrintingForSomeTime = async () => {
        const toastId = toast.loading("Loading flashcards...");

        try {
            await retry(async () => printFlashcardSet(), 10, 1_000);
            toast.dismiss(toastId);
        } catch {
            toast.error("Oops, seems like the set is too long. Try printing it from the details page", {
                id: toastId,
            });
        }
    };

    const printOption = {
        label: "Export as PDF",
        onClick: async () => {
            try {
                printFlashcardSet();
            } catch (error) {
                retryPrintingForSomeTime();
            }
        },
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <Printer strokeWidth={strokeWidth.normal} size={iconSizes.SM} />
                {"Export as PDF"}
            </FlexRowAlignCenter>
        ),
    };

    const renameOption: WithMenuOption = {
        label: "Rename",
        onClick: async () => setIsRenamePopupOpen(true),
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <PencilLine strokeWidth={strokeWidth.normal} size={iconSizes.SM} /> Rename
            </FlexRowAlignCenter>
        ),
        hide: readOnly,
    };

    const manageSharingOption: WithMenuOption = {
        label: "Manage sharing",
        onClick: () => openClassSharingPopup({ fileId: flashcardSetId, fileType: ItemType.FLASHCARDSET }),
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <Lock strokeWidth={strokeWidth.normal} size={iconSizes.SM} /> Manage sharing
            </FlexRowAlignCenter>
        ),
    };

    const moveToOption: WithMenuOption = {
        label: "Move to",
        onClick: async () => {
            openMoveToPopup({
                onMove: async ({ classId: destinationClassId, folderId: destinationFolderId }) => {
                    moveFlashcardSets({
                        flashcardSetIds: [flashcardSetId],
                        userId,
                        sourceFolderId: folderId || null,
                        destinationFolderId,
                        sourceClassId: flashcardSet.classId || null,
                        destinationClassId,
                    });
                },
                selectedFolders: {},
            });
        },
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <FolderInput strokeWidth={strokeWidth.normal} size={iconSizes.SM} /> Move to
            </FlexRowAlignCenter>
        ),
        hide: readOnly || typeof openMoveToPopup !== "function",
    };

    const getLinkOption: WithMenuOption = {
        label: isPublic ? "Unshare" : "Get link",
        onClick: () => {
            if (!readOnly && !isPasswordProtected) {
                setPublicAndGetLink(!isPublic);
            } else {
                copyToClipboard(encodeURI(window.location.origin + flashcardRoute));
            }
        },
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <Link strokeWidth={strokeWidth.normal} size={iconSizes.SM} />{" "}
                {isPublic && !readOnly ? "Unshare" : "Get link"}
            </FlexRowAlignCenter>
        ),
    };

    const openInNewTabOption: WithMenuOption = {
        label: "Open in new tab",
        onClick: () => window.open(window.location.origin + flashcardRoute, "_blank"),
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <ExternalLink strokeWidth={strokeWidth.normal} size={iconSizes.SM} /> Open in new tab
            </FlexRowAlignCenter>
        ),
    };

    const editSetOption: WithMenuOption = {
        label: "Edit this set",
        onClick: () => router.push(`/flashcards/${flashcardSetId}/edit`),
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <Pen strokeWidth={strokeWidth.normal} size={iconSizes.SM} /> Edit this set
            </FlexRowAlignCenter>
        ),
        hide: readOnly,
    };

    const duplicateOption: WithMenuOption = {
        label: "Duplicate",
        onClick: () =>
            checkAuth(
                async () => {
                    const newFlashcardSet = await toast.promise(duplicateFlashcardSet(flashcardSetId, user), {
                        loading: "Copying to your notebook",
                        success: "Flashcard set duplicated successfully!",
                        error: "Failed to duplicate the Flashcard set",
                    });

                    if (!isOnHome(pathname)) {
                        router.push(`/flashcards/${newFlashcardSet.flashcardSetId}/edit`);
                    }
                },
                {
                    event: AuthEvent.DUPLICATE_FLASHCARDS,
                }
            ),
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <Copy strokeWidth={strokeWidth.normal} size={iconSizes.SM} /> Duplicate
            </FlexRowAlignCenter>
        ),
    };

    const editTagsOption: WithMenuOption = {
        label: "Edit tags",
        onClick: () =>
            openTaggingPopup({
                flashcardSet: flashcardSet as FlashcardSet,
                onSave: async newTags => {
                    renameKey(newTags, "exam", "exam_v2");
                    renameKey(newTags, "custom", "tags");
                    await updateFlashcardSetMetaData(newTags);

                    closeTaggingPopup();
                },
            }),
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <Tag strokeWidth={strokeWidth.normal} size={iconSizes.SM} /> Edit tags
            </FlexRowAlignCenter>
        ),
        hide: readOnly,
    };

    const mergeSetOption: WithMenuOption = {
        label: "Combine",
        onClick: () => {
            const route = `/flashcards/merge?flashcardSetId=${flashcardSetId}${
                folderId ? `&folderId=${folderId}` : ""
            }`;

            checkAuth(() => router.push(route), {
                event: AuthEvent.COMBINE_FLASHCARDS,
            });
        },
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <Merge strokeWidth={strokeWidth.normal} size={iconSizes.SM} /> Combine
            </FlexRowAlignCenter>
        ),
    };

    const trashOption: WithMenuOption = {
        label: flashcardSet?.classId ? "Remove from class" : "Trash",
        onClick: async () => {
            if (flashcardSet?.classId) {
                toast.loading("Removing flashcard set from class...", { id: "DELETE_FLASHCARD_SET" });
                await moveFlashcardSets({
                    flashcardSetIds: [flashcardSetId],
                    userId,
                    sourceFolderId: flashcardSet.folderId ?? null,
                    destinationFolderId: null,
                    sourceClassId: flashcardSet.classId,
                    destinationClassId: null,
                });
                toast.success("Flashcard set removed from class", { id: "DELETE_FLASHCARD_SET" });
            } else {
                await trashFlashcardSets({
                    flashcardSetIds: [flashcardSetId],
                    userId,
                    sourceFolderId: flashcardSet.folderId ?? null,
                });
                toast.success("Flashcard set deleted");
            }
        },
        hide: readOnly,
        menuItemProps: {
            sx: {
                borderTop: `2px solid ${themeColors.neutral1}`,
                height: "4.7rem",
                color: themeColors.errorPrimary,
                fontWeight: "600",
                fontSize: "1.5rem",
                marginBottom: "-8px",
            },
        },
    };

    const restoreOption: WithMenuOption = {
        label: "Restore",
        onClick: async () => {
            await restoreFlashcardSets({
                flashcardSetIds: [flashcardSetId],
                userId,
                sourceFolderId: flashcardSet.folderId ?? null,
            });
            toast.success("Flashcard set restored");
        },
        hide: readOnly,
    };

    const deletePermanentOption: WithMenuOption = {
        label: "Delete",
        onClick: deletePermanently,
        hide: readOnly,
    };

    const exportOption: WithMenuOption = {
        label: "Export",
        node: (
            <FlexRowAlignCenter style={{ gap: "0.9rem" }}>
                <Download strokeWidth={strokeWidth.normal} size={iconSizes.SM} /> Export
            </FlexRowAlignCenter>
        ),
        onClick: () => setIsExportPopupOpen(true),
    };

    const options: WithMenuOption[] = flashcardSet.trash
        ? [restoreOption, deletePermanentOption]
        : isClassItem
        ? [
              duplicateOption,
              moveToOption,
              renameOption,
              manageSharingOption,
              getLinkOption,
              openInNewTabOption,
              editTagsOption,
              trashOption,
          ]
        : [
              saveOption,
              duplicateOption,
              editSetOption,
              printOption,
              moveToOption,
              renameOption,
              getLinkOption,
              openInNewTabOption,
              editTagsOption,
              mergeSetOption,
              exportOption,
              trashOption,
          ];

    return (
        <>
            {/* THREE DOTS */}
            <WithOptionsMenu
                options={options}
                menuItemProps={{
                    sx: { padding: "1rem 1.6rem" },
                }}
                menuProps={{
                    PaperProps: {
                        sx: {
                            borderRadius: "2rem",
                            width: "20.6rem",
                            fontSize: "1.4rem",
                            margin: "-0.5rem 0 0 -1.25rem",
                        },
                    },
                    transformOrigin: { vertical: "top", horizontal: "left" },
                }}>
                {({ openMenu }) => (
                    <ThreeDotsBtn
                        onPointerEnter={() => setShouldHookLoad(true)}
                        onClick={openMenu}
                        onRightClick={e => {
                            setShouldHookLoad(true);
                            openMenu(e);
                        }}
                        cardContainerRef={parentRef}
                        sx={{ marginTop: "-0.35rem", ...btnSx }}
                    />
                )}
            </WithOptionsMenu>

            <Portal>
                <LazyLoaded load={isRenamePopupOpen}>
                    {/* disable propagation since we don't want to trigger note anchor href when inside the popup */}
                    <div onClick={e => e.stopPropagation()}>
                        <FlashcardSetNamingPopup
                            defaultName={flashcardSet.title as string}
                            isOpen={isRenamePopupOpen}
                            onClose={() => setIsRenamePopupOpen(false)}
                            onConfirm={async newFlashcardSetTitle => {
                                await updateFlashcardSetMetaData({ title: newFlashcardSetTitle });
                                toast.success("Flashcard set renamed");
                            }}
                        />
                    </div>
                </LazyLoaded>

                <LazyLoaded load={isExportPopupOpen}>
                    <div onClick={e => e.stopPropagation()}>
                        <ExportFlashcardsPopup
                            isOpen={isExportPopupOpen}
                            onClose={() => setIsExportPopupOpen(false)}
                            flashcardSetId={flashcardSetId}
                        />
                    </div>
                </LazyLoaded>
            </Portal>

            <div style={{ display: "none" }}>
                <FlashcardsToPrint flashcardSet={flashcardSet} ref={flashCardToPrintRef} />
            </div>
        </>
    );
};

export default memo(FlashcardSetOptionsMenu);
