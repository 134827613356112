import {
    FlashcardSet,
    FlashcardStudyState,
    StudySession,
    StudySessionProgress,
    StudySessionProgressEnum,
    StudySessionType,
} from "@/graphql/schema";
import { DEFAULT_STUDY_PROGRESS } from "./constants";

const values: { [key in StudySessionProgressEnum]: number } = {
    [StudySessionProgressEnum.NEW]: 0,
    [StudySessionProgressEnum.LEARNING]: 20,
    [StudySessionProgressEnum.REVIEWING]: 60,
    [StudySessionProgressEnum.MASTERED]: 100,
};

export const getProgressValue = (progress: StudySessionProgress): number => {
    const total = Object.values(progress).reduce((acc, curr) => acc + curr, 0);

    const keys = Object.keys(progress);
    return keys.reduce((acc, curr: StudySessionProgressEnum) => {
        const value = values[curr];
        return acc + (value * progress[curr]) / total;
    }, 0);
};

export const getMasteredPercentage = (stats?: Record<StudySessionProgressEnum, number>) => {
    if (!stats) return 0;
    const totalTypes = Object.values(stats).reduce((acc, val) => acc + val, 0);
    const masteredProgress = totalTypes > 0 ? (stats.MASTERED / totalTypes) * 100 : 0;
    return masteredProgress.toFixed(0);
};

export const getProgressLabel = (progress: StudySessionProgressEnum): string => {
    switch (progress) {
        case StudySessionProgressEnum.NEW:
            return "not started yet";
        case StudySessionProgressEnum.LEARNING:
            return "still learning";
        case StudySessionProgressEnum.REVIEWING:
            return "almost done";
        case StudySessionProgressEnum.MASTERED:
            return "complete";
    }
};

export const getProgressBucket = (progressPercentage: number): StudySessionProgressEnum => {
    return progressPercentage > 90
        ? StudySessionProgressEnum.MASTERED
        : progressPercentage > 60
        ? StudySessionProgressEnum.REVIEWING
        : progressPercentage > 20
        ? StudySessionProgressEnum.LEARNING
        : StudySessionProgressEnum.NEW;
};

export type FlashcardStats = Record<string, Record<StudySessionProgressEnum, number>>;

export const calculateFlashcardStats = (dataObject: FlashcardsStudyAnalytics) => {
    return Object.values(dataObject).reduce((acc, userObj) => {
        Object.entries(userObj).forEach(([flashcardId, { progress }]) => {
            if (!acc[flashcardId]) {
                acc[flashcardId] = DEFAULT_STUDY_PROGRESS;
            }
            acc[flashcardId][progress]++;
        });
        return acc;
    }, {} as FlashcardStats);
};

export type StudySessionCompletion = "STARTED" | "CONTINUED" | "COMPLETED";

export const getSessionCompletion = (progress?: StudySessionProgress): StudySessionCompletion => {
    if (!progress) return "STARTED";
    if (progress.NEW === 0 && progress.LEARNING === 0 && progress.REVIEWING === 0) {
        return "COMPLETED";
    } else if (progress.MASTERED === 0 && progress.LEARNING === 0 && progress.REVIEWING === 0) {
        return "STARTED";
    }
    return "CONTINUED";
};

export type UserStudyAnalytics = {
    lastActive: number;
    timeSpent: number;
    cardsSeen: number;
    progressPercentage: number;
    statsPerMode: Record<
        StudySessionType,
        {
            timeSpent: number;
            lastActive: number;
            cardsSeen: number;
        }
    >;
};

const getMostRecentStudySessionPerUser = ({
    studySessions,
    userIds,
}: {
    studySessions: StudySession[];
    userIds: string[];
}) =>
    studySessions
        ?.filter(({ userId }) => userIds.includes(userId))
        .reduce((acc, curr) => {
            acc[curr.userId] ||= curr;
            if (acc[curr.userId].created < curr.created) acc[curr.userId] = curr;
            return acc;
        }, {} as Record<string, StudySession>);

const getStudyProgressPerUser = ({ studySessions, userIds }: { studySessions: StudySession[]; userIds: string[] }) =>
    Object.entries(getMostRecentStudySessionPerUser({ studySessions, userIds })).map(
        ([userId, studySession]) => [userId, getProgressValue(studySession.progress)] as const
    );

export const getUsersStudyAnalytics = ({
    studySessions,
    flashcardSetId,
    flashcardStudyStates,
    userIds,
}: {
    studySessions: StudySession[] | null | undefined;
    flashcardSetId: string | null | undefined;
    flashcardStudyStates: FlashcardStudyState[] | null | undefined;
    userIds?: string[];
}): Record<string, UserStudyAnalytics> | undefined => {
    if (!flashcardSetId) {
        return null;
    }

    if (!studySessions || !flashcardStudyStates || !userIds) return undefined;

    const result: Record<string, UserStudyAnalytics> = {};
    userIds.forEach(userId => (result[userId] = {} as UserStudyAnalytics));

    getStudyProgressPerUser({ studySessions, userIds }).forEach(([userId, progressPercentage]) => {
        result[userId].progressPercentage = progressPercentage;
    });

    flashcardStudyStates
        ?.filter(({ userId }) => userIds.includes(userId))
        .forEach(flashcardStudyState => {
            const userId = flashcardStudyState.userId;
            result[userId] ||= {} as UserStudyAnalytics;

            result[userId].statsPerMode ||= {} as UserStudyAnalytics["statsPerMode"];
            for (const mode of Object.values(StudySessionType)) {
                result[flashcardStudyState.userId].statsPerMode[mode] ||=
                    {} as UserStudyAnalytics["statsPerMode"][typeof mode];
            }

            flashcardStudyState.history.forEach(({ mode, timestamp, timeTaken }) => {
                // time spent per mode
                result[userId].statsPerMode[mode].timeSpent ||= 0;
                result[userId].statsPerMode[mode].timeSpent += timeTaken;

                // time spent overall
                result[userId].timeSpent ||= 0;
                result[userId].timeSpent += timeTaken;

                // last active per mode
                result[userId].statsPerMode[mode].lastActive ||= timestamp;
                if (result[userId].statsPerMode[mode].lastActive < timestamp) {
                    result[userId].statsPerMode[mode].lastActive = timestamp;
                }

                // last active overall
                result[userId].lastActive ||= timestamp;
                if (result[userId].lastActive < timestamp) {
                    result[userId].lastActive = timestamp;
                }
            });

            // cards seen
            result[userId].cardsSeen ||= 0;
            if (flashcardStudyState.history.length > 0) {
                result[userId].cardsSeen += 1;
            }

            // cards seen per mode
            for (const mode of Object.values(StudySessionType)) {
                result[userId].statsPerMode[mode].cardsSeen ||= 0;
                if (flashcardStudyState.history.some(({ mode: _mode }) => _mode === mode)) {
                    result[userId].statsPerMode[mode].cardsSeen += 1;
                }
            }
        });

    return result;
};

type UsersStudyAnalyticsPerFlashcard = Record<
    string,
    {
        progress: StudySessionProgressEnum;
        timeSpent: number;
    }
>;

export const getOverallFlashcardSetCompletion = ({
    studySessions,
}: {
    studySessions: StudySession[] | null | undefined;
}): Record<StudySessionProgressEnum, number> => {
    if (!studySessions) return null;

    if (!studySessions.length) {
        return DEFAULT_STUDY_PROGRESS;
    }

    return studySessions.reduce(
        (acc, curr) => {
            const completion = getProgressBucket(getProgressValue(curr.progress));
            acc[completion]++;
            return acc;
        },
        { ...DEFAULT_STUDY_PROGRESS }
    );
};

export type FlashcardsStudyAnalytics = Record<string, UsersStudyAnalyticsPerFlashcard>;

export const getFlashcardsStudyAnalytics = ({
    flashcardStudyStates,
    flashcardSet,
    userIds,
}: {
    flashcardStudyStates: FlashcardStudyState[] | null | undefined;
    flashcardSet: FlashcardSet | null | undefined;
    userIds?: string[];
}): FlashcardsStudyAnalytics => {
    const fillEmptyFlashcardIdsAndUsers = (retObj: FlashcardsStudyAnalytics) => {
        if (!flashcardSet) return retObj;

        const DEFAULT_USER_STATS = {
            progress: StudySessionProgressEnum.NEW,
            timeSpent: 0,
        } as UsersStudyAnalyticsPerFlashcard[string];

        flashcardSet.flashcards.forEach(flashcard => {
            // Fill empty flashcardIds (flashcards that haven't been started on by any user)
            if (!retObj[flashcard.flashcardId]) {
                retObj[flashcard.flashcardId] = {};
            }

            // Fill empty user stats (students that haven't started on a specific flashcard)
            if (Object.keys(retObj[flashcard.flashcardId]).length !== (userIds?.length || 0)) {
                userIds?.forEach(userId => {
                    if (!retObj[flashcard.flashcardId][userId]) {
                        retObj[flashcard.flashcardId][userId] = { ...DEFAULT_USER_STATS };
                    }
                });
            }
        });

        return retObj;
    };

    if (!flashcardStudyStates) return fillEmptyFlashcardIdsAndUsers({});

    const result: FlashcardsStudyAnalytics = {};

    flashcardStudyStates
        ?.filter(({ userId }) => !userIds || userIds.includes(userId))
        .forEach(({ flashcardId, userId, history, progress }) => {
            result[flashcardId] ||= {} as UsersStudyAnalyticsPerFlashcard;
            result[flashcardId][userId] ||= {} as UsersStudyAnalyticsPerFlashcard[string];

            result[flashcardId][userId].timeSpent = history.reduce((acc, curr) => acc + curr.timeTaken, 0);
            result[flashcardId][userId].progress = progress;
        });

    return fillEmptyFlashcardIdsAndUsers(result);
};

export type FlashcardsMastery = Record<string, Record<StudySessionProgressEnum, number>>;

export const getFlashcardsMastery = ({
    flashcardsStudyAnalytics,
}: {
    flashcardsStudyAnalytics: FlashcardsStudyAnalytics;
}) => {
    return Object.entries(flashcardsStudyAnalytics).reduce((acc, [flashcardId, userStats]) => {
        const progress: StudySessionProgressEnum[] = Object.values(userStats).map(({ progress }) => progress);
        return {
            ...acc,
            [flashcardId]: progress.reduce(
                (acc, curr) => {
                    acc[curr]++;
                    return acc;
                },
                { ...DEFAULT_STUDY_PROGRESS }
            ),
        };
    }, {} as FlashcardsMastery);
};
