export const LOCAL_AUDIO_SETTINGS_KEY = "localAudioSettings";

export const backgroundMusicList = [
    {
        label: "None",
        value: null,
    },
    {
        label: "Slow metal",
        value: "slow_metal",
    },
    {
        label: "Beach breeze",
        value: "beach_breeze",
    },
    {
        label: "City street",
        value: "city_street",
    },
    {
        label: "Coffee shop",
        value: "coffee_shop",
    },
    {
        label: "Far from river",
        value: "far_from_a_river",
    },
    {
        label: "Gladiator fight",
        value: "gladiator_fight",
    },
    {
        label: "Living free",
        value: "living_free",
    },
    {
        label: "Rain forest",
        value: "rain_forest",
    },
    {
        label: "Saturday market",
        value: "saturday_market",
    },
    {
        label: "Spanish guitar",
        value: "spanish_guitar",
    },
    {
        label: "Upstate New Jersey",
        value: "upstate_new_jersey",
    },
    {
        label: "Walk in the park",
        value: "walk_in_park",
    },
    {
        label: "Waterfall",
        value: "water_fall",
    },
];
