import { sample } from "lodash";
import { useState, useCallback, useMemo } from "react";

const phrases = [
    "The answer is hidden within the question itself.",
    "Sometimes, what you seek is right in front of you.",
    "The question and the answer are closer than you think.",
    "Look closely; the question holds your answer.",
];

enum HintType {
    SIMILARITY,
    KEYWORD,
}

const hintTypesSuitableForKeyWord = [HintType.SIMILARITY, HintType.KEYWORD];

const useHints = ({ answer, question }: { answer: string; question: string }) => {
    const [showedLetterHints, setShowedLetterHints] = useState<string[]>([]);
    const [hint, setHint] = useState("");

    const hintsBasedOnLetters = useMemo(() => {
        return [
            `It starts with ${answer[0]}.`,
            `It ends with ${answer[answer.length - 1]}.`,
            `It has ${answer.length} letter${answer.length === 1 ? "" : "s"}.`,
            `It has ${answer.split(" ").length} word${answer.split(" ").length === 1 ? "" : "s"}.`,
        ].filter(hint => !showedLetterHints.includes(hint));
    }, [answer, showedLetterHints]);

    const hintBasedOnLetters = useMemo(
        () => (hintsBasedOnLetters.length ? (sample(hintsBasedOnLetters) as string) : showedLetterHints.slice(-1)[0]),
        [hintsBasedOnLetters, showedLetterHints]
    );

    const generateHint = useCallback(async () => {
        const nlp = (await import("compromise")).default;
        if (!answer) return setHint("Nothing is sometimes the best hint.");

        const isAnswerSameAsQuestion = answer.trim().toLowerCase() === question.trim().toLowerCase();
        if (isAnswerSameAsQuestion) return setHint(sample(phrases) as string);

        const isOneLetterAnswer = answer.trim().length === 1;
        if (isOneLetterAnswer) return setHint("It's a single letter.");

        const isAnswerOneWord = answer.trim().split(" ").length === 1;
        if (isAnswerOneWord) {
            if (!showedLetterHints.includes(hintBasedOnLetters))
                setShowedLetterHints(prev => [...prev, hintBasedOnLetters]);
            return setHint(hintBasedOnLetters);
        }

        const doc = nlp(answer);
        const keyWord = doc.nouns().out("array")[0];

        const isKeywordSameAsAnswer = keyWord?.trim().toLowerCase() === answer.trim().toLowerCase();

        if (isKeywordSameAsAnswer) {
            if (!showedLetterHints.includes(hintBasedOnLetters))
                setShowedLetterHints(prev => [...prev, hintBasedOnLetters]);
            return setHint(hintBasedOnLetters);
        }

        const mainWord = keyWord ?? answer.split(" ")[0];

        const hintGenerators = {
            [HintType.SIMILARITY]: word => `It has something to do with ${word}.`,
            [HintType.KEYWORD]: word => `One key word is: ${word}.`,
        };

        const hintType = sample(hintTypesSuitableForKeyWord);

        const hintGenerator = hintGenerators[hintType as HintType];
        const hint = await hintGenerator(mainWord);
        setHint(hint);
    }, [answer, hintBasedOnLetters, question, showedLetterHints]);

    return { hint, generateHint };
};

export default useHints;
