import { StudySession, StudySessionType } from "@knowt/syncing/graphql/schema";
import { ChevronDown } from "lucide-react";
import { useRouter } from "next13-progressbar";
import Image from "next/image";
import StudyNavbarTextBtn from "./StudyNavbarTextBtn";
import { FlexRowAlignCenter } from "@/components/Flex";
import ActionArrowRight from "@/components/Circular/styled/ActionArrowRight";
import WithOptionsMenu from "@/components/WithOptionsMenu";
import { borderRadius } from "@/utils/borderRadius";
import { iconSizes } from "@/utils/iconProps";
import { ASSETS_URL } from "@/config/deployConstants";

type StudyDropDownOption = {
    type: StudySessionType;
    label: string;
    hrefSuffix: string;
    node: React.ReactNode;
    icon: string;
};

const OptionContainer = ({ label, image }: { label: string; image: React.ReactNode }) => (
    <FlexRowAlignCenter style={{ justifyContent: "space-between", gap: "0.8rem", width: "100%" }}>
        {image} {label}
        <ActionArrowRight style={{ marginLeft: "auto", backgroundColor: "transparent" }} />
    </FlexRowAlignCenter>
);

export const STUDY_DROPDOWN_OPTIONS: {
    [key in StudySessionType]: StudyDropDownOption;
} = {
    [StudySessionType.LEARN]: {
        type: StudySessionType.LEARN,
        label: "Learn Mode",
        hrefSuffix: "/learn",
        icon: `${ASSETS_URL}/images/brain.png`,
        node: (
            <OptionContainer
                label={"Learn Mode"}
                image={<Image src={`${ASSETS_URL}/images/brain.png`} width={24} height={24} alt="Learn Mode" />}
            />
        ),
    },
    [StudySessionType.TEST]: {
        type: StudySessionType.TEST,
        label: "Practice Test",
        hrefSuffix: "/test",
        icon: `${ASSETS_URL}/images/ExamIcon.png`,
        node: (
            <OptionContainer
                label={"Practice Test"}
                image={<Image src={`${ASSETS_URL}/images/ExamIcon.png`} width={24} height={24} alt="Practice Test" />}
            />
        ),
    },
    [StudySessionType.SPACED]: {
        type: StudySessionType.SPACED,
        label: "Spaced Repetition",
        hrefSuffix: "/spaced",
        icon: `${ASSETS_URL}/images/Spaced_Repetition.png`,
        node: (
            <OptionContainer
                label={"Spaced Repetition"}
                image={
                    <Image
                        src={`${ASSETS_URL}/images/Spaced_Repetition.png`}
                        width={24}
                        height={24}
                        alt="Spaced Repetition"
                    />
                }
            />
        ),
    },
    [StudySessionType.MATCH]: {
        type: StudySessionType.MATCH,
        label: "Matching",
        hrefSuffix: "/match",
        icon: `${ASSETS_URL}/images/heart_puzzle.png`,
        node: (
            <OptionContainer
                label={"Matching"}
                image={
                    <Image src={`${ASSETS_URL}/images/heart_puzzle.png`} width={24} height={24} alt="Matching Game" />
                }
            />
        ),
    },
    [StudySessionType.REVIEW]: {
        type: StudySessionType.REVIEW,
        label: "Flashcards",
        hrefSuffix: "/review",
        icon: `${ASSETS_URL}/images/flashcard-icon.svg`,
        node: (
            <OptionContainer
                label={"Flashcards"}
                image={
                    <Image src={`${ASSETS_URL}/images/flashcard-icon.svg`} width={24} height={24} alt="Flashcards" />
                }
            />
        ),
    },
};

const StudyNavbarDropDown = ({
    studySessionType,
    studySession,
}: {
    studySessionType: StudySessionType;
    studySession: StudySession;
}) => {
    const router = useRouter();

    const studyOptionsArr = Object.values(STUDY_DROPDOWN_OPTIONS);

    const options = studyOptionsArr.map(o => ({
        ...o,
        onClick: () => {
            if (STUDY_DROPDOWN_OPTIONS[studySessionType].hrefSuffix === o.hrefSuffix) {
                return;
            }
            router.replace(`/study/flashcards/${studySession.itemId}/${o.hrefSuffix}`);
        },
    }));

    return (
        <WithOptionsMenu
            options={options.filter(option => option.type !== studySessionType)}
            menuItemProps={{
                sx: { fontSize: "1.5rem", fontWeight: "600", padding: "8px 20px" },
            }}
            menuProps={{
                PaperProps: {
                    sx: { borderRadius: borderRadius.smallModal, marginTop: "1rem", width: "35rem" },
                },
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                transformOrigin: { vertical: "top", horizontal: "left" },
            }}>
            {({ openMenu }) => (
                <StudyNavbarTextBtn
                    onClick={(e: React.MouseEvent) => {
                        openMenu(e);
                    }}>
                    {/* SELECTED OPTION  */}
                    <FlexRowAlignCenter style={{ gap: "0.8rem" }}>
                        <Image
                            src={STUDY_DROPDOWN_OPTIONS[studySessionType].icon}
                            width={24}
                            height={24}
                            alt="Practice Test"
                        />
                        {STUDY_DROPDOWN_OPTIONS[studySessionType].label}
                    </FlexRowAlignCenter>
                    <ChevronDown size={iconSizes.SM} fill={"currentColor"} strokeWidth={0} />
                </StudyNavbarTextBtn>
            )}
        </WithOptionsMenu>
    );
};

export default StudyNavbarDropDown;
