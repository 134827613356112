import { StudySession, StudySessionProgressEnum } from "@knowt/syncing/graphql/schema";
import ProgressPill from "./ProgressPill";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";
import toast from "react-hot-toast";
import OutlineTextButton from "@/components/CircularButton/styled/OutlineTextButton";

const StudyingProgressCard = ({
    studySession,
    total: _total,
    description,
    onStudy,
    ...divProps
}: {
    studySession?: StudySession;
    total?: number;
    description?: React.ReactNode;
    onStudy?: (studyFrom: StudySessionProgressEnum) => void;
} & React.HTMLAttributes<HTMLDivElement>) => {
    // we pass total in case there is no study session, in that case everything is "new"

    const total = studySession
        ? Object.values(studySession.progress ?? {}).reduce((acc, curr) => acc + (curr ?? 0), 0)
        : _total ?? 0;

    return (
        <FlexColumn
            {...divProps}
            style={{
                flexWrap: "wrap",
                backgroundColor: themeColors.neutralWhite,
                borderRadius: "2rem",
                padding: "3.2rem 2.5rem",
                ...divProps.style,
            }}
            as={"section"}>
            <span style={{ marginBottom: "2.8rem" }}>
                <h2 style={{ fontSize: "1.8rem", fontWeight: "600" }}>Studying Progress</h2>
                {description && <p style={{ fontSize: "1.5rem", marginTop: "0.6rem" }}>{description}</p>}
            </span>
            <FlexColumn style={{ gap: "2.2rem", width: "100%" }}>
                {[
                    StudySessionProgressEnum.NEW,
                    StudySessionProgressEnum.LEARNING,
                    StudySessionProgressEnum.REVIEWING,
                    StudySessionProgressEnum.MASTERED,
                ].map((studyProgress, index) => {
                    const getValue = (): number => {
                        if (index === 0 && !studySession) {
                            return total as number;
                        } else {
                            return studySession?.progress?.[studyProgress] || 0;
                        }
                    };

                    const getWidth = (): string => {
                        const cardPercentage = getValue() / total;
                        // 16rem is the min-width of the `ProgressPill`
                        // 2.3rem is approx `getValue()` text size
                        // 11rem is the gap + button besides it
                        return `calc(16rem + (100% - 16rem - 2.3rem - 11rem) * ${cardPercentage})`;
                    };

                    return (
                        <FlexRowAlignCenter
                            key={studyProgress}
                            style={{
                                justifyContent: "space-between",
                            }}>
                            <FlexRowAlignCenter
                                style={{
                                    width: getWidth(),
                                    gap: "1.2rem",
                                    maxWidth: "100%",
                                }}>
                                <ProgressPill progress={studyProgress} style={{ width: "100%" }} />
                                <span style={{ fontSize: "1.4rem" }}>{getValue()}</span>
                            </FlexRowAlignCenter>
                            {!!onStudy && (
                                <OutlineTextButton
                                    sx={{
                                        fontSize: "1.4rem",
                                        height: "4rem",
                                        width: "9rem",
                                        backgroundColor: themeColors.neutralWhite,
                                        color: themeColors.neutralBlack,
                                        padding: "2rem",
                                    }}
                                    text={"Study"}
                                    onClick={() => {
                                        if (getValue() === 0) {
                                            return toast.error("You have no cards to study in this bucket.");
                                        }
                                        onStudy(studyProgress);
                                    }}
                                />
                            )}
                        </FlexRowAlignCenter>
                    );
                })}
            </FlexColumn>
        </FlexColumn>
    );
};

export default StudyingProgressCard;
