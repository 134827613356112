import { ServerClientWithCookies } from "@knowt/syncing/utils/SyncUtils";
import { callCreateFlashcardSetViewer, callGetFlashcardSetViewer } from "./graphqlUtils";

export const getOrCreateFlashcardSetViewer = async ({
    userId,
    flashcardSetId,
    serverClient,
}: {
    userId: string;
    flashcardSetId: string;
    serverClient?: ServerClientWithCookies;
}) => {
    return (
        (await callGetFlashcardSetViewer({ userId, flashcardSetId, serverClient })) ||
        (await callCreateFlashcardSetViewer({ userId, flashcardSetId, serverClient }))
    );
};
