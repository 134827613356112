import { ASSETS_URL } from "@/config/deployConstants";
import { ItemType, QuestionType, StudySession } from "@knowt/syncing/graphql/schema";
import { TIME_SECONDS } from "@knowt/syncing/utils/dateTimeUtils";
import { LOCAL_AUDIO_SETTINGS_KEY } from "./constants/localStoragekeys";

export const getStudySessionItemUrl = (studySession: StudySession) => {
    if (studySession.type === ItemType.FLASHCARDSET) {
        return `/flashcards/${studySession.itemId}`;
    } else if (studySession.type === ItemType.NOTE) {
        return `/note/${studySession.itemId}`;
    }
    throw new Error(`studySession.type: ${studySession.type} is not implemented`);
};

// TODO: is the minimum length of question 3?
export const validateLength = (length: number) => {
    if (!length || length < 3) {
        throw new Error("Question length cannot be less than 3");
    }
};

export const validateCardsPerSession = (cardsPerDay: number) => {
    if (!cardsPerDay || cardsPerDay < 1) {
        throw new Error("Cards per day cannot be less than 1");
    }
};

export const validateQuestionTypes = (questionTypes: QuestionType[]) => {
    if (questionTypes.length === 0) {
        throw new Error("At least one question type must be selected");
    }
};

export const validateAnswerSide = ({
    answerWithTerm,
    answerWithDef,
}: {
    answerWithTerm: boolean;
    answerWithDef: boolean;
}) => {
    if (!answerWithTerm && !answerWithDef) {
        throw new Error("At least one answer side must be selected");
    }
};

export const validateIntervals = (intervals: number[]) => {
    if (intervals.length === 0) {
        throw new Error("At least one interval must be selected");
    }

    if (intervals.some(interval => interval < 1)) {
        throw new Error("Interval cannot be less than 1");
    }

    if (intervals.some(interval => interval > TIME_SECONDS.YEAR)) {
        throw new Error("Interval cannot be greater than 1 year");
    }
};

export const getAudioSettings = () => {
    if (!localStorage.getItem(LOCAL_AUDIO_SETTINGS_KEY)) {
        localStorage.setItem(LOCAL_AUDIO_SETTINGS_KEY, JSON.stringify(true));
        return true;
    }

    return JSON.parse(localStorage.getItem(LOCAL_AUDIO_SETTINGS_KEY) || "true");
};

export const changeAudioSettings = (isAudioOn: boolean) => {
    localStorage.setItem(LOCAL_AUDIO_SETTINGS_KEY, JSON.stringify(isAudioOn));
};

export const getBackgroundMusic = () => {
    if (typeof window === "undefined") return null;
    // TODO (fix): create universalStorage class to handle localStorage and cookies in one place depending on the environment
    return localStorage.getItem("backgroundMusic");
};

export const handleBackgroundMusicChange = (value: string | null) => {
    if (value === null) {
        localStorage.removeItem("backgroundMusic");
        return;
    }
    localStorage.setItem("backgroundMusic", value);
};

const getplayingAudio = () => {
    return document.body.querySelector("audio");
};

export const stopPlayingAudio = () => {
    const audio = getplayingAudio();

    if (audio) {
        audio.pause();
        audio.remove();
    }
};

export const playBackgroundMusic = () => {
    const backgroundMusic = getBackgroundMusic();
    //to stop playing audio if any
    stopPlayingAudio();
    if (backgroundMusic) {
        const audio = new Audio(`${ASSETS_URL}/sounds/${backgroundMusic}.wav`);
        audio.loop = true;
        audio.play();
        document.body.appendChild(audio);
    }
};
